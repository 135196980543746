import { addDays, startOfWeek, format, subWeeks, addWeeks, isSameDay, isAfter } from 'date-fns';
import React from 'react';
import nb from 'date-fns/locale/nb';
import Button from '../buttons/Button';
import Ikon from '../Ikon';

interface WeekCalendarPickerProps {
  date: Date;
  onChange: (date: Date) => void;
}

const WeekCalendarPicker = (props: WeekCalendarPickerProps): JSX.Element => {
  const { date, onChange } = props;
  const monday = startOfWeek(date, { weekStartsOn: 1 });
  const weekdays = [
    monday,
    addDays(monday, 1),
    addDays(monday, 2),
    addDays(monday, 3),
    addDays(monday, 4),
    addDays(monday, 5),
    addDays(monday, 6),
  ];

  return (
    <div className="flex justify-between items-stretch bg-gray">
      <Button className="" onClick={() => onChange(subWeeks(monday, 1))} outline>
        <Ikon ikon="chevron-thin-left" />
      </Button>
      {weekdays.map((day) => {
        return (
          <Button
            className={` flex-grow disabled:cursor-not-allowed disabled:text-gray-50 ${isSameDay(date, day)
              ? 'text-blue-hover font-bold border border-blue-hover'
              : ''
            }`}
            onClick={() => onChange(day)}
            key={day.toString()}
            disabled={isAfter(day, new Date())}
          >
            <div className="flex flex-col">
              <span className="">{format(day, 'iii', { locale: nb })}</span>
              <span className="font-bold">{format(day, 'dd', { locale: nb })}</span>
            </div>
          </Button>
        );
      })}
      <Button className="" onClick={() => onChange(addWeeks(monday, 1))} outline>
        <Ikon ikon="chevron-thin-right" />
      </Button>
    </div>
  );
};

export default WeekCalendarPicker;
