import React, { useEffect, useReducer, useState } from 'react';
import MessageBox from '../common/messageBox/MessageBox';
import { nyMeldingReducer, initState, updateNyMeldingState } from './NyMeldingReducer';
import Input from '../common/forms/Input';
import Textarea from '../common/forms/Textarea';
import DropdownSelect from '../common/forms/DropdownSelect';
import FileInput from '../common/forms/FIleInput';
import * as yup from 'yup';
import { newMeldingUrl } from '../api/urls';
import Button from '../common/buttons/Button';
import { getFormValidationErrorMessage } from '../../utils/formValidation';
import { useToast } from '../toast/ToastProvider';
import { useApi } from '../context/ApiProvider';
import { reduce, sortBy } from 'lodash-es';
import PageSpinner from '../common/spinner/PageSpinner';
import AdresseInput from './AdresseInput';
import Lokasjonsvelger from '../Lokasjonsvelger/Lokasjonsvelger';
import { useMeldingstyperFiltrert } from '../../queryHooks/useMeldingstyperFiltrert';

interface ValidationMessage {
  type: string;
  message: string;
}

interface FormValidation {
  innsenderEpost?: ValidationMessage;
  beskrivelse?: ValidationMessage;
  meldingstypeId?: ValidationMessage;
  adresse?: ValidationMessage;
  latitude?: ValidationMessage;
  longitude?: ValidationMessage;
  bilder?: ValidationMessage;
}

interface RawValidationresult {
  inner: [{ path: string; type: string; message: string }];
}

const getValidationObject = (e: RawValidationresult): FormValidation => {
  const validation = reduce(
    e.inner,
    (res, value) => {
      return { ...res, [value.path]: { type: value.type ?? 'validation', message: value.message } };
    },
    {} as FormValidation
  );
  return validation;
};

interface NyMeldingProps {
  onClose: () => void;
}

const NyMeldingPage = (props: NyMeldingProps): JSX.Element => {
  const { onClose } = props;
  const { addToast } = useToast();
  const { api } = useApi();
  const [state, dispatch] = useReducer(nyMeldingReducer, initState);
  const [validationError, setValidationError] = useState<FormValidation>({});
  const [meldingstyper, setMeldingstyper] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const { latitude, longitude, adresse, bilder, innsenderEpost, meldingstypeId, beskrivelse, mapCenter } = state;

  const validator = yup.object().shape({
    innsenderEpost: yup.string().lowercase().notRequired().email('validatation.notavalidemail'),
    beskrivelse: yup.string().notRequired(),
    meldingstypeId: yup.string().required('validatation.required'),
    adresse: yup.string().required('validatation.required'),
    latitude: yup.number().required('validatation.required'),
    longitude: yup.number().required('validatation.required'),
    bilder: yup.array().nullable(),
  });

  const updateState = (updatedState: updateNyMeldingState): void => {
    dispatch({ type: 'UPDATE_STATE', payload: { ...state, ...updatedState } });
  };

  const cancelNyMelding = (): void => {
    setValidationError({});
    updateState(initState);
    onClose();
  };

  const submitNyMelding = async (): Promise<void> => {
    setValidationError({});
    const melding = {
      latitude,
      longitude,
      adresse,
      bilder,
      innsenderEpost,
      meldingstypeId,
      beskrivelse,
    };
    try {
      setLoading(true);
      await validator.validate(melding, { abortEarly: false });
      const form = new FormData();
      form.append('meldingstypeId', meldingstypeId);
      form.append('beskrivelse', beskrivelse);
      form.append('adresse', adresse);
      form.append('latitude', latitude?.toString() || '');
      form.append('longitude', longitude?.toString() || '');
      bilder.forEach((bilde) => form.append('bilder', bilde));

      await api.post(newMeldingUrl, form);
      addToast('Melding ble lagret', 'success');
      updateState(initState);
      onClose();
    } catch (e) {
      if (e.name === 'ValidationError') {
        const eo = getValidationObject(e);
        setValidationError(eo);
      } else {
        addToast('Kunne ikke opprette melding', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const { data: meldingstyperFiltrert, isSuccess: meldingstyperFiltrertSuccess } = useMeldingstyperFiltrert();

  useEffect(() => {
    const gyldigeMeldingstyper = (): void => {
      let sorterteMeldingstyperMedAktivStatus: any[] = [];

      if (meldingstyperFiltrert) {
        // dette er kopi av samme liste i BymeldingClient (dette burde være properties vi får fra backend!)
        const deactivatedMeldingsTyper = [
          '179a7593-0d5a-4d11-b6bf-682578a5e664', // betalingsautomater
          '29382bdf-971e-41c0-91f6-b867283820e0', // betalingsautomater
          'bb54d45b-3534-4583-9ad3-4d24960a7a5a', // betalingsautomater
          '1990e04c-a194-4c04-b920-0c22ab921c2f', // betalingsautomater
          '62d2d550-beee-4818-bd9b-5c8d24f95830', // betalingsautomater
          '2d5f72d8-bf1d-433a-9aaf-4cb17a1af5ce', // betalingsautomater
          '9ba9e22e-90da-4c95-bd14-746109af9c91', // toaletter
          '7fc95d70-43c7-458a-977e-cbdba1e0cb3b', // toaletter
          '149b2f17-cf71-421b-95dc-4b353304c162', // toaletter
          'ff8c7673-d2a8-4257-bfd8-0f2607b0a2da', // Ladestolper
          'e3818f25-0510-4a6e-9826-2588af842d7f', // Ladestolper
          '8686b8c0-b3ae-45da-b2a5-5d4a2f5634bb', // Ladestolper
          'fb948410-b878-45c4-86f9-42ba0d44916e', // Ladestolper
          '93b42d6c-d2cd-48c2-a53e-22ba08c3f0c3', // Ladestolper.
        ];

        sorterteMeldingstyperMedAktivStatus = sortBy(meldingstyperFiltrert.map((t) => {
          const erDeaktivert = deactivatedMeldingsTyper.indexOf(t.meldingstypeId) > -1;
          return ({ value: t.meldingstypeId, name: t.beskrivelse, disabled: erDeaktivert, disabledTooltip: erDeaktivert ? 'Deaktivert - Må meldes inn via publikumsportal' : '' })
        }), 'name');
      }

      setMeldingstyper([
        { value: '', name: '' },
        ...sorterteMeldingstyperMedAktivStatus])
    };

    gyldigeMeldingstyper();
  }, [meldingstyperFiltrert, meldingstyperFiltrertSuccess])

  return (

    <div className="space-y-3">
      {loading && <PageSpinner />}
      <h1>Ny melding</h1>
      <div>
        <Input
          label="E-post"
          name="innsenderEpost"
          id="innsenderEpost"
          value={innsenderEpost}
          onChange={(e) => {
            updateState({ innsenderEpost: e.target.value });
          }}
          autoFocus
          outline
        />
        {validationError.innsenderEpost && (
          <MessageBox state="danger">
            {getFormValidationErrorMessage(validationError.innsenderEpost.message)}
          </MessageBox>
        )}
      </div>
      <div>
        <DropdownSelect
          options={meldingstyper}
          label="Meldingstype"
          name="meldingstypeId"
          id="meldingstypeId"
          outline
          value={meldingstypeId}
          onChange={(e) => {
            updateState({ meldingstypeId: e.target.value });
          }}
        />
        {validationError.meldingstypeId && (
          <MessageBox state="danger">
            {getFormValidationErrorMessage(validationError.meldingstypeId.message)}
          </MessageBox>
        )}
      </div>
      <div>
        <Textarea
          label="Beskrivelse"
          value={beskrivelse}
          onChange={(e) => {
            updateState({ beskrivelse: e.target.value });
          }}
          outline
        />
        <MessageBox state="warning">Unngå personopplysninger i beskrivelsen.</MessageBox>
      </div>
      <div>
        <AdresseInput
          value={adresse}
          onChange={(adresse: string, latitude: number, longitude: number) => {
            updateState({
              adresse,
              mapCenter: { lat: latitude, lng: longitude },
              latitude: undefined,
              longitude: undefined,
            });
          }}
        />
        <Lokasjonsvelger
          markerPosition={latitude && longitude ? { lat: latitude, lng: longitude } : undefined}
          mapCenter={mapCenter}
          onPlaceMarker={(markerPosition?: { lat: number; lng: number }, adresse?: string) => {
            updateState({ adresse: adresse, latitude: markerPosition?.lat, longitude: markerPosition?.lng });
          }}
        />
        {(validationError.latitude || validationError.longitude || validationError.adresse) && (
          <MessageBox state="danger">
            {getFormValidationErrorMessage(
              validationError.latitude?.message ||
              validationError.longitude?.message ||
              validationError.adresse?.message ||
              ''
            )}
          </MessageBox>
        )}
      </div>
      <div>
        <FileInput
          filer={bilder}
          onChange={(bilder: File[]) => {
            updateState({ bilder });
          }}
        />
        <MessageBox state="warning">Unngå personopplysninger i bilde(r)</MessageBox>
      </div>
      <div className="my-3 flex justify-start items-center space-x-3">
        <Button onClick={submitNyMelding}>Lagre</Button>
        <Button onClick={cancelNyMelding}>Avbryt</Button>
      </div>
    </div>

  );
};

export default NyMeldingPage;
