import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';
import { MeldingsFilter } from '../features/filter/filterReducer';
import { Bruker } from '../features/context/BrukerProvider';

export const useFilterByBruker = (bruker: Bruker): UseQueryResult<MeldingsFilter[]> => {
  const { api } = useApi();

  return useQuery({
    enabled: bruker.erAutentisert,
    queryKey: ['bruker-filter', [{ brukerId: bruker.brukerId }]],
    queryFn: () => {
      return api.get(urls.savedMeldingSearchUrl(bruker.brukerId)).then((result: { data: { result: MeldingsFilter[] } }) => {
        return result.data.result;
      });
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
