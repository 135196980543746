import { sortBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { MeldingsStatus } from '../../interfaces';
import { getStatusUnderstatuser } from '../../utils/statusUtils';
import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';
import MeldingStatusFilterItem from './MeldingStatusFilterItem';
import { useMeldingsstatuser } from '../../queryHooks/useMeldingsstatuser';

const MeldingStatusFilter = (): JSX.Element => {
  const { data: meldingsstatuser, isSuccess: meldingstatuserSuccess } = useMeldingsstatuser();
  const { currentFilter } = useFilter();
  const [groupedMeldingstatuser, setGroupedMeldingstatuser] = useState<MeldingsStatus[]>([]);

  useEffect(() => {
    if (meldingstatuserSuccess && meldingsstatuser) {
      setGroupedMeldingstatuser(getStatusUnderstatuser(meldingsstatuser));
    }
  }, [meldingsstatuser, meldingstatuserSuccess]);

  return (
    <div>
      {groupedMeldingstatuser.length > 0 && (
        <ExpandablePanel title="Status" indicatorLabel={currentFilter.meldingsStatusId.length}>
          <div className="flex flex-col">
            {groupedMeldingstatuser &&
              sortBy(
                groupedMeldingstatuser,
                (item: MeldingsStatus) => `${item.children?.length || 0}${item.navn}`
              ).map((status) => <MeldingStatusFilterItem status={status} key={status.id} />)}
          </div>
        </ExpandablePanel>
      )}
    </div>
  );
};

export default MeldingStatusFilter;
