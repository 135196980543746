/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { startsWith } from 'lodash-es';
import Button from '../buttons/Button';
import Ikon from '../Ikon';
import './FileInput.scss';

interface VedlegProp {
  filer: any;
  onChange: any;
  acceptedFormats?: string[];
  preview?: boolean;
  maxItems?: number;
}

const FileInput = (props: VedlegProp): JSX.Element => {
  const maxNumberOfImages = 4;
  const { filer, onChange, acceptedFormats, preview = true, maxItems } = props;
  const [error, setError] = useState('');

  const onRemoveImage = (index: number): void => {
    setError('');
    filer.splice(index, 1);
    onChange([...filer]);
  };

  const onAddImages = (images: FileList | null): void => {
    setError('');
    if (images) {
      const files = Array.from(images);
      const filterdImages = files.filter((item: File) =>
        acceptedFormats
          ? acceptedFormats.filter((f) => {
              const test = f.split('/');
              return startsWith(item.type, test[0]);
            })
          : startsWith(item.type, 'image/')
      );
      if (files.length !== filterdImages.length) {
        setError('Filformatet er ikke støttet');
      }
      if (maxItems && filer.length < maxItems) {
        if (filterdImages.length > maxItems - filer.length) {
          setError(`${error}\n Maksimum antall filer er nådd.`);
          onChange([...filer, ...filterdImages.slice(0, maxItems - filer.length)]);
        } else onChange([...filer, ...filterdImages]);
      } else {
        onChange([...filer, ...filterdImages]);
      }
    }
  };

  return (
    <div className="add-images">
      <ul className="flex flex-row flex-wrap">
        {preview &&
          filer &&
          filer.map((b: any, index: number) => (
            <li key={b.name} className="add-images-preview-item">
              <Button
                className={`
                w-4 h-4 
                flex items-center justify-center 
                border-2 border-solid border-white rounded-full 
                bg-gray-80 text-white 
                absolute 
                cursor-pointer
                `}
                onClick={() => onRemoveImage(index)}
              >
                <Ikon ikon="x" />
              </Button>
              <img className="add-images-preview-item-img" src={URL.createObjectURL(b)} />
            </li>
          ))}
        {filer.length < maxNumberOfImages && (
          <li className="add-images-preview-item bg-gray20  ">
            <label
              htmlFor="innmelding-file"
              className={`
              cursor-pointer
              add-images-button
              border-solid 
              border-blue-dark
              flex 
              items-center 
              justify-center
              bg-white
              `}
            >
              <input
                className="p-2"
                type="file"
                accept={acceptedFormats?.join(', ') ?? 'image/*'}
                name="innmelding-file"
                id="innmelding-file"
                multiple
                onChange={(e) => onAddImages(e.target.files)}
              />
              <Ikon ikon="plus-sign" />
            </label>
          </li>
        )}
      </ul>
      {error && <div className="p-3 border-state-danger focus:border-state-danger bg-state-danger">{error}</div>}
    </div>
  );
};

export default FileInput;
