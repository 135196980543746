import React from 'react';
import { format } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { Melding } from '../../interfaces';
import Ikon from '../common/Ikon';
import StatusIkon from '../StatusIkon/StatusIkon';
import { getMeldingtypeFromId } from '../../utils/meldingstypeUtils';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';

interface MeldingPopupProps {
  melding: Melding;
}

const MeldingPopup = (props: MeldingPopupProps): JSX.Element => {
  const { melding } = props;
  const { data: meldingstyper } = useMeldingstyper();
  const location = useLocation();
  return (
    <>
      {meldingstyper && (
        <div className="overflow-hidden">
          <div>
            <Link
              className="flex flex-nowrap justify-between items-center p-1"
              to={`${location.pathname}/melding/${melding.meldingId}`}
            >
              <h5 className="py-1 whitespace-nowrap truncate">
                {melding.lopeNummer} - {(getMeldingtypeFromId(meldingstyper, melding.meldingstypeId) || undefined)?.beskrivelse}
              </h5>
              <Ikon ikon="chevron-right" />
            </Link>
          </div>
          <div className="bg-gray-light p-1">
            <dl className="text-6 divide-y-1">
              <div className="flex justify-between items-center p-1">
                <dt className="font-bold">Mottatt: </dt>
                <dd className="">{format(new Date(melding.opprettetTid), 'dd.MM.yy HH:mm')}</dd>
              </div>
              <div className="flex justify-between items-center p-1">
                <dt className="font-bold">Sted: </dt>
                <dd className="text-right">{melding.adresse}</dd>
              </div>
              <div className="flex justify-between items-center p-1">
                <dt className="font-bold">Status: </dt>
                <dd>{melding.meldingsStatusId && <StatusIkon statusId={melding.meldingsStatusId} />}</dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default MeldingPopup;
