import React, { useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import { ReactComponent as Logo } from '../../assets/oslo-logo.svg';
import { useOutsideClickHandler } from '../hooks/ClickHandlerHooks';
import './arbeidsliteNav.scss';

interface ArbeidslisteNavProps {
  toggleNyMeldingModal: (open: boolean) => void;
}

const ArbeidslisteNav = (props: ArbeidslisteNavProps): JSX.Element => {
  const clickOutsidekHandeler = useRef(null);
  const { toggleNyMeldingModal } = props;

  useOutsideClickHandler(clickOutsidekHandeler, () => {
    setHamburgerOpen(false);
  });
  const history = useHistory();

  history.listen(() => {
    setHamburgerOpen(false);
  });

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  return (
    <>
      <nav className="nav-bar-container h-nav-small z-menu">
        <div className="nav-bar-logo">
          <Logo className="h-logo-large" />
          <h1 className="ml-2"> | Null Hull</h1>
        </div>
        <div className="nav-links">
          <div ref={clickOutsidekHandeler} className={`hamburger-menu z-slide-in ${hamburgerOpen ? 'open' : 'closed'}`}>
            <Button onClick={() => setHamburgerOpen(!hamburgerOpen)} className="hamburger-close-btn">
              <Ikon ikon="x" />
            </Button>
            <NavLinks />
            <Button
              className={`nav-link flex items-center`}
              onClick={() => {
                toggleNyMeldingModal(true);
                setHamburgerOpen(false)
              }}>
              <div className="">
                <Ikon ikon="plus-sign" title="Ny melding" />
              </div>
              <div className="nav-link-text">Ny melding</div>
            </Button>
          </div>
          <Button onClick={() => setHamburgerOpen(!hamburgerOpen)} className="hamburger-toggle">
            <Ikon ikon="menu" />
          </Button>
        </div>
      </nav>
      <div
        className={`hamburger-backdrop z-slide-in-backdrop bg-black-backdrop ${hamburgerOpen ? 'open' : 'closed'}`}
      />
    </>
  );
};
export default ArbeidslisteNav;

const navLinks = [
  { link: '/arbeidsliste/liste', icon: 'checklist', title: 'Listevisning' },
  { link: '/arbeidsliste/kart', icon: 'map', title: 'Kartvisning' },
  { link: '/arbeidsliste/min_side', icon: 'user', title: 'Min side' },
  { link: '/arbeidsliste/ressursforbruk', icon: 'coin-stacks', title: 'Ressursforbruk' },
  // { link: '/arbeidsliste/ny_melding', icon: 'plus-sign', title: 'Ny melding' },
];

const NavLinks = (): JSX.Element => (
  <>
    {navLinks.map((item) => (
      <NavLink to={item.link} key={item.link} className={`nav-link flex items-center`}>
        <div className="">
          <Ikon ikon={item.icon} title={item.title} />
        </div>
        <div className="nav-link-text">{item.title}</div>
      </NavLink>
    ))}

  </>
);
