import { AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { getEnvironmentVariable } from '../utils/environment';

export const oidcConfig: AuthProviderProps = {
    authority: getEnvironmentVariable('REACT_APP_ONELOGIN_AUTHORITY'),
    client_id: getEnvironmentVariable('REACT_APP_ONELOGIN_CLIENT_ID'),
    redirect_uri: getEnvironmentVariable('REACT_APP_ONELOGIN_REDIRECT_URI'),
    silent_redirect_uri: getEnvironmentVariable('REACT_APP_ONELOGIN_SILENT_REDIRECT_URI'),
    post_logout_redirect_uri: getEnvironmentVariable('REACT_APP_ONELOGIN_POST_LOGOUT_REDIRECT_URI'),
    automaticSilentRenew: true,
    response_type: 'code',
    scope: 'openid profile groups email',
    resource: getEnvironmentVariable('REACT_APP_ONELOGIN_RESOURCE').split(','),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};