import { reduce } from 'lodash-es';
import React, { useState } from 'react';
import { Ressursforbruk } from '../../interfaces';
import Button from '../common/buttons/Button';
import * as yup from 'yup';
import { useToast } from '../toast/ToastProvider';
import Textarea from '../common/forms/Textarea';
import DateTimeInput from '../common/forms/DateTimeInput';
import { format } from 'date-fns';
import Input from '../common/forms/Input';
import PageSpinner from '../common/spinner/PageSpinner';
import MessageBox from '../common/messageBox/MessageBox';

interface validationMessage {
  type: string;
  message: string;
}

interface formValidation {
  dato?: validationMessage;
  kommentar?: validationMessage;
  prosesskodeEnhetsVerdi?: validationMessage;
}

interface rawValidationresult {
  inner: [{ path: string; type: string; message: string }];
}

const getValidationObject = (e: rawValidationresult): formValidation => {
  const validation = reduce(
    e.inner,
    (res, value) => {
      return { ...res, [value.path]: { type: value.type ?? 'validation', message: value.message } };
    },
    {} as formValidation
  );
  return validation;
};

interface EditRfDetailsFormProps {
  value: Ressursforbruk;
  onSubmit: (updatedRfb: Ressursforbruk) => void;
  onCancel: () => void;
}

const EditRfDetailsForm = (props: EditRfDetailsFormProps): JSX.Element => {
  const { value, onSubmit, onCancel } = props;
  const [localState, setLocalState] = useState({
    dato: value.dato,
    kommentar: value.kommentar,
    prosesskodeEnhetsVerdi: value.prosesskodeEnhetsVerdi,
  });
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState<formValidation>({});
  const { addToast } = useToast();

  const validator = yup.object().shape({
    prosesskodeEnhetsVerdi: yup.number().required('Feltet må innekolde et tall').moreThan(0, "Mengde må ha verdi større enn 0"),
    dato: yup
      .date()
      .required('validatation.required')
      .default(new Date())
      .max(new Date(), `Dato kan ikke være etter ${format(new Date(), 'dd.MM.yyyy')}`),
    kommentar: yup.string(),
  });

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      await validator.validate(localState, { abortEarly: false });
      await onSubmit({ ...value, ...localState });
      onCancel();
    } catch (e) {
      if (e.name === 'ValidationError') {
        const eo = getValidationObject(e);
        setValidationError(eo);
      } else {
        addToast('Kunne ikke lagre endringer', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-2">
      {loading && <PageSpinner />}
      <h1>
        {value.prosesskode.navn} - {value.prosesskode.beskrivelse}
      </h1>
      <div>
        <DateTimeInput
          label="Dato*"
          id="pkDdato"
          placeholder="Dato"
          handleChange={(e) => {
            setLocalState({ ...localState, dato: e?.toUTCString() ?? '' });
          }}
          value={localState.dato ? new Date(localState.dato) : undefined}
        />
        {validationError.dato && <MessageBox state="danger">{validationError.dato.message}</MessageBox>}
      </div>
      <div>
        <Textarea
          label="Kommentar"
          value={localState.kommentar}
          onChange={(e) => {
            setLocalState({ ...localState, kommentar: e.target.value });
          }}
          outline
        />
      </div>
      <div>
        <Input
          label="Mengde*"
          id="prosesskodeEnhetsVerdi"
          type="number"
          step="0.1"
          value={localState.prosesskodeEnhetsVerdi}
          onChange={(e) => {
            setLocalState({ ...localState, prosesskodeEnhetsVerdi: e.target.value });
          }}
          outline
          postfix={value.prosesskode.prosesskodeEnhet.navn}
        />
      </div>
      {validationError.prosesskodeEnhetsVerdi && (
        <MessageBox state="danger">{validationError.prosesskodeEnhetsVerdi.message}</MessageBox>
      )}
      <div className="flex flex-row justify-between">
        <Button onClick={onCancel} outline color="red">
          Avbryt
        </Button>
        <Button onClick={handleSubmit}>Lagre</Button>
      </div>
    </div>
  );
};

export default EditRfDetailsForm;
