/* eslint-disable react-hooks/exhaustive-deps */
import { startsWith } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { dokumentasjonUrl } from '../../api/urls';
import Button from '../../common/buttons/Button';
import Ikon from '../../common/Ikon';
import FadeInSpinner from '../../common/spinner/FadeInSpinner';
import { useApi } from '../../context/ApiProvider';
import { useToast } from '../../toast/ToastProvider';
import LeggTilDokumantasjonForm from './LeggTilDokumantasjonForm';
import DocumentasjonBilde from './DokumentasjonBilde';

export interface Dokumentasjon {
  base64Dokumentasjon: string;
  contentType: string;
  dateTimeTaken?: string;
  dokumentasjonId: string;
  filNavn: string;
  orientationDegrees: number;
}

export const base64Link = (doc: Dokumentasjon): string => `data:${doc.contentType};base64, ${doc.base64Dokumentasjon}`;

const DokumentasjonsPanel = (props: {
  dokumentasjonIds?: string[];
  meldingId: string;
  dokumentasjonCash?: Dokumentasjon[];
  cashDokumentasjonToState: (docs: Dokumentasjon[]) => void;
}): JSX.Element => {
  const { dokumentasjonIds, dokumentasjonCash, cashDokumentasjonToState, meldingId } = props;
  const { api } = useApi();
  const { addToast } = useToast();
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([] as Dokumentasjon[]);
  const [documents, setDocuments] = useState([] as Dokumentasjon[]);

  const groupDocsByType = async (docs: Dokumentasjon[]): Promise<void> => {
    const docImages = [] as Dokumentasjon[];
    const docDocuments = [] as Dokumentasjon[];

    docs.map((doc: Dokumentasjon) => {
      if (startsWith(doc.contentType, 'image')) docImages.push(doc);
      else docDocuments.push(doc);
    });
    setImages(docImages);
    setDocuments(docDocuments);
    return;
  };

  const loadDokumentasjonForMelding = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await api.get(dokumentasjonUrl(meldingId));
      cashDokumentasjonToState(res.data.result);
      await groupDocsByType(res.data.result);
    } catch {
      addToast('Kunne ikke laste inn dokumentasjon', 'error');
    } finally {
      setLoading(false);
    }
  };

  const selectNext = (): number => {
    if (images.length > 1 && selectedImgIndex < images.length - 1) {
      return selectedImgIndex + 1;
    } else {
      return 0;
    }
  };

  const selectPrev = (): number => {
    if (images.length > 1 && selectedImgIndex !== 0) {
      return selectedImgIndex - 1;
    } else {
      return images.length - 1;
    }
  };

  useMemo(() => {
    const init = async (): Promise<void> => {
      if (dokumentasjonCash) {
        setLoading(true);
        await groupDocsByType(dokumentasjonCash);
        setLoading(false);
      } else if (dokumentasjonIds && dokumentasjonIds.length > 0) await loadDokumentasjonForMelding();
    };
    init();
  }, []);

  return (
    <div className="flex flex-col space-y-4">
      <FadeInSpinner loading={loading} />

      <div>
        <h4>Legg til dokumentasjon</h4>
        <LeggTilDokumantasjonForm
          meldingId={meldingId}
          onChange={() => {
            loadDokumentasjonForMelding();
          }}
        />
      </div>

      {!loading && images.length > 0 && (
        <div>
          <h4>Bilder</h4>
          <div className="bg-gray-light flex h-img-large mobile-only:h-mobile-img-large justify-center items-center ">
            <DocumentasjonBilde image={images[selectedImgIndex]} />
            {images.length > 1 && (
              <>
                <div className="absolute left-0">
                  <button
                    className="p-1 h-full bg-blue-dark text-white opacity-75"
                    onClick={() => setSelectedImgIndex(selectPrev())}
                  >
                    <Ikon ikon="chevron-thin-left" />
                  </button>
                </div>
                <div className="absolute right-0 ">
                  <button
                    className="p-1 h-full bg-blue-dark text-white opacity-75"
                    onClick={() => setSelectedImgIndex(selectNext())}
                  >
                    <Ikon ikon="chevron-thin-right" />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-wrap mt-3">
            {images.map((doc, index) => (
              <div key={doc.dokumentasjonId} className="">
                <Button className="" onClick={() => setSelectedImgIndex(index)}>
                  <img className="object-contain h-img-thumb" alt="meldingsvedlegg" src={base64Link(doc)} />
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
      {!loading && documents.length > 0 && (
        <div>
          <h4>Dokumenter</h4>
          <div className="flex space-x-3">
            {documents.map((d) => (
              <div key={d.dokumentasjonId} className="bg-gray-dark flex p-2 mb-3">
                <div title={d.filNavn} className="flex flex-col items-center justify-center p-1">
                  <Ikon ikon="dokument" />
                  <span className="w-7 truncate ">{d.filNavn}</span>
                </div>
                <a
                  href={base64Link(d)}
                  target="_"
                  download={d.filNavn}
                  className={`
                    flex flex-col items-center justify-center 
                    bg-blue-dark hover:bg-blue-hover text-white
                    p-3
                  `}
                >
                  <Ikon ikon="download" />
                  Last ned
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DokumentasjonsPanel;
