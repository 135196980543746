import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { MeldingDetaljer, MeldingsStatus } from '../../../interfaces';
import { findMeldingstatusPath } from '../../../utils/statusUtils';
import { bymeldingPortalUrl, postTilbakemeldingerForMeldingUrl } from '../../api/urls';
import Button from '../../common/buttons/Button';
import Textarea from '../../common/forms/Textarea';
import { useApi } from '../../context/ApiProvider';
import { useToast } from '../../toast/ToastProvider';
import { useMeldingsstatuser } from '../../../queryHooks/useMeldingsstatuser';

interface MeldingTilbakemeldingFormProps {
  melding: MeldingDetaljer;
  pendingStatusUpdate?: MeldingsStatus;
  onCancel: () => void;
  onConfirm: (statusId: number) => void;
}

const MeldingTilbakemeldingForm = (props: MeldingTilbakemeldingFormProps): JSX.Element => {
  const { melding, pendingStatusUpdate, onCancel, onConfirm } = props;

  const { api } = useApi();
  const { addToast } = useToast();
  const { data: meldingsstatuser } = useMeldingsstatuser();

  const [tilbakemelding, setTilbakemelding] = useState<string>();

  useEffect(() => {
    if (!meldingsstatuser) {
      return;
    }

    setTilbakemelding(modalTextVedAvslutning(melding, meldingsstatuser, pendingStatusUpdate));
  }, [melding, meldingsstatuser, pendingStatusUpdate]);

  const sendTilbakemeldingAndConfirm = async (): Promise<void> => {
    const url = postTilbakemeldingerForMeldingUrl(melding.meldingId);
    try {
      await api.post(url, { tekst: tilbakemelding });
      onConfirm(pendingStatusUpdate?.id ?? 0);
      addToast('Tilbakemelding er sendt og status er oppdatert', 'success');
      onCancel();
    } catch (e) {
      addToast('Kunne ikke sende tilbakemelding', 'error');
    }
  };

  return (
    <div className="space-y-2">
      <h4>Tilbakemelding ved avslutning av sak</h4>
      {tilbakemelding && (
        <Textarea
          outline
          rows={20}
          value={tilbakemelding}
          onChange={(e) => setTilbakemelding(e.target.value)}
          label="Send en tilbakemelding til innmelder"
        />
      )}
      <div className="flex justify-end space-x-2">
        <Button onClick={onCancel} outline color="red">
          Avbryt
        </Button>
        <Button
          onClick={async () => {
            try {
              onConfirm(pendingStatusUpdate?.id ?? 0);
              addToast('Status er oppdatert', 'success');
              onCancel();
            } catch (e) {
              addToast('Kunne ikke oppdatere status', 'error');
            }
          }}
          outline
        >
          Oppdater status uten tilbakemelding
        </Button>
        <Button
          onClick={() => {
            sendTilbakemeldingAndConfirm();
          }}
        >
          Oppdater status og send tilbakemelding
        </Button>
      </div>
    </div>
  );
};

export default MeldingTilbakemeldingForm;

const modalTextVedAvslutning = (
  melding: MeldingDetaljer,
  meldingsStatuser: MeldingsStatus[],
  pendingStatus?: MeldingsStatus
): string => {
  const { meldingId, opprettetTid, meldingstype, adresse, beskrivelse, lopeNummer } = melding;
  const path = findMeldingstatusPath(meldingsStatuser, pendingStatus?.id || 0);
  const pathString = path.reduce((prev, current, index) => {
    const res = prev + `${current.navn !== 'Fritekst' ? `${index === 0 ? '' : ' - '}${current.navn}` : ''}`;
    return res;
  }, '');

  const text = `Hei,
Din melding har blitt oppdatert.

Meldingsnr.: ${lopeNummer}
${opprettetTid ? `Mottatt: ${format(new Date(opprettetTid), 'dd.MM.yy HH:mm', { locale: nb })}` : ''}
${adresse ? `Adresse: ${adresse}` : ''}
${meldingstype ? `Kategori: ${meldingstype.beskrivelse}\n` : ''}
${pathString ? `Status: ${pathString}` : ''}
${beskrivelse ? `Beskrivelse: ${beskrivelse}` : ''}

${pendingStatus?.tekstmal || ''}

Se din melding her: ${bymeldingPortalUrl}/meldinger/${meldingId}

Takk for at du bidrar til å gjøre byen bedre.

Med vennlig hilsen
Bymiljøetaten, Oslo kommune
`;
  return text;
};
