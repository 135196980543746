import React, { useEffect, useState } from 'react';
import { useApi } from '../context/ApiProvider';
import { kommentarerForMeldingUrl } from '../api/urls';
import { MeldingKommentar } from '../../interfaces';
import { useBrukerContext } from '../context/BrukerProvider';
import LeggTilKommentarForm from './LeggTilKommentarForm';
import { format } from 'date-fns';
import FadeInSpinner from '../common/spinner/FadeInSpinner';
import Button from '../common/buttons/Button';

const KommentarPanel = ({
  meldingId,
  cashKommentarToState,
  kommentarer,
}: {
  meldingId: string;
  cashKommentarToState(k: MeldingKommentar[]): void;
  kommentarer: MeldingKommentar[];
}): JSX.Element => {
  const { api } = useApi();
  const { bruker: user } = useBrukerContext();
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const loadKommentarer = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await api.get(kommentarerForMeldingUrl(meldingId));
      cashKommentarToState(res.data.result);
      setShowInput(false);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const onAddSuccess = (kommenrarId: string, tekst: string): void => {
    const newKommentar = {
      opprettetTid: new Date().toISOString(),
      tekst: tekst,
      opprettetAvBruker: { brukerId: user.brukerId, navn: '', epost: user.epost },
      kommentarId: kommenrarId,
    };
    cashKommentarToState([newKommentar]);
  };

  useEffect(() => {
    kommentarer.length === 0 && loadKommentarer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FadeInSpinner loading={loading} />
      {!loading && (
        <>
          <div className="p-1">
            {showInput ? (
              <LeggTilKommentarForm
                meldingId={meldingId}
                onSuccess={onAddSuccess}
                onClose={() => setShowInput(false)}
              />
            ) : (
              <div className="flex justify-end">
                <Button value="Legg til intern kommentar" outline onClick={() => setShowInput(true)} />
              </div>
            )}
          </div>
          {kommentarer.map((k) => {
            const outgoing = user.brukerId === k.opprettetAvBruker.brukerId;
            return (
              <div key={k.kommentarId} className={`p-1 flex flex-col ${outgoing ? 'items-end' : 'items-start'}`}>
                <div className="flex no-wrap max-w-full">
                  <span className="truncate">{k.opprettetAvBruker.epost}</span>
                  <span className="whitespace-no-wrap">({format(new Date(k.opprettetTid), 'dd.MM.yy HH:mm')})</span>
                </div>
                <div
                  className={`flex px-4 py-2 rounded-xl  ${outgoing
                    ? 'text-white bg-blue-dark rounded-tr-none'
                    : 'bg-gray-light border border-blue-dark rounded-tl-none'
                    }`}
                >
                  {k.tekst}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default KommentarPanel;
