import React from 'react';
import MeldingTypeFilter from './MeldingTypeFilter';
import MeldingStatusFilter from './MeldingStatusFilter';
import BydelsFilter from './BydelsFilter';
import MeldingSok from '../MeldingSok/MeldingSok';
import AvtaleFilter from './AvtaleFilter';
import DateLastUpdatedFilter from './DateLastUpdatedFilter';
import DateCreatedFilter from './DateCreatedFilter';

const FilterOptions = (): JSX.Element => {
  return (
    <div className="flex flex-col space-y-3">
      <MeldingSok />
      <MeldingTypeFilter />
      <MeldingStatusFilter />
      <BydelsFilter />
      <AvtaleFilter />
      <DateLastUpdatedFilter />
      <DateCreatedFilter />
    </div>
  );
};

export default FilterOptions;
