import React, { useEffect, useReducer } from 'react';
import { Pris, Prosesskode } from '../../interfaces';
import * as urls from '../api/urls';
import { buildQueryfromState } from '../../utils/filterUtils';
import { useApi } from '../context/ApiProvider';
import { useToast } from '../toast/ToastProvider';
import { getStatusIdsOfChildren, getStatusUnderstatuser } from '../../utils/statusUtils';
import { find, orderBy, uniqBy } from 'lodash-es';
import { startOfDay, endOfDay, format } from 'date-fns';
import { initFilter } from '../filter/filterReducer';
import PageSpinner from '../common/spinner/PageSpinner';
import MeldingRessursbrukRow from './MeldingRessursbrukRow';
import MeldingRessursbrukHeader from './MeldingRessursbrukHeader';
import {
  meldingRessursbrukReducer,
  meldingRessursbrukInitState,
  MeldingWithRessursbruk,
} from './meldingRessursbrukReducer';
import ProsesskodeSelect from './ProsesskodeSelect';
import Checkbox from '../common/forms/Checkbox';
import { useBrukerContext } from '../context/BrukerProvider';
import { useMeldingsstatuser } from '../../queryHooks/useMeldingsstatuser';
import { useBrukerselskap } from '../../queryHooks/useBrukerselskap';

const MeldingerWithRessursbrukTable = (props: { date: Date }): JSX.Element => {
  const { date } = props;
  const { bruker } = useBrukerContext();
  const [state, dispatch] = useReducer(meldingRessursbrukReducer, {
    ...meldingRessursbrukInitState,
    visMeldingerForAvtale: bruker.erServiceavdeling,
  });
  const { meldinger, pkColumns, loading, totaltAntall } = state;
  const { addToast } = useToast();
  const { api } = useApi();
  const { data: meldingsstatuser, isSuccess: isMeldingsstatuserSuccess } = useMeldingsstatuser();
  const { data: brukerselskap, isSuccess: isbrukerselskapSuccess } = useBrukerselskap(bruker);

  const updateState = (updatedFields: {
    meldinger?: MeldingWithRessursbruk[];
    pkColumns?: Prosesskode[];
    totaltAntall?: number;
    visMeldingerForAvtale?: boolean;
    loading?: boolean;
  }): void => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: { ...state, ...updatedFields },
    });
  };

  const getRessursforbrukForMelding = async (melding: MeldingWithRessursbruk): Promise<MeldingWithRessursbruk> => {
    const rf = await api.get(urls.getRessursbrukListUrl(melding.meldingId));
    const ressursforbruk = rf.data.result.ressursforbruk;
    const aggregertHistoriskPris = rf.data.result.aggregertHistoriskPris;
    return { ...melding, ressursforbruk, aggregertHistoriskPris };
  };

  const getPkFromMeldinger = (meldinger: MeldingWithRessursbruk[]): Prosesskode[] => {
    let pks = [] as Prosesskode[];
    meldinger.map((melding) => {
      melding.ressursforbruk?.map((rsf) => {
        if (rsf.prosesskode) pks = [...pks, rsf.prosesskode];
      });
    });
    return uniqBy(pks, 'id');
  };

  const getMeldinger = async (date: Date): Promise<void> => {
    updateState({ loading: true });
    if (!isMeldingsstatuserSuccess || !meldingsstatuser || !isbrukerselskapSuccess || !brukerselskap) {
      return;
    }

    const ferdigBehandletStatus = find(getStatusUnderstatuser(meldingsstatuser), (f) => f.id === 5);
    const filter = {
      ...initFilter,
      meldingsStatusId: ferdigBehandletStatus ? getStatusIdsOfChildren(ferdigBehandletStatus) : [],
      sistEndretDatoFra: startOfDay(date).toISOString(),
      sistEndretDatoTil: endOfDay(date).toISOString(),
      sistEndretAvBruker: !state.visMeldingerForAvtale ? bruker.brukerId : undefined,
      sorterEtter: 'dato',
      page: 1,
    };
    const filterString = `${buildQueryfromState(filter)}`;
    try {
      const res = bruker.erEntreprenor
        ? await api.get(urls.getFilteredMeldingerForEntrepreneurs(brukerselskap.selskapId, filterString))
        : await api.get(urls.getFilteredMeldingerForServiceavdeling(filterString));
      const meldinger: MeldingWithRessursbruk[] = res.data.result.meldinger;
      const totaltAntall = res.data.result.totaltAntall;
      const meldingerWithRF = await Promise.all(
        meldinger.map((m: MeldingWithRessursbruk) => getRessursforbrukForMelding(m))
      );
      updateState({
        meldinger: orderBy(meldingerWithRF, ['adresse', 'lopeNummer'], ['asc', 'desc']),
        pkColumns: getPkFromMeldinger(meldingerWithRF),
        totaltAntall: totaltAntall,
        loading: false,
      });
    } catch (e) {
      addToast(`Kunne ikke hente meldinger: ${e.response?.data?.errorMessage || 'ukjent feil'}`, 'error');
      updateState({
        loading: false,
      });
    }
  };

  const toggleAlleFilter = (): void => {
    updateState({ visMeldingerForAvtale: !state.visMeldingerForAvtale });
  };

  const addProsesskode = (pris: Pris): void => {
    if (pkColumns.findIndex((cpk) => cpk.id === pris.prosesskode.id) === -1) {
      updateState({
        pkColumns: [...pkColumns, pris.prosesskode],
      });
    } else {
      addToast('Prosesskoden er allerede i listen', 'warning');
    }
  };

  useEffect(() => {
    getMeldinger(date);
  }, [date, state.visMeldingerForAvtale, bruker, brukerselskap, isMeldingsstatuserSuccess, meldingsstatuser, isbrukerselskapSuccess]);

  return (
    <>
      <div className="w-full-page bg-yellow p-2 sticky left-0">
        <div className="">
          <Checkbox label="Min arbeidsliste" handleChange={toggleAlleFilter} checked={!state.visMeldingerForAvtale} />
        </div>
      </div>
      {loading && <PageSpinner />}
      {!loading && meldinger.length === 0 && (
        <div className="p-3 ">
          <h1>Ingen meldinger ble ferdigstillt {format(date, 'dd.MM.yyyy')}</h1>
        </div>
      )}
      {!loading && meldinger.length !== 0 && (
        <div
          className=""
          style={{
            display: 'grid',
            gridAutoColumns: 'minmax(170px, 300px)',
            gridAutoRows: 'auto',
          }}
        >
          <div
            className="bg-yellow shadow-lg z-nav1 sticky top-0 left-0 h-full p-2 flex rf-page-table-header"
            style={{ gridArea: '1 / 1 / 2 / 2' }}
          >
            <div className="text-sm">Oppdrag ({totaltAntall})</div>
          </div>
          {pkColumns.map((pk, index) => {
            return (
              <div
                key={pk.id}
                className="bg-yellow shadow-lg z-nav sticky top-0 border-r border-gray-dark text-sm rf-page-table-header "
                style={{ gridArea: `1 / ${index + 2} / 2 / ${index + 3}` }}
              >
                <div className="bg-yellow px-2 py-1">
                  {pk.navn} - {pk.beskrivelse}
                </div>
              </div>
            );
          })}
          {bruker.erEntreprenor && (
            <div
              style={{ gridArea: `1 / ${pkColumns.length + 2} / ${meldinger.length + 3}  / ${pkColumns.length + 3}` }}
            >
              <div className="bg-yellow shadow-lg z-nav sticky top-0 py-3 px-1">
                <ProsesskodeSelect handleSelect={addProsesskode} />
              </div>
            </div>
          )}
          {meldinger.map((melding, index) => {
            return [
              <MeldingRessursbrukHeader melding={melding} index={index} key={`${melding.meldingId}_1`} />,
              <MeldingRessursbrukRow
                key={`${melding.meldingId}_2`}
                melding={melding}
                index={index}
                pkColumns={pkColumns}
                onRessursforrbrukUpdated={(updatedMelding: MeldingWithRessursbruk): void => {
                  const updatedMeldingList = [
                    ...meldinger.filter((m) => m.meldingId !== melding.meldingId),
                    updatedMelding,
                  ];
                  updateState({
                    meldinger: orderBy(updatedMeldingList, ['adresse', 'lopeNummer'], ['asc', 'desc']),
                  });
                }}
              />,
            ];
          })}
        </div>
      )}
    </>
  );
};

export default MeldingerWithRessursbrukTable;
