import { MeldingDetaljer, MeldingKommentar } from '../../interfaces/Melding';
import { Dokumentasjon } from './Dokumentasjon/DokumentasjonPanel';

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export type bildeCashItem = {
  id: string;
  base64: string;
  filnavn: string;
};

export interface meldingPageState {
  melding: MeldingDetaljer;
  bildeCash?: bildeCashItem[];
  dokumentasjonCash?: Dokumentasjon[];
  kommentarer?: MeldingKommentar[];
  loading: boolean;
}

export const initState = {
  melding: {
    adresse: '',
    avtaleId: '',
    avvisningsgrunn: '',
    beskrivelse: '',
    innsenderEpost: '',
    latitude: 0,
    longitude: 0,
    lopeNummer: 0,
    meldingId: '',
    meldingsStatusId: 0,
    meldingstypeId: '',
    opprettetAvBruker: '',
    opprettetTid: '',
    rutingParametre: '',
    rutingTidspunkt: '',
    opprettetAvEpost: '',
    snapToLatitude: 0,
    snapToLongitude: 0,
  },
  loading: false,
};

export const meldingPageReducer = (
  state: meldingPageState,
  action: { type: stateActionType; payload: meldingPageState }
): meldingPageState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...initState };
    default:
      return state;
  }
};
