import React from 'react';
import { useKartGlobals } from "../context/KartProvider";


const KartlagItemLedgend = (props: { ledgendId: string | number }): JSX.Element => {
  const { ledgendId } = props;
  const {
    ledgend
  } = useKartGlobals();

  const modifyKartSymbolNames = (label: string): string => {
    switch (label) {
      case 'E':
        return 'Europavei';
      case 'K':
        return 'Kommunal vei';
      case 'P':
        return 'Privat vei';
      case 'R':
        return 'Riksvei';
      case 'S':
        return 'Skogsvei';
      case 'Armatur infrastruktur - VTP':
        return 'VPT';
      case 'Tilstand ukjent':
        return 'Gatelysskap - Tilstand ukjent';
      case 'Byttes snarest':
        return 'Gatelysskap - Byttes snarest';
      case 'Gammel OK':
        return 'Gatelysskap - Gammel OK';
      case 'OK':
        return 'Gatelysskap - OK';
      case 'Armatur infrastruktur - Kabel':
        return 'Kabel';
      case 'Armatur infrastruktur - Gatelyslinjer':
        return 'Gatelyslinjer';
      case 'Armatur infrastruktur - Wirestrekk':
        return 'Wirestrekk';
      default:
        return label;
    }
  }


  const l = ledgend.find(kl => kl.layerId === ledgendId);
  return <li>

    {l &&
      <ul className="px-2">
        {l.legend.map(kartLagBeskrivelse => {
          const { imageData, label, contentType } = kartLagBeskrivelse;
          const base64 =
            'data:' +
            contentType +
            ';base64,' +
            imageData;
          return (<li key={label} className="flex flex-row space-x-1 pl-4" ><img src={base64} /><span className="whitespace-normal max-w-full">{label ? modifyKartSymbolNames(label) : modifyKartSymbolNames(l.layerName)}</span> </li>)
        })}
      </ul>
    }
  </li>;
};

export default KartlagItemLedgend;