import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { bydelListe } from '../../utils/constants';
import { useFilter } from './FilterProvider';
import SelectedFilterToken, { SelectedFilterTokenProps } from './SelectedFilterToken';
import { getMeldingtypeFromId } from '../../utils/meldingstypeUtils';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';
import { useMeldingsstatuser } from '../../queryHooks/useMeldingsstatuser';
import { getMeldingstatusFromId } from '../../utils/meldingsstatusUtils';
import { useAvtalerByBruker } from '../../queryHooks/useAvtalerByBruker';
import { getAvtaleFromId } from '../../utils/avtaleUtils';
import { useBrukerContext } from '../context/BrukerProvider';

const SelectedFiltersList = (): JSX.Element => {

  const { currentFilter, updateCurrentFilter } = useFilter();
  const [filterList, setFilterList] = useState<SelectedFilterTokenProps[]>([]);

  const { data: meldingsstatuser } = useMeldingsstatuser();
  const { data: meldingstyper } = useMeldingstyper();
  const { bruker } = useBrukerContext();
  const { data: avtaler } = useAvtalerByBruker(bruker);

  useEffect(() => {
    if (meldingsstatuser == undefined || meldingstyper == undefined || avtaler == undefined) {
      return;
    }

    const getFiltersList = (): SelectedFilterTokenProps[] => {
      const list = [];
      if (currentFilter.fritekstSok) list.push({
        label: `Fritekst: ${currentFilter.fritekstSok}`,
        onRemove: () => {
          updateCurrentFilter({
            ...currentFilter,
            fritekstSok: "",
          })
        }
      })
      currentFilter.meldingstypeId.map((id) => {
        const meldingtype = getMeldingtypeFromId(meldingstyper, id);
        if (meldingtype) {
          list.push({
            label: meldingtype.navn,
            onRemove: () =>
              updateCurrentFilter({
                ...currentFilter,
                meldingstypeId: [...currentFilter.meldingstypeId.filter((v) => !id.includes(v))],
              })
          })
        }
      });
      currentFilter.meldingsStatusId.map((id) => {
        const meldingtype = getMeldingstatusFromId(meldingsstatuser, Number.parseInt(id));
        if (meldingtype) {
          list.push({
            label: meldingtype.navn,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              meldingsStatusId: [...currentFilter.meldingsStatusId.filter((v) => !id.includes(v))],
            })
          })
        }
      })
      currentFilter.bydelId.map((id) => {
        const bydel = bydelListe.find(bydel => bydel.id === id);
        if (bydel) {
          list.push({
            label: bydel.navn,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              bydelId: [...currentFilter.bydelId.filter((v) => !id.includes(v))],
            }),
          });
        }
      });
      currentFilter.avtaleId.map((id) => {
        const avtale = getAvtaleFromId(id, avtaler);
        if (avtale) {
          list.push({
            label: avtale.navn,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              avtaleId: [...currentFilter.avtaleId.filter((v) => !id.includes(v))],
            }),
          })
        };
      })
      if (currentFilter.sistEndretDatoFra) {
        list.push(
          {
            label: `Sist endret - fra ${format(new Date(currentFilter.sistEndretDatoFra), "dd.MM.yyyy")}`,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              sistEndretDatoFra: undefined,
            })
          }
        );
      }
      if (currentFilter.sistEndretDatoTil) {
        list.push(
          {
            label: `Sist endret - til ${format(new Date(currentFilter.sistEndretDatoTil), "dd.MM.yyyy")}`,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              sistEndretDatoTil: undefined,
            })
          }
        );
      }
      if (currentFilter.datoFra) {
        list.push(
          {
            label: `Opprettet  - fra ${format(new Date(currentFilter.datoFra), "dd.MM.yyyy")}`,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              datoFra: undefined,
            })
          }
        );
      }
      if (currentFilter.datoTil) {
        list.push(
          {
            label: `Opprettet - til ${format(new Date(currentFilter.datoTil), "dd.MM.yyyy")}`,
            onRemove: () => updateCurrentFilter({
              ...currentFilter,
              datoTil: undefined,
            })
          }
        );
      }
      return list;
    }

    setFilterList(getFiltersList());
  }, [avtaler, currentFilter, meldingsstatuser, meldingstyper, updateCurrentFilter]);


  return (
    <div className="flex flex-wrap justify-start p-2">
      {filterList.map((f) => {
        if (f) return <SelectedFilterToken label={f.label || ""} onRemove={f.onRemove} />
      })}
    </div >
  );
}

export default SelectedFiltersList;