import React, { useState, useCallback, useContext } from 'react';
import Toast from './Toast';

type UseToastProps = {
  addToast: (content: string, state?: ToastState) => void;
  removeToast: (id: number) => void;
  toasts: ToastDefinition[];
};

type ToastState = 'success' | 'warning' | 'error';

export interface ToastDefinition {
  id: number;
  content: string;
  state?: ToastState;
}

const ToastContext = React.createContext({} as UseToastProps);

const ToastProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [toasts, setToasts] = useState<ToastDefinition[]>([]);

  const addToast = (content: string, state: ToastState = 'success'): void => {
    const id = toasts.length + 1;
    // setToasts([...toasts, { id: id, content, state }]);
    setToasts([{ id: id, content, state }]);
  };

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      <>
        {children}
        <div className="fixed w-full h-0 top-0  z-modal">
          {toasts.map((toast) => (
            <Toast toast={toast} key={toast.id} />
          ))}
        </div>
      </>
    </ToastContext.Provider>
  );
};

export const useToast = (): UseToastProps => useContext(ToastContext);

export default ToastProvider;
