import React, { useMemo, useState } from 'react';
import { sortBy } from 'lodash-es';
import { MeldingsStatus } from '../../../interfaces';
import { getStatusNameAndClass, getStatusUnderstatuser } from '../../../utils/statusUtils';
import Button from '../../common/buttons/Button';
import Ikon from '../../common/Ikon';
import { useBrukerContext } from '../../context/BrukerProvider';
import { useMeldingsstatuser } from '../../../queryHooks/useMeldingsstatuser';
import { getMeldingstatusFromId } from '../../../utils/meldingsstatusUtils';

const StatusVelger = ({ handleChange }: { handleChange: (statusId: number) => void }): JSX.Element => {

  const { isLoading: meldingsstatuserIsLoading, isError: meldingsstatuserIsError, data: meldingsstatuser } = useMeldingsstatuser();

  const [activeParent, setActiveParent] = useState<MeldingsStatus | undefined>();
  const [sortedStatuses, setSortedStatuses] = useState<MeldingsStatus[]>([]);

  useMemo(() => {
    if (meldingsstatuserIsLoading || meldingsstatuserIsError || !meldingsstatuser) {
      return;
    }

    const meldingsStatuserGroups = getStatusUnderstatuser(meldingsstatuser);

    const getSortedStatuses = (active?: MeldingsStatus): MeldingsStatus[] =>
      sortBy(
        active?.children ?? meldingsStatuserGroups,
        (item: MeldingsStatus) => `${item.children?.length ?? 0}${item.navn}`
      );

    setSortedStatuses(getSortedStatuses(activeParent));

  }, [activeParent, meldingsstatuser, meldingsstatuserIsError, meldingsstatuserIsLoading]);

  const { bruker: user } = useBrukerContext();

  return (
    <div className="flex items-start pt-3">
      {activeParent && meldingsstatuser && (
        <Button outline onClick={() => setActiveParent(getMeldingstatusFromId(meldingsstatuser, activeParent?.foreldreId) || undefined)}>
          <Ikon ikon="chevron-thin-left" />
        </Button>
      )}
      <div className="flex-grow flex flex-col items-center space-y-2 ml-2">
        {sortedStatuses.filter(status => ((isAvailableForEntreprenor(status.id) && user.erEntreprenor) || user.erServiceavdeling)).map((status) => {
          return (
            <>
              {!status.children && (
                <Button
                  className={`p-3 w-full flex justify-start status-color-${getStatusNameAndClass(status.id)[1]}`}
                  key={status.id}
                  onClick={() => handleChange(status.id)}
                >
                  {status.navn}
                </Button>
              )}
              {status.children && status.children.filter(s => ((isAvailableForEntreprenor(s.id) && user.erEntreprenor) || user.erServiceavdeling)).length > 0 && (
                <Button
                  className={`p-3 w-full status-color-${getStatusNameAndClass(status.id)[1]}`}
                  key={status.id}
                  onClick={() => setActiveParent(status)}
                >
                  <div className="flex items-center justify-between">
                    {status.navn}
                    <Ikon ikon="chevron-thin-right" />
                  </div>
                </Button>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

// Meldingsstatuser tilgjengelig for entreprenør:

// - Utbedring bestilt
// - Sporveien AS
// - Videresendt BYM områdeansvarlig
// - Vann og avløpsetaten

const isAvailableForEntreprenor = (id: number): boolean => {
  return (
    (id !== 1 && id !== 10 && id < 15) || id === 18 || id === 57 || id === 71 || id === 200 || id === 300
  );
};

export default StatusVelger;
