import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../context/ApiProvider';
import { getNotificationServiceVarselData } from '../api/urls';
import Ikon from '../common/Ikon';

interface Varsel {
  id: string;
  varslingstype: string;
  publisert: boolean;
  klienter: Klient[];
  synligFra: string;
  synligTil: string;
  tekst: string;
}

interface Klient {
  key: string;
  visningsnavn: string;
}

const Varsel = (): JSX.Element => {
  const { api } = useApi();
  const klienKey = 'enk-null-hull';

  const { data } = useQuery<Varsel[], Error>({
    enabled: api.initialized,
    queryKey: ['notification-service-statiske-data'],
    queryFn: async () => {
      try {
        const response = await api.get(getNotificationServiceVarselData(klienKey));
        const responseData = response.data;
        return responseData;
      } catch (error) {
        throw error;
      }
    },
    onError: () => {
      console.error("Kunne ikke laste varsler");
    },
  });

  if (!data || data.length === 0) return <></>;

  const varselType = (varselType: string): string => {
    switch (varselType) {
      case "Advarsel":
        return "border-yellow bg-yellow-50";
      case "Feil":
        return "border-red bg-red-30";
      case "FeilRettet":
        return "border-green bg-green-30"
      default:
        return "border-blue bg-blue-60"
    }
  }
  const icon = (varselType: string): JSX.Element => {
    switch (varselType) {
      case "Advarsel":
        return <Ikon ikon="warning-triangle" />;
      case "Feil":
        return <Ikon ikon="error-hexagon" />;
      case "FeilRettet":
        return <Ikon ikon="succes-square" />
      default:
        return <Ikon ikon="info-mark-circle" />
    }
  }

  return (
    <>
      {data.map((varsel) => (
        <div key={varsel.id}>
          <section className="desktop:mx-2">
            <div className={`flex w-full my-2 border-0 border-l-2 border-solid ${varselType(varsel.varslingstype)}`}>
              <div className="flex gap-2 p-2 items-center">
                <div className="min-h-[24px] min-w-[24px]">{icon(varsel.varslingstype)} </div>
                <div className="text-4 hyphens-none" dangerouslySetInnerHTML={{ __html: varsel.tekst }} />
              </div>
            </div>
          </section>
        </div>
      ))}
    </>
  );
};

export default Varsel;
