import React, { forwardRef } from 'react';
import Ikon from '../Ikon';
import './DateInput.scss';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay } from 'date-fns';

interface InputFieldProps {
  label?: string;
  placeholder: string;
  id: string;
  value?: Date;
  handleChange: (date?: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
}
type DateTimeInputProps = InputFieldProps;

const DateTimeInput = forwardRef<HTMLInputElement, DateTimeInputProps>(
  ({ placeholder, value, handleChange, id, label, minDate, maxDate, minTime, maxTime }) => {
    registerLocale('nb', nb);
    setDefaultLocale('nb');
    const dateFormat = 'dd.MM.yyyy HH:mm';
    const timeFormat = 'HH:mm';
    return (
      <div className="flex flex-row flex-no-wrap justify-start items-end my-1">
        <label className="text-5 " htmlFor={`dateTimeInput-${id}`}>
          {label}
          <div className="flex flex-row flex-no-wrap justify-start items-center">
            <div className="h-50 px-2 py-1 border-r-0 border-2 border-blue-dark hover:border-blue-hover">
              <Ikon ikon="calendar" aria-hidden="true" />
            </div>
            <DatePicker
              id={`dateTimeInput-${id}`}
              selected={value}
              onChange={(date: Date) => handleChange(date)}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              timeCaption="Tidspunkt"
              timeIntervals={60}
              showTimeSelect
              locale="nb"
              placeholderText={placeholder}
              className="datetime_input flex-1 h-50 p-2 border-2 border-blue-dark hover:border-blue-hover focus:border-blue-hover rounded-none osloSans"
              calendarClassName="datetime_calendar z-menu border-2 border-blue-dark rounded-none   osloSans text-5 "
              minDate={minDate}
              maxDate={maxDate ?? new Date()}
              minTime={minTime ?? startOfDay(new Date())}
              maxTime={maxTime ?? new Date()}
              popperModifiers={{
                offset: { enabled: true, offset: '-52px, 0' },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </div>
        </label>

        <button
          disabled={!value}
          onClick={() => handleChange(undefined)}
          className="h-50 px-2 py-1 border-l-0 border-2 border-blue-dark hover:border-blue-hover"
        >
          <Ikon ikon="x" />
        </button>
      </div>
    );
  }
);

DateTimeInput.displayName = 'DateTimeInput';
export default DateTimeInput;
