import React, { useMemo, useRef, useState } from 'react';
import { Ressursforbruk } from '../../interfaces';
import Button from '../common/buttons/Button';
import Input from '../common/forms/Input';
import Ikon from '../common/Ikon';
import MessageBox from '../common/messageBox/MessageBox';
import Modal, { ModalSection } from '../common/modal/Modal';
import { useOutsideClickHandler } from '../hooks/ClickHandlerHooks';
import EditRfDetailsForm from './EditRfDetailsForm';

const RfInputWithSubmit = (props: {
  value: Ressursforbruk;
  onSubmit: (updatedRfb: Ressursforbruk) => void;
  onDelete: (rfId: string) => void;
  postfix?: string;
}): JSX.Element => {
  const { value, onSubmit, onDelete, postfix } = props;
  const [updatedValue, setUpdatedValue] = useState<string | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [inputState, setInputState] = useState<"primary" | "success" | "warning" | "danger" | undefined>();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const toggleModal = (): void => setShowDetails(!showDetails);

  const updateInputState = (value: string): void => {
    const number = Number.parseFloat(value) || 0;
    if (number === 0) {
      setInputState("warning");
    }
    else if (number < 0) {
      setInputState("danger");
    }
    else {
      setInputState(undefined);
    }
  };

  useMemo(() => {
    setUpdatedValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useMemo(() => {
    updateInputState(updatedValue || value.prosesskodeEnhetsVerdi);
  }, [updatedValue, value.prosesskodeEnhetsVerdi]);

  const abortUpdateOnOutsideClickRef = useRef(null);



  useOutsideClickHandler(abortUpdateOnOutsideClickRef, () => {
    setUpdatedValue(null);
    setShowConfirmDelete(false);
  });

  const onSubmitUpdate = async (): Promise<void> => {
    if (updatedValue) {
      setLoading(true);
      await onSubmit({ ...value, prosesskodeEnhetsVerdi: updatedValue });
      setUpdatedValue(null);
    }
    setLoading(false);
  };

  const handleKeyEvent = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && updatedValue) {
      if (inputState === undefined) onSubmitUpdate();
    } else if (e.key === 'Escape') {
      setUpdatedValue(null);
    }
  };

  return (
    <>
      {!showConfirmDelete ? (
        <div className="flex flex-col h-full w-full items-start space-y-1">
          <div className="flex h-full w-full items-center " ref={abortUpdateOnOutsideClickRef}>
            <div className="rf-input">
              <Input
                value={updatedValue ?? value.prosesskodeEnhetsVerdi}
                className="w-3/4"
                min={0}
                onChange={(e) => {
                  const { value } = e.target;
                  setUpdatedValue(value);
                }}
                state={inputState}
                placeholder=""
                onKeyUp={handleKeyEvent}
                postfix={postfix}
                label=""
                type="number"
                step="0.1"
                outline
              />
            </div>
            {updatedValue ? (
              <div className="flex flex-col items-end ">
                <Button
                  className="rf-input-btn bg-green-light text-black-default"
                  onClick={onSubmitUpdate} // onSubmit({ ...value, prosesskodeEnhetsVerdi: updatedValue })}
                  disabled={loading || inputState !== undefined}
                >
                  <Ikon ikon="check" />
                </Button>
                <Button
                  className="rf-input-btn bg-yellow text-blue-dark"
                  onClick={() => setUpdatedValue(null)}
                  disabled={loading}
                >
                  <Ikon ikon="undo" />
                </Button>
              </div>
            ) : (
              <div className="flex flex-col items-end">
                <Button className="rf-input-btn bg-blue-dark text-white" disabled={loading} onClick={toggleModal}>
                  <Ikon ikon="edit" />
                </Button>
                <Button
                  className="rf-input-btn rounded-full bg-red text-black-default"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  <Ikon ikon="x" />
                </Button>
              </div>
            )}
            {showDetails && (
              <Modal toggle={toggleModal} isOpen={showDetails}>
                <ModalSection>
                  <EditRfDetailsForm value={value} onSubmit={onSubmit} onCancel={toggleModal} />
                </ModalSection>
              </Modal>
            )}
          </div>
          {inputState && (
            <MessageBox state={inputState}>{
              "Verdi må være større enn 0"
            }</MessageBox>
          )}
        </div>
      ) : (
        <div className="flex h-full w-full items-center" ref={abortUpdateOnOutsideClickRef}>
          <Button
            disabled={loading}
            color="red"
            padding={1}
            onClick={async () => {
              setLoading(true);
              await onDelete(value.ressursforbrukId);
              setShowConfirmDelete(false);
              setLoading(false);
            }}
          >
            <span className="whitespace-no-wrap flex flex-row flex-no-wrap items-center">Slett Ressursforbruk?</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default RfInputWithSubmit;
