import React, { useRef } from 'react';
import { useOutsideClickHandler } from '../hooks/ClickHandlerHooks';
import { useFilter } from './FilterProvider';
import './filter.scss';
import Filter from './Filter';
import Ikon from '../common/Ikon';
import Button from '../common/buttons/Button';
import ReactDOM from 'react-dom';

const container = document.getElementById('slide-in-filter-root');

const SlideInFilter = ({ totaltAntall }: { totaltAntall: number }): JSX.Element => {
  const { filterOpen, toggleFilterOpen } = useFilter();
  const clickOutsidekHandelerRef = useRef(null);

  useOutsideClickHandler(clickOutsidekHandelerRef, () => {
    toggleFilterOpen(false);
  });

  const filter = (
    <>
      <div className={`filter z-slide-in bg-white ${filterOpen ? 'open' : 'closed'}`} ref={clickOutsidekHandelerRef}>
        <div
          className={`desktop:mt-nav-small py-2 flex justify-start items-center sticky top-0 w-full shadow-lg z-slide-in bg-white`}
        >
          <Button onClick={() => toggleFilterOpen()} className="mr-3">
            <Ikon ikon="x" className="w-5 h-5" />
          </Button>
          <div className="text-2 flex-grow">Meldinger: {totaltAntall}</div>
        </div>

        <Filter />
      </div>
      <div className={`filter-backdrop z-slide-in-backdrop bg-black-backdrop ${filterOpen ? 'open' : 'closed'}`} />
    </>
  );
  if (container) return ReactDOM.createPortal(filter, container);
  return filter;
};

export default SlideInFilter;
