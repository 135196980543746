type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface nyMeldingState {
  innsenderEpost: string;
  beskrivelse: string;
  meldingstypeId: string;
  adresse: string;
  latitude?: number;
  longitude?: number;
  mapCenter?: {
    lat: number;
    lng: number;
  };
  bilder: File[];
  loading: boolean;
}

export interface updateNyMeldingState {
  innsenderEpost?: string;
  beskrivelse?: string;
  meldingstypeId?: string;
  adresse?: string;
  latitude?: number;
  longitude?: number;
  mapCenter?: {
    lat: number;
    lng: number;
  };
  bilder?: File[];
  loading?: boolean;
}

export const initState = {
  innsenderEpost: '',
  beskrivelse: '',
  meldingstypeId: '',
  adresse: '',
  mapCenter: {
    lat: 59.91061357565543,
    lng: 10.748497577778883,
  },
  lat: 0,
  lng: 0,
  bilder: [],
  loading: false,
};

export const nyMeldingReducer = (
  state: nyMeldingState,
  action: { type: stateActionType; payload: nyMeldingState }
): nyMeldingState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...initState };
    default:
      return state;
  }
};
