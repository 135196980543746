import React from 'react';

import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';
import { endOfDay } from 'date-fns';
import DateInput from '../common/forms/DateInput';

const DateLastUpdatedFilter = (): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  return (
    <ExpandablePanel
      title="Sist endret dato"
      indicatorLabel={[currentFilter.sistEndretDatoFra, currentFilter.sistEndretDatoTil].filter((i) => i).length}
      heightConstraint={false}
    >
      <DateInput
        id="sistEndretDatoFra"
        placeholder="f.o.m"
        value={currentFilter.sistEndretDatoFra ? new Date(currentFilter.sistEndretDatoFra) : undefined}
        handleChange={(date) =>
          updateCurrentFilter({ ...currentFilter, sistEndretDatoFra: date?.toISOString() || undefined })
        }
      />
      <DateInput
        id="sistEndretDatoTil"
        placeholder="t.o.m"
        value={currentFilter.sistEndretDatoTil ? new Date(currentFilter.sistEndretDatoTil) : undefined}
        handleChange={(date) =>
          updateCurrentFilter({
            ...currentFilter,
            sistEndretDatoTil: date ? endOfDay(date).toISOString() : undefined,
          })
        }
      />
    </ExpandablePanel>
  )
};

export default DateLastUpdatedFilter;
