import React, { useState } from 'react';
import Button from '../../common/buttons/Button';
import Modal, { ModalSection } from '../../common/modal/Modal';
import { useBrukerContext } from '../../context/BrukerProvider';
import { updatedMeldingProps } from '../MeldingPage';
import { MeldingDetaljer, MeldingsStatus } from '../../../interfaces';
import { useToast } from '../../toast/ToastProvider';
import LeggTilKommentarForm from '../LeggTilKommentarForm';
import MeldingTilbakemeldingForm from './MeldingTilbakemeldingForm';
import StatusVelger from './StatusVelger';
import '../../StatusIkon/statusIkon.scss';
import BekreftStatusoppdatering from './BekreftStatusoppdatering';
import { isTerminalstatus } from '../../../utils/statusUtils';
import { useMeldingsstatuser } from '../../../queryHooks/useMeldingsstatuser';
import { getMeldingstatusFromId } from '../../../utils/meldingsstatusUtils';

const StatusChangeButton = ({
  meldingId,
  avtaleId,
  updateMelding,
  setSelectedTab,
  melding,
}: {
  meldingId: string;
  avtaleId?: string;
  updateMelding: (meldingId: string, avtaleId: string, updatedMelding: updatedMeldingProps) => void;
  setSelectedTab: (tab: number) => void;
  melding: MeldingDetaljer;
}): JSX.Element => {
  const { bruker: user } = useBrukerContext();
  const { addToast } = useToast();

  const [showStatausChangeModal, setShowStatausChangeModal] = useState(false);
  const toggleStatusModal = (): void => setShowStatausChangeModal(!showStatausChangeModal);
  const [addAvvistKommentar, setAddAvvistKommentar] = useState(false);
  const toggleKommentarModal = (): void => setAddAvvistKommentar(!addAvvistKommentar);
  const [tilbakemeldingModal, setTilbakemeldingModal] = useState(false);
  const toggleTilbakemeldingModal = (): void => setTilbakemeldingModal(!tilbakemeldingModal);

  const [pendingStatusUpdate, setPendingStatusUpdate] = useState<MeldingsStatus | undefined>();
  const { data: meldingsstatuser } = useMeldingsstatuser();

  const updateStatus = async (statusId: number): Promise<void> => {
    try {
      if (user.erEntreprenor && avtaleId && meldingsstatuser) {
        updateMelding(meldingId, avtaleId, { meldingsStatusId: statusId });
        // Hvis melding har terminalstatus og avtale er koblet mot en kontrakt vises ressursforbruk-fanen
        if (isTerminalstatus(meldingsstatuser, statusId) && melding.kontrakt) {
          setSelectedTab(5);
        }
      } else if (user.erServiceavdeling) {
        updateMelding(meldingId, '', { meldingsStatusId: statusId });
      }
    } catch (e) {
      addToast(`Kunne ikke oppdatere status: ${e.response?.data?.errorMessage || 'Ukjent feil'}`, 'error');
    }
  };

  const annonymInnsender = (): boolean => (melding.opprettetAvEpost === 'anonympublikum@bym.no' || melding.opprettetAvEpost === 'Anonym innsender');

  const handleChange = async (statusId: number): Promise<void> => {
    if (!meldingsstatuser) {
      return;
    }

    setPendingStatusUpdate(getMeldingstatusFromId(meldingsstatuser, statusId) || undefined);

    if (user.erEntreprenor && statusId === 3) {
      toggleStatusModal();
      // Melding avvist - entreprenør må legge til kommentar.
      setAddAvvistKommentar(true);
    } else if (statusId >= 4) {
      // mulighet for å sende epost til bruker hvis ikke bruker er annonym. Ellers vises "bekreft oppdater" modal
      toggleStatusModal();
      toggleTilbakemeldingModal();
    } else {
      toggleStatusModal();
      updateStatus(statusId);
    }
  };

  return (
    <>
      {meldingsstatuser && (
        <Button onClick={toggleStatusModal}>{isTerminalstatus(meldingsstatuser, melding.meldingsStatusId) ? "Endre status" : "Ferdigstill"}</Button>
      )}
      <Modal toggle={toggleStatusModal} isOpen={showStatausChangeModal} preventScroll>
        <ModalSection>
          <h4>Velg status</h4>
          <StatusVelger handleChange={handleChange} />
          <div className="flex space-x-2 justify-end mt-2">
            <Button color="red" outline onClick={toggleStatusModal}>
              Avbryt
            </Button>
          </div>
        </ModalSection>
      </Modal>
      <Modal toggle={toggleKommentarModal} isOpen={addAvvistKommentar} preventScroll>
        <ModalSection>
          <h4>Legg inn årsak til status Avvist</h4>
          <LeggTilKommentarForm
            meldingId={meldingId}
            onSuccess={() => updateStatus(3)}
            onClose={toggleKommentarModal}
          />
        </ModalSection>
      </Modal>
      {tilbakemeldingModal && (

        <Modal toggle={toggleTilbakemeldingModal} isOpen={tilbakemeldingModal} preventScroll>
          <ModalSection>
            {!annonymInnsender()
              ? (<MeldingTilbakemeldingForm
                melding={melding}
                pendingStatusUpdate={pendingStatusUpdate}
                onCancel={toggleTilbakemeldingModal}
                onConfirm={updateStatus}
              />)
              : (<BekreftStatusoppdatering
                melding={melding}
                pendingStatusUpdate={pendingStatusUpdate}
                onCancel={toggleTilbakemeldingModal}
                onConfirm={updateStatus}
              />)}
          </ModalSection>
        </Modal>
      )}

    </>
  );
};

export default StatusChangeButton;
