import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

interface TabProps {
  key: string;
  title: string;
  component: JSX.Element
}

interface MeldingTabPanelProps {
  tabs: TabProps[];
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
};

const MeldingTabPanel = ({ tabs, selectedTab, setSelectedTab }: MeldingTabPanelProps): JSX.Element => {
  // const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Tabs
      selectedIndex={selectedTab}
      onSelect={(tabIndex) => setSelectedTab(tabIndex)}
      selectedTabClassName="text-blue-hover border-b-4 border-blue-hover"
    >
      <TabList className="flex justify-start items-center max-w-full mobile-only:overflow-x-scroll">
        {tabs.map((e) => (
          <Tab className="mx-1 text-gray-50 border-b-4 border-transparent cursor-pointer" key={e.key}>
            {e.title}
          </Tab>
        ))}
      </TabList>
      {tabs.map((e) => (
        <TabPanel className="mx-1 border-b-4 border-transparent" key={`panel-${e.key}`}>
          {e.component}
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default MeldingTabPanel;
