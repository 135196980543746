import React from 'react';
import { sortBy } from 'lodash-es';
import { bydelListe } from '../../utils/constants';
import Checkbox from '../common/forms/Checkbox';
import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';

const BydelsFilter = (): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  return (
    <div>
      <ExpandablePanel title="Bydel" indicatorLabel={currentFilter.bydelId.length}>
        <div className="flex flex-col">
          {sortBy(bydelListe, 'navn').map((bydel: { id: string; navn: string }) => {
            return (
              <Checkbox
                key={bydel.id}
                label={bydel.navn}
                value={bydel.id}
                checked={currentFilter.bydelId ? currentFilter.bydelId.findIndex((i) => i === bydel.id) >= 0 : false}
                handleChange={(value, checked) => {
                  if (checked) {
                    updateCurrentFilter({
                      ...currentFilter,
                      bydelId: [...currentFilter.bydelId, ...value.filter((f) => !currentFilter.bydelId.includes(f))],
                    });
                  } else
                    updateCurrentFilter({
                      ...currentFilter,
                      bydelId: [...currentFilter.bydelId.filter((v) => !value.includes(v))],
                    });
                }}
              />
            );
          })}
        </div>
      </ExpandablePanel>
    </div>
  );
};

export default BydelsFilter;
