import React, { useState } from 'react';
import { Pris, Prosesskode, Ressursforbruk } from '../../interfaces';
import { useApi } from '../context/ApiProvider';
import { updateMeldingRessursbrukByIdUrl, meldingRessursbrukUrl, ressursforbrukById } from '../api/urls';
import { MeldingWithRessursbruk } from './meldingRessursbrukReducer';
import RfInputWithSubmit from './RfInputWithSubmit';
import { useToast } from '../toast/ToastProvider';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import MessageBox from '../common/messageBox/MessageBox';
import { useBrukerContext } from '../context/BrukerProvider';
import { useAvtalerByBruker } from '../../queryHooks/useAvtalerByBruker';
import { getAvtaleFromId } from '../../utils/avtaleUtils';

interface newRF {
  dato?: string;
  kommentar?: string;
  prisId?: string;
  prosesskodeEnhetsverdi?: number;
  skiftId?: number;
}

interface meldingRessursbrukRowProps {
  melding: MeldingWithRessursbruk;
  index: number;
  pkColumns: Prosesskode[];
  onRessursforrbrukUpdated: (melding: MeldingWithRessursbruk) => void;
}

const MeldingRessursbrukRow = (props: meldingRessursbrukRowProps): JSX.Element => {
  const { melding, index, pkColumns, onRessursforrbrukUpdated } = props;
  const { api } = useApi();
  const { addToast } = useToast();
  const { bruker } = useBrukerContext();
  const { data: avtaler, isSuccess: avtalerIsSuccess } = useAvtalerByBruker(bruker);
  const [loading, setLoading] = useState(false);
  const { bruker: user } = useBrukerContext();

  const updateMeldingRessursforbruk = async (updatedRfb: Ressursforbruk): Promise<void> => {
    const url = updateMeldingRessursbrukByIdUrl(melding.meldingId, updatedRfb.ressursforbrukId);
    setLoading(true);
    try {
      await api.put(url, updatedRfb);
      const newRfb = await api.get(ressursforbrukById(updatedRfb.ressursforbrukId));
      const updatedMeldingRfb =
        melding.ressursforbruk?.filter((r) => r.ressursforbrukId !== updatedRfb.ressursforbrukId) || [];
      onRessursforrbrukUpdated({
        ...melding,
        ressursforbruk: [...updatedMeldingRfb, newRfb.data.result],
      });
      addToast(`Ressursforbruk ble oppdatert`, 'success');
    } catch (e) {
      addToast(`Kunne ikke oppdatere ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const addMeldingRessursforbruk = async (newPessursforbruk: newRF): Promise<void> => {
    setLoading(true);
    const url = meldingRessursbrukUrl(melding.meldingId);
    try {
      const res = await api.post(url, newPessursforbruk);
      const newRessursforbrukRes = await api.get(ressursforbrukById(res.data.result.id));
      const newRF = newRessursforbrukRes.data.result;
      onRessursforrbrukUpdated({
        ...melding,
        ressursforbruk: melding.ressursforbruk ? [...melding.ressursforbruk, newRF] : [newRF],
      });
      addToast(`Ressursforbruk ble lagt til`, 'success');
    } catch (e) {
      addToast(`Kunne ikke oppdatere ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteMeldingRessursforbruk = async (rfId: string): Promise<void> => {
    setLoading(true);
    const url = ressursforbrukById(rfId);
    try {
      await api.delete(url);
      const updatedMeldingRF =
        melding.ressursforbruk?.filter((rf: Ressursforbruk) => rf.ressursforbrukId !== rfId) || [];
      onRessursforrbrukUpdated({
        ...melding,
        ressursforbruk: updatedMeldingRF,
      });
      addToast(`Ressursforbruk ble slettet`, 'success');
    } catch (e) {
      addToast(`Kunne ikke slette ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {avtalerIsSuccess && avtaler && pkColumns.map((pk, pkIndex) => {
        const rfPrisId = getAvtaleFromId(melding.avtaleId ?? '', avtaler)?.prisListe?.find((pris: Pris) => pris.prosesskode.id === pk.id)?.id ?? '';
        const meldingRf = melding.ressursforbruk?.filter((rf) => rf.prosesskode.id === pk.id);

        return (
          <div
            key={`${pk.id}_${melding.meldingId}`}
            style={{
              gridArea: `${index + 2} / ${pkIndex + 2} / ${index + 3} / ${pkIndex + 3}`,
            }}
            className={`flex justify-between h-full w-full p-2 border-r border-gray-dark ${index % 2 === 0 ? 'bg-white' : 'bg-gray-light'
              }`}
          >
            {user.erEntreprenor ? (
              <>
                <div className="flex flex-col h-full w-full justify-center items-center space-y-2">
                  {meldingRf &&
                    meldingRf.map((rf) => (
                      <RfInputWithSubmit
                        key={rf.ressursforbrukId}
                        value={rf}
                        postfix={pk.prosesskodeEnhet.navn}
                        onSubmit={updateMeldingRessursforbruk}
                        onDelete={deleteMeldingRessursforbruk}
                      />
                    ))}
                </div>
                {meldingRf?.length === 0 &&
                  (rfPrisId ? (
                    <div className="flex flex-col h-full w-full justify-center items-end">
                      <Button
                        className="rf-input-btn rounded-full bg-blue-dark text-white"
                        disabled={loading}
                        onClick={() =>
                          addMeldingRessursforbruk({
                            prisId: rfPrisId,
                            kommentar: '',
                            prosesskodeEnhetsverdi: 0,
                            dato: new Date().toUTCString(),
                          })
                        }
                      >
                        <Ikon ikon="plus-sign" />
                      </Button>
                    </div>
                  ) : (
                    <div className="flex flex-col h-full w-full justify-center items-end">
                      <MessageBox state="warning">
                        {!getAvtaleFromId(melding.avtaleId ?? '', avtaler)?.navn
                          ? 'Meldingen er ikke knyttet til en avtale'
                          : `Melding er tildelt avtale "${getAvtaleFromId(melding.avtaleId ?? '', avtaler)?.navn}"`}
                      </MessageBox>
                    </div>
                  ))}
              </>
            ) : (
              <div className="flex flex-col items-center justify-center p-3">
                {meldingRf &&
                  meldingRf.map((rf) =>
                    rf ? (
                      <div key={rf.ressursforbrukId}>
                        <span className="mr-2">{rf.prosesskodeEnhetsVerdi}</span>
                        {pk.prosesskodeEnhet.navn}
                      </div>
                    ) : (
                      ''
                    )
                  )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default MeldingRessursbrukRow;
