import React, { useEffect, useState } from 'react';
import L, { Map } from 'leaflet';
import { MapContainer, Marker, useMap, useMapEvent } from 'react-leaflet';
import { getAdresse } from '../api/urls';
import Spinner from '../common/spinner/Spinner';
import { useApi } from '../context/ApiProvider';
import defaultMarker from '../../assets/mapMarkers/defaultMarker.svg';
import { useToast } from '../toast/ToastProvider';
import CustomLeafletControl from '../common/CustomLeafletControl';
import Kartlagvelger from '../kartlagvelger/Kartlagvelger';

export interface LatLng {
  lat: number;
  lng: number;
}

interface LokasjonsvelgerProps {
  markerPosition?: LatLng;
  mapCenter?: LatLng;
  children?: JSX.Element | JSX.Element[]
  onPlaceMarker: (markerPosition?: LatLng, adresse?: string, leafletInstance?: Map) => void;
}

const Lokasjonsvelger = (props: LokasjonsvelgerProps): JSX.Element => {
  const { markerPosition, mapCenter, onPlaceMarker, children } = props;
  const [loading, setLoading] = useState(false);
  const { api } = useApi();
  const { addToast } = useToast();

  const getAdresseFronPoint = async (position: LatLng): Promise<{ adresse: string, validLocaiton: boolean }> => {
    try {
      const adr = await api.get(getAdresse(position.lat.toString(), position.lng.toString(), true));
      return { adresse: adr.data.result ?? 'Ukjent adresse', validLocaiton: true };
    } catch (e) {
      if (e?.response?.status == 400) return { adresse: '', validLocaiton: false };
      else return { adresse: 'Ukjent adresse', validLocaiton: true };
    }
  };

  const placeMarker = async (position: LatLng, mapInstace: Map): Promise<void> => {
    setLoading(true);
    const locationCheck = await getAdresseFronPoint(position);
    if (!locationCheck?.validLocaiton) {
      addToast("Lokasjoenen er utenfor Oslo", "error");
      onPlaceMarker(undefined, "", mapInstace);
    } else {
      onPlaceMarker(position, locationCheck.adresse, mapInstace);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <div className="absolute w-full h-740px flex justify-center items-center left-0">
          <div className="absolute h-16 w-16 m-auto rounded-full z-modalBackdrop bg-gray-80 opacity-50">
            <div className="absolute overflow-hidden">
              <Spinner />
            </div>
          </div>
        </div>
      )}
      <div className="bg-gray-80 h-740px">
        <MapContainer className="w-full h-740px" center={mapCenter} zoom={17} scrollWheelZoom={false}>

          <CustomLeafletControl position="topright">
            <Kartlagvelger id="ny_melding_kartlagvelger" />
          </CustomLeafletControl>

          <MapClickHandeler
            mapCenter={mapCenter}
            onClick={(latLng, mapInstace) => {
              placeMarker(latLng, mapInstace);
            }}
          />
          {markerPosition && (
            <Marker
              position={markerPosition}
              icon={
                new L.Icon({
                  iconUrl: defaultMarker,
                  iconSize: new L.Point(40, 40),
                  className: ``,
                })
              }
            />
          )}
          {children}
        </MapContainer>
      </div>
    </>
  );
};

export default Lokasjonsvelger;

interface ClickHandelerProps {
  mapCenter?: LatLng;
  onClick: (latLang: LatLng, mapInstace: Map) => void;
}

const MapClickHandeler = ({ onClick, mapCenter }: ClickHandelerProps): JSX.Element => {
  const map = useMap();

  useMapEvent('click', (e) => {
    onClick(e.latlng, map);
    map.flyTo(e.latlng, map.getZoom());
  });

  useEffect(() => {
    if (mapCenter) map.flyTo(mapCenter, map.getZoom());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenter]);

  return <></>;
};
