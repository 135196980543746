import React from 'react';
import { useFilter } from './FilterProvider';
import Button from '../common/buttons/Button';
import SaveFilterForm from './SaveFilterForm';
import SavedFilters from './SavedFilters';
import FilterOptions from './FilterOptions';
import SelectedFiltersList from './SelectedFiltersList';

const Filter = (): JSX.Element => {
  const { savedFilters, resetFilter, numberOfFiltersSelected, toggleFilterOpen } = useFilter();

  return (
    <div className="flex flex-col p-3 space-y-3">
      <FilterOptions />
      <div className="flex flex-col justify-start mt-3 space-y-3 max-w-md">
        <div className="flex justify-start items-center space-x-3">
          {numberOfFiltersSelected > 0 && (
            <Button
              color="red"
              outline
              onClick={() => {
                resetFilter();
              }}
            >
              Fjern filtervalg
            </Button>
          )}
          <Button color="green-dark" onClick={() => toggleFilterOpen()}>
            Bruk filter
          </Button>
        </div>
      </div>

      {numberOfFiltersSelected > 0 && (
        <>
          <h4>Lagre filter</h4>
          <SaveFilterForm />
        </>
      )}
      {(savedFilters && savedFilters.length > 0) && <div><SavedFilters /></div>}
      {numberOfFiltersSelected > 0 && (
        <>
          <h4>Dine filtervalg</h4>
          <SelectedFiltersList />
        </>
      )}
    </div>
  );
};

export default Filter;
