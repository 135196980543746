import React from 'react';
import { statusKoder } from '../../utils/statusUtils';
import './statusIkon.scss';

const StatusIkon = ({ statusId }: { statusId: number }): JSX.Element => {
  const s = statusKoder.find((e) => e.id === statusId);

  return (
    <div
      className={`truncate rounded-full py-1 px-3 mobile-only:px-1 text-6 font-bold text-black border-solid border status-color-${
        s?.class || 'ikke-tildelt'
      }`}
    >
      {s?.navn || 'Ikke tildelt'}
    </div>
  );
};

export default StatusIkon;
