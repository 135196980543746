import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MeldingsStatus } from '../interfaces';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';

export const useMeldingsstatuser = (): UseQueryResult<MeldingsStatus[]> => {
  const { api, initialized: apiInitialized } = useApi();

  return useQuery({
    enabled: apiInitialized,
    queryKey: ['meldingsstatuser'],
    queryFn: () => {
      return api.get(urls.meldingstatuser).then((result: { data: { result: MeldingsStatus[] } }) => {
        return result.data.result;
      });
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
