import React, { useMemo, useState } from 'react';
import { useYupValidationResolver } from '../../utils/useYupValidationResolver';
import Button from '../common/buttons/Button';
import Input from '../common/forms/Input';
import * as yup from 'yup';
import { useFilter } from './FilterProvider';
import { useForm } from 'react-hook-form';
import MessageBox from '../common/messageBox/MessageBox';
import Checkbox from '../common/forms/Checkbox';

const SaveFilterForm = (): JSX.Element => {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const { saveFilter } = useFilter();

  const saveFilterSchema = useMemo(
    () =>
      yup.object({
        navn: yup.string().lowercase().required('Dette feltet er påkrevd'),
        standard: yup.boolean(),
      }),
    []
  );
  const resolver = useYupValidationResolver(saveFilterSchema);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<{
    navn: string;
    standard: boolean;
  }>({
    mode: 'onSubmit',
    resolver,
  });

  const handleSaveFilter = handleSubmit(
    async ({ navn, standard }): Promise<void> => {
      setLoading(true);
      try {
        saveFilter(navn, standard);
        reset();
        setShowForm(false);
      } catch (e) { } finally {
        setLoading(false);
      }
    }
  );

  return showForm ? (
    <div>
      <form
        id="lagre-filter-form"
        className="flex flex-col justify-start items-start border-2 border-blue-dark p-2"
        onSubmit={handleSaveFilter}
      >
        <div className="flex flex-col justify-start items-start">
          <Input
            outline
            state={errors.navn?.message !== undefined ? 'danger' : 'primary'}
            label="Navn"
            type="text"
            id="navn"
            autoFocus
            {...register('navn')}
          />
          {errors.navn && <MessageBox state="danger">{errors.navn.message || ''}</MessageBox>}
          <div className="my-2">
            <Checkbox label="Standard" id="standard" {...register('standard')} />
          </div>
        </div>
        <div>
          <Button color="green-dark" type="submit" disabled={loading}>
            Lagre filter
          </Button>
          <Button className="mx-3" onClick={() => setShowForm(!showForm)} type="reset">
            Avbryt
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <div>
      <Button color="blue-dark" outline onClick={() => setShowForm(!showForm)}>
        Lagre filter
      </Button>
    </div>
  );
};

export default SaveFilterForm;
