/* eslint-disable prettier/prettier */
import axios from 'axios';

export const getGoogleUrl = (adresse: string): string => {
  return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(adresse)}`;
};

export interface Adresse {
  adressetekst: string;
  adressenavn: string;
  nummer?: number;
  bokstav?: string;
  objtype?: string;
  adressetilleggsnavn?: string;
  representasjonspunkt?: Representasjonspunkt;
  meterDistanseTilPunkt?: number;
}
export interface Representasjonspunkt {
  lat: number;
  lon: number;
}

export const adresseSok = async (soketekst: string, treffPerSide?: number): Promise<Adresse[]> => {
  try {
    const url = 'https://ws.geonorge.no/adresser/v1/sok';
    const res = await axios.get(
      `${url}?sok=${soketekst}*&sokemodus=AND&kommunenummer=0301&treffPerSide=${
        treffPerSide || 10
      }&asciiKompatibel=true`
    );
    return res.data.adresser;
  } catch (e) {}
  return [];
};
