import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import './App.css';
import Routes from './features/routes/Routes';
import { isBetatester, joinBetatest, leaveBetatest } from './utils/betatestUtils';
import { isProduction } from './utils/environment';
import useVH from 'react-viewport-height';
import { initilizeFaLibrary } from './features/common/Ikon';

const App = (): JSX.Element => {
  const { location } = useHistory();
  useVH();

  // Handle betatester FF
  useEffect(() => {
    initilizeFaLibrary();

    if (isProduction()) {
      if (!isBetatester() && location.search === '?join-beta-test') joinBetatest();
      else if (isBetatester() && location.search === '?leave-beta-test') leaveBetatest();
    }
  }, [location.search]);

  return (<div>
    <a href="#content" aria-label="Hopp til hovedinnholdet" className="snarvei" tabIndex={0}>
      <h2>Hopp til hovedinnholdet</h2>
    </a>
    <div id="content">
      <Routes />
    </div>
  </div>
  );
};

export default App;

/*
Whitelist classnames:
  heading-1
  heading-2
  heading-3
  heading-4
  heading-5
*/
