import React from 'react';

import ApiProvider from './ApiProvider';
import ToastProvider from '../toast/ToastProvider';
import FilterProvider from '../filter/FilterProvider';
import KartProvider from './KartProvider';
import { FeatureFlagHandler } from '../../utils/featureFlagUtils';
import BrukerProvider from './BrukerProvider';

type ApiProviderProps = {
  children: JSX.Element;
};

const ContextProvider: React.FC<ApiProviderProps> = ({ children }) => (
  <FeatureFlagHandler>
    <ToastProvider>
      <BrukerProvider>
        <KartProvider>
          <ApiProvider>
            <FilterProvider>{children}</FilterProvider>
          </ApiProvider>
        </KartProvider>
      </BrukerProvider>
    </ToastProvider>
  </FeatureFlagHandler>
);

export default ContextProvider;
