/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useRef, useState } from 'react';
import axios from 'axios';
import { CLIENT_NAME } from '../api/serviceInfo';
import { User } from 'oidc-client-ts';
import { oidcConfig } from '../../auth/oidcConfig';

interface UseApiInterface {
  api?: any;
  version?: string;
  initialized?: boolean;
}

const getUpdatedToken = (): string | null => {
  const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage);
    return user?.access_token ?? null;
  }

  return null;
};

const ApiProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [version, setVersion] = useState('');
  const [initialized, setInitialized] = useState(false);

  const { children } = props;
  const apiRef = useRef(axios.create({}));
  apiRef.current.defaults.headers['X-Bymelding-Client'] = `${CLIENT_NAME}:tba`;
  apiRef.current.interceptors.request.use((config) => {
    const token = getUpdatedToken();
    if (token) {
      config.headers = Object.assign({}, config.headers, {
        Authorization: `Bearer ${token}`
      });
    }

    return config;
  });

  const getAppVersion = async (): Promise<void> => {
    try {
      const versionResponse = await axios.get(`/version.txt`);
      setVersion(versionResponse?.data);
    } catch {
      setVersion('Ingen versjon funnet');
    } finally {
      apiRef.current.defaults.headers['X-Bymelding-Client'] = `${CLIENT_NAME}:${version}`;
      setInitialized(true);
    }
  };

  getAppVersion();

  return <ApiContext.Provider value={{ api: apiRef.current, version, initialized: initialized }}>{children}</ApiContext.Provider>;
};

export const ApiContext = createContext({});
export const useApi = (): UseApiInterface => useContext(ApiContext);
export default ApiProvider;
