import React, { createContext, useEffect, useContext, useReducer, useState, useMemo } from 'react';
import PageSpinner from '../common/spinner/PageSpinner';
import { useApi } from '../context/ApiProvider';
import * as urls from '../api/urls';
import { Filter, filterReducer, filterInitState, initFilter, MeldingsFilter } from './filterReducer';
import { buildQueryfromState, buildStateFromQuery, getNumberOfSelectedFilters } from '../../utils/filterUtils';
import { useToast } from '../toast/ToastProvider';
import { useHistory } from 'react-router';
import { useBrukerContext } from '../context/BrukerProvider';
import { useFilterByBruker } from '../../queryHooks/useFilterByBruker';
import { useQueryClient } from '@tanstack/react-query'
import { globalRoutes } from '../routes/Routes';

interface FilterContextProps extends Filter {
  filterOpen: boolean;
  toggleFilterOpen: (open?: boolean) => void;
}

export const FilterContext = createContext({} as FilterContextProps);

const FilterProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(filterReducer, filterInitState);
  const history = useHistory();
  const { initialized } = state;
  const { bruker } = useBrukerContext();
  const { addToast } = useToast();
  const { api } = useApi();
  const [filterOpen, setFilterOpen] = useState(false);
  const { data: filterByBruker, isSuccess: filterByBrukerIsSuccess, isError: filterByBrukerIsError } = useFilterByBruker(bruker);
  const queryClient = useQueryClient();
  queryClient.removeQueries({ queryKey: ['bruker-filter', [{ brukerId: '' }]] })

  useEffect(() => {
    if (filterByBrukerIsError) {
      history.push(globalRoutes.servererror.link);
    }
  }, [history, filterByBrukerIsError]);

  const updateState = (state: Filter): void => {
    if (bruker.erAutentisert) {
      queryClient.invalidateQueries({ queryKey: ['bruker-filter', [{ brukerId: bruker.brukerId }]] })
    }

    dispatch({ type: 'UPDATE_STATE', payload: state });
  };

  const updateMeldingSok = (sok?: string): void => {
    updateState({ ...state, currentFilter: { ...state.currentFilter, fritekstSok: sok ?? undefined } });
  };

  const toggleFilterOpen = (open?: boolean): void => {
    if (open !== undefined) setFilterOpen(open);
    else setFilterOpen(!filterOpen);
  };

  const updateCurrentFilter = (updatedFields: {
    avtaleId: string[];
    bydelId: string[];
    selskapId: string[];
    datoFra?: string;
    datoTil?: string;
    meldingsStatusId?: string[];
    meldingstypeId?: string[];
    page?: number;
    sorterEtter?: string;
  }): void => {
    const updatedState = { ...state, currentFilter: { ...state.currentFilter, ...updatedFields } };
    history.push({ ...history.location, search: buildQueryfromState(updatedState.currentFilter) });
    updateState(updatedState);
  };

  const saveFilter = async (navn: string, standard: boolean): Promise<void> => {
    const filter = buildQueryfromState(state.currentFilter);
    try {
      const newFilter = await api.post(urls.savedMeldingSearchUrl(bruker.brukerId), {
        brukerId: bruker.brukerId,
        filter: filter,
        navn: navn,
        standard: standard,
      });
      updateState({
        ...state,
        savedFilters: [
          ...state.savedFilters,
          { id: newFilter.data.result.id, filter, navn, standard, brukerId: bruker.brukerId },
        ],
      });
    } catch (e) {
      addToast(`Kunne ikke lagre filter. ${e.response?.data?.errorMessage}`, 'error');
    }
  };

  const deleteFilter = async (filterId: string): Promise<void> => {
    try {
      await api.delete(urls.deleteSavedMeldingsfilter(bruker.brukerId, filterId));
      updateState({
        ...state,
        savedFilters: [...state.savedFilters.filter((d) => d.id !== filterId)],
      });
      addToast(`Filter ble slettet.`);
    } catch (e) {
      addToast(`Kunne ikke slette filter. ${e.response?.data?.errorMessage}`, 'error');
    }
  };

  const resetFilter = (): void => {
    updateState({
      ...state,
      currentFilter: { ...state.currentFilter, ...initFilter },
    });
  };

  const useSavedFilter = (filter: string): void => {
    const f = buildStateFromQuery(filter);
    updateCurrentFilter(f);
  };

  useEffect(() => {
    if (filterByBrukerIsSuccess) {
      const savedFilters = filterByBruker ?? [];

      const setInitState = async (): Promise<void> => {
        const filterFromSearch = buildStateFromQuery(history.location.search);
        const defaultFilter = savedFilters.find((f: MeldingsFilter) => f.standard);
        if (getNumberOfSelectedFilters(filterFromSearch) > 0) {
          updateState({
            ...state,
            savedFilters: savedFilters,
            currentFilter: { ...filterFromSearch },
            initialized: true,
          });
        } else if (defaultFilter) {
          const parsedDefaultFilter = buildStateFromQuery(defaultFilter.filter);
          updateState({
            ...state,
            savedFilters: savedFilters,
            currentFilter: { ...parsedDefaultFilter },
            initialized: true,
          });

        } else {
          updateState({
            ...state,
            savedFilters: savedFilters,
            initialized: true,
          });
        }
      }

      setInitState();
    }

  }, [bruker, filterByBrukerIsSuccess]);

  useMemo(() => {
    if (filterByBrukerIsError) {
      return;
    }

    const numberOfFiltersSelected = getNumberOfSelectedFilters(state.currentFilter);
    updateState({
      ...state,
      numberOfFiltersSelected,
    });

  }, [state.currentFilter, filterByBrukerIsError]);

  return (
    <FilterContext.Provider
      value={{
        ...state,
        saveFilter,
        deleteFilter,
        updateCurrentFilter,
        updateMeldingSok,
        resetFilter,
        useSavedFilter,
        toggleFilterOpen,
        filterOpen,
      }}
    >
      {!bruker.erAutentisert || initialized || filterByBrukerIsError ? children : <PageSpinner />}
    </FilterContext.Provider>
  );
};

export default FilterProvider;

export const useFilter = (): FilterContextProps => useContext(FilterContext);
