import React, { useMemo, useState } from 'react';
import { MeldingType } from '../../interfaces';
import Checkbox from '../common/forms/Checkbox';
import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';
import { checkContainsAllValues, getGroupedMeldingstyper } from '../../utils/filterUtils';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';

const MeldingTypeFilter = (): JSX.Element => {
  const { isLoading: meldingstyperIsLoading, isError: meldingstyperIsError, data: meldingstyper } = useMeldingstyper();
  const { currentFilter, updateCurrentFilter } = useFilter();
  const [groupedMeldingstyper, setGroupedMeldingstyper] = useState<{ [key: string]: MeldingType[] }>();

  useMemo(() => {
    if (meldingstyperIsLoading || meldingstyperIsError || meldingstyper == undefined) {
      return;
    }

    setGroupedMeldingstyper(getGroupedMeldingstyper(meldingstyper));
  }, [meldingstyper, meldingstyperIsError, meldingstyperIsLoading]);

  return (
    <div>
      {meldingstyper && meldingstyper.length > 0 && (
        <ExpandablePanel title="Meldingstype" indicatorLabel={currentFilter.meldingstypeId.length}>

          <div className="flex flex-col">
            {groupedMeldingstyper &&
              Object.keys(groupedMeldingstyper)
                .sort()
                .map((key) => {
                  const groupIds: string[] = [
                    ...groupedMeldingstyper[key].map((type) => type.meldingstypeId.toString()),
                  ];
                  return (
                    <div key={key}>
                      <Checkbox
                        labelClass="heading-4"
                        key={key}
                        label={key}
                        value={groupIds}
                        checked={checkContainsAllValues(currentFilter.meldingstypeId, groupIds)}
                        handleChange={(value, checked) => {
                          if (checked)
                            updateCurrentFilter({
                              ...currentFilter,
                              meldingstypeId: [
                                ...currentFilter.meldingstypeId,
                                ...value.filter((f) => !currentFilter.meldingstypeId.includes(f)),
                              ],
                            });
                          else
                            updateCurrentFilter({
                              ...currentFilter,
                              meldingstypeId: [...currentFilter.meldingstypeId.filter((v) => !value.includes(v))],
                            });
                        }}
                      />
                      <div className="ml-3 flex flex-col">
                        {groupedMeldingstyper[key].sort().map((type) => (
                          <Checkbox
                            key={type.meldingstypeId}
                            label={`${type.beskrivelse?.split(' - ')[1]?.trim() || type.beskrivelse}`}
                            value={type.meldingstypeId}
                            checked={
                              currentFilter.meldingstypeId
                                ? currentFilter.meldingstypeId.findIndex((i) => i === type.meldingstypeId) >= 0
                                : false
                            }
                            handleChange={(value, checked) => {
                              if (checked)
                                updateCurrentFilter({
                                  ...currentFilter,
                                  meldingstypeId: [...currentFilter.meldingstypeId, ...value],
                                });
                              else
                                updateCurrentFilter({
                                  ...currentFilter,
                                  meldingstypeId: [...currentFilter.meldingstypeId.filter((v) => !value.includes(v))],
                                });
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}

          </div>
        </ExpandablePanel>
      )}
    </div>
  );
};

export default MeldingTypeFilter;
