import * as Cookies from 'js-cookie';

const COOKIE_KEY = 'bymelding_entreprenorportal_betatester';

export const isBetatester = (): boolean => {
  const cookie = Cookies.get(COOKIE_KEY);
  if (cookie && cookie.toLowerCase() === 'true') return true;
  return false;
};

export const joinBetatest = (): void => {
  Cookies.set(COOKIE_KEY, 'true', { expires: 365 });
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export const leaveBetatest = (): void => {
  Cookies.set(COOKIE_KEY, 'false');
  // eslint-disable-next-line no-restricted-globals
  location.reload();
};
