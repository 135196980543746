import React from 'react';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import './MeldingSok.scss';

const SokInstructions = ({ close }: { close: () => void }): JSX.Element => {
  return (
    <div className="border border-blue-dark my-2 p-1">
      <div className="w-full flex justify-end">
        <Button className="" onClick={close}>
          <Ikon ikon="x" />
        </Button>
      </div>
      <div className="soketops-container">
        <h1>Søketips</h1>
        <p>
          Du kan søke i alle meldinger ved å angi forskjellige søkeord med mellomrom mellom. Det vil da bli utført et
          søk etter meldinger som inneholder minst ett av ordene i de søkbare feltene (epost, adresse, beskrivelse,
          løpenummer). Dersom sortering ikke er angitt sorteres resultatene etter relevans. I tillegg til det
          grunnleggende søket kan man benytte forskjellige parametere for å skreddersy søket ytterligere.
        </p>
        <h3>Jokertegn</h3>
        <p>
          Ved å benytte jokertegnet (<i>*</i>) kan du søke med ufullstendige ord:
          <pre>youngstor*</pre>
        </p>
        <h3>Logiske uttrykk</h3>
        <p>
          Det er mulig å benytte logiske uttrykk (<i>AND</i>, <i>OR</i> og <i>NOT</i>) mellom søkeord. Disse må skrives
          med store bokstaver.
          <pre>(avfall OR søppel) AND pilesteredet AND NOT container</pre>
          Addisjon- og subtraksjonstegn (<i>+ og -</i>) kan også benyttes for å angi om et ord må eller ikke må finnes i
          resultatet:
          <pre>+søppel -container</pre>
        </p>
        <h3>Fraser / setninger</h3>
        <p>
          Der er også mulig å søke på eksakte fraser ved å skrive setninger i hermetegn:
          <pre>
            {'"'}Oslo gate{'"'}
          </pre>
        </p>
        <h3>Feltnavn</h3>
        <p>
          Dersom man ønsker å søke i ett gitt felt kan man angi feltnavn foran søkeord, med kolon mellom feltnavn og
          søkeord:
          <pre>adresse:pilestredet</pre>
          <pre>epost:some@one.com</pre>
          Gyldige feltnavn er:
          <ul>
            <li>
              <i>epost</i>
            </li>
            <li>
              <i>adressse</i>
            </li>
            <li>
              <i>beskrivelse</i>
            </li>
            <li>
              <i>meldingsnr</i>
            </li>
          </ul>
        </p>
        <h3>Kombinasjoner</h3>
        <p>
          De forskjellige måtene å søke på som er nevnt ovnefor kan også kombineres i ett og samme søk:
          <pre>adresse:youngst* AND beskrivelse:hull</pre>
          <pre>lopenummer:(121123 OR 121124)</pre>
          <pre>
            +{'"'}Oslo gate{'"'} -avfall
          </pre>
        </p>
      </div>
    </div>
  );
};

export default SokInstructions;
