import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Melding } from '../../interfaces';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';
import { getMeldingtypeFromId } from '../../utils/meldingstypeUtils';

interface MeldingRessursbrukHeaderProps {
  melding: Melding;
  index: number;
}

const MeldingRessursbrukHeader = (props: MeldingRessursbrukHeaderProps): JSX.Element => {
  const { melding, index } = props;
  const { data: meldingstyper, isSuccess: isMeldingstyperSuccess } = useMeldingstyper();


  const meldingstypeBeskrivelse = (): string => {
    if (meldingstyper) {
      const meldingstype = getMeldingtypeFromId(meldingstyper, melding.meldingstypeId);
      return meldingstype ? meldingstype.beskrivelse : '';
    }

    return '';
  }

  return (
    <div
      className={`h-full flex flex-col p-2 sticky left-0 z-nav overflow-x-hidden ${index % 2 === 0 ? 'bg-white' : 'bg-gray-light'
        }`}
      style={{
        gridArea: `${index + 2} / 1 / ${index + 3} / 2`,
        boxShadow: '10px 0 15px -10px rgba(0, 0, 0, 0.1)',
      }}
      key={melding.meldingId}
    >
      <Link to={`/arbeidsliste/ressursforbruk/melding/${melding.meldingId}`} className="underline">
        {melding.adresse}
      </Link>
      <span className="text-sm">{melding.lopeNummer}</span>
      {isMeldingstyperSuccess && meldingstyper && <span className="font-bold text-sm">{meldingstypeBeskrivelse()}</span>}
      <span className="text-sm">Opprettet: {format(new Date(melding.opprettetTid), 'dd.MM.yy')}</span>
    </div>
  );
};

export default MeldingRessursbrukHeader;
