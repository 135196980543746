import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { BrukerSelskap } from '../interfaces';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';
import { Bruker } from '../features/context/BrukerProvider';
import { AxiosError } from 'axios';

export const useBrukerselskap = (bruker: Bruker): UseQueryResult<BrukerSelskap> => {
  const { api } = useApi();

  return useQuery({
    enabled: bruker.erAutentisert,
    queryKey: ['bruker-selskap', { id: bruker.brukerId }],
    queryFn: () => {
      return api
        .get(urls.getselskapsIdForUserUrl(bruker.brukerId))
        .then((result: { data: { result: BrukerSelskap } }) => {
          return result.data.result;
        })
        .catch((error: AxiosError) => {
          return {};
        });
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
