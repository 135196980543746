import React, { useRef, useState } from "react";
import { DomEvent, DomUtil } from "leaflet";
import Button from "../common/buttons/Button";
import Ikon from "../common/Ikon";
import { useKartGlobals } from "../context/KartProvider";
import { useOutsideClickHandler } from "../hooks/ClickHandlerHooks";
import { WMSTileLayer, TileLayer, LayersControl } from "react-leaflet";
import './kartlagvelger.scss';
import { orderBy } from "lodash-es";
import KartlagItem from "./KartlagItem";

const CustomKartlagVelger = (props: { id: string }): JSX.Element => {
  const { id } = props;
  const {
    grunnkartOptions,
    setSelectedBasemap,
    selectedBasemap,
    selectedLayers,
    setSelectedLayers,
    kartlag
  } = useKartGlobals();
  const [open, setOpen] = useState(false);
  const clickOutsidekHandeler = useRef(null);
  const lefletControlId = `custom_layers_control_${id}`;

  // Stop propagation of scrolling and clicks in layers-list to map
  const element = DomUtil.get(lefletControlId);
  element && DomEvent.disableClickPropagation(element)
    .disableScrollPropagation(element);

  useOutsideClickHandler(clickOutsidekHandeler, () => {
    setOpen(false);
  });

  return (
    <>
      <LayersControl position="bottomleft">
        {grunnkartOptions.map((m) => {
          return (
            <LayersControl.BaseLayer key={m.key} name={m.displayName} checked={selectedBasemap === m.key}>
              <TileLayer
                {...m}
              />
            </LayersControl.BaseLayer>
          );
        })}
        {kartlag.map(kartlagOption => {
          const k = selectedLayers.find(kl => kl === kartlagOption.layerId);
          return (
            <LayersControl.Overlay key={kartlagOption.layerId} name={kartlagOption.displayName} checked={k !== undefined}>
              <WMSTileLayer
                key={kartlagOption.layerId}
                url={kartlagOption.url}
                layers={kartlagOption.layerId}
                format='image/png'
                transparent
                opacity={kartlagOption.opacity ? kartlagOption.opacity : 1}
              />
            </LayersControl.Overlay>)

        })}
      </LayersControl>
      <div id={lefletControlId} className="flex flex-col justify-end items-end">

        <Button
          onClick={() => setOpen(!open)}
          className="bg-white hover:bg-blue-hover hover:text-white p-1 flex justify-start items-center mobile-only:w-6 rounded-full"
        >
          <>
            <div className="flex items justify-center p-2 ">
              <Ikon ikon="kartlag" className="h-4 w-4" />
              <div className="bg-yellow rounded-full w-4 h-4 flex items-center justify-center text-blue-dark -ml-2 -mt-2">
                {selectedLayers.length}
              </div>
            </div>
            <span className="text-5 mx-2 font-bold flex-grow mobile-only:hidden">
              Kartlag
            </span>
            <Ikon ikon={`${open ? "chevron-thin-down" : "chevron-thin-right"}`} />
          </>
        </Button>
        <div ref={clickOutsidekHandeler} className={`max-w-[250px] flex flex-col justify-end`}>
          {open && (
            <>
              <div className="flex flex-row my-2 bg-white p-1 " >
                {grunnkartOptions.map(option => {
                  return (
                    <Button
                      key={option.key}
                      onClick={() => setSelectedBasemap(option.key)}
                      disabled={option.key === selectedBasemap}
                      className={`
                        grunnkart-option-${option.key} 
                        mx-1 
                        p-2
                        w-1/3 
                        font-bold 
                        text-5 
                        ${option.key === selectedBasemap
                          ? "border-2 border-blue-dark"
                          : ""}`}
                    >
                      {option.displayName}
                    </Button>)
                })}
              </div>
              <div className="bg-white max-h-96 overflow-scroll">
                <ul className="flex flex-col space-y-1 divide-x-1 w-xs ">
                  {orderBy(kartlag, ["displayName"]).map(lag => {
                    return (
                      <li key={lag.layerId}>
                        <KartlagItem kartlag={lag} />
                      </li>
                    )
                  })}
                </ul>
              </div>
              <Button
                className="bg-white hover:bg-blue-hover hover:text-white p-2 text-5 mt-2 flex justify-between items-center"
                onClick={() => {
                  setSelectedLayers([]);
                  setOpen(false);
                }}>
                <>
                  Fjern kartlag
                  <Ikon ikon="x" />
                </>
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}



export default CustomKartlagVelger;