import React, { useEffect } from 'react';
import Ikon from '../common/Ikon';
import { ToastDefinition, useToast } from './ToastProvider';

const Toast = ({ toast }: { toast: ToastDefinition }): JSX.Element => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(toast.id);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, [toast, removeToast]);

  return (
    <div
      key={toast.id}
      className={`m-3 p-3 border rounded-md max-w-full flex justify-between ${
        toast.state === 'success'
          ? 'border-green-default text-green-dark bg-green-light '
          : 'border-red text-blue-dark bg-red'
      }`}
      id={toast.id.toString()}
    >
      {toast.content}
      <button onClick={() => removeToast(toast.id)}>
        <Ikon ikon="x" />
      </button>
    </div>
  );
};

export default Toast;
