import { Avtale, Pris } from '../../interfaces';

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface ProsesskodeSelectState {
  gruppertePriser?: { value: string; name: string; priser: Pris[]; }[];
  selectedAvtale?: Avtale;
  selectedGroup?: Pris[];
}

export interface UpdateProsesskodeSelectState {
  gruppertePriser?:  { value: string; name: string; priser: Pris[]; }[];
  selectedAvtale?: Avtale;
  selectedGroup?: Pris[];
}

export const prosesskodeSelectInitState = {};

export const prosesskodeSelectReducer = (state: ProsesskodeSelectState, action: { type: stateActionType; payload: ProsesskodeSelectState }): ProsesskodeSelectState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
