import React, { forwardRef } from 'react';
import './Textarea.scss';

interface Textarea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  state?: 'primary' | 'success' | 'warning' | 'danger';
  outline?: boolean;
  label: string;
  rows?: number;
}

const Textarea = forwardRef<HTMLTextAreaElement, Textarea>(
  (props: Textarea, ref): JSX.Element => {
    const { label, state = 'primary', outline = false, required = false, rows = 6, ...rest } = props;
    return (
      <>
        <label className="textarea-field flex flex-col">
          {`${label}${required ? ' *' : ''}`}
          <textarea
            className={`${outline ? 'textarea-field-outline' : 'textarea-field'} textarea-field-${state} p-3`}
            ref={ref}
            rows={rows}
            {...rest}
          />
        </label>
      </>
    );
  }
);

Textarea.displayName = 'Textarea';
export default Textarea;
