import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Pris, Ressursforbruk } from '../../interfaces';
import { getRessursbrukListUrl } from '../api/urls';
import { updateMeldingRessursbrukByIdUrl, meldingRessursbrukUrl, ressursforbrukById } from '../api/urls';
import PageSpinner from '../common/spinner/PageSpinner';
import { useApi } from '../context/ApiProvider';
import ProsesskodeSelect from '../ressursforbruk/ProsesskodeSelect';
import RfInputWithSubmit from '../ressursforbruk/RfInputWithSubmit';
import { useToast } from '../toast/ToastProvider';
import SortByBtn from './SortByBtn';
import { orderBy } from 'lodash-es';
import './RessursbrukPanel.scss';
import MessageBox from '../common/messageBox/MessageBox';
import { useBrukerContext } from '../context/BrukerProvider';

interface RessursbrukPanelProps {
  meldingId: string;
  avtaleId?: string;
}

interface newRF {
  dato?: string;
  kommentar?: string;
  prisId?: string;
  prosesskodeEnhetsverdi?: number;
  skiftId?: number;
}

const RessursbrukPanel = (props: RessursbrukPanelProps): JSX.Element => {
  const { meldingId, avtaleId } = props;
  const [meldingRf, setMeldingRf] = useState<Ressursforbruk[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortByCol, setSortByCol] = useState('dato');
  const [sortByDir, setSortByDir] = useState<'asc' | 'desc'>('desc');

  const { addToast } = useToast();
  const { api } = useApi();
  const { bruker: user } = useBrukerContext();

  useEffect(() => {
    const getMeldingRf = async (): Promise<void> => {
      const rf = await api.get(getRessursbrukListUrl(meldingId));
      const ressursforbruk = rf.data.result.ressursforbruk;
      setMeldingRf(ressursforbruk);
    };
    getMeldingRf();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMeldingRessursforbruk = async (updatedRfb: Ressursforbruk): Promise<void> => {
    const url = updateMeldingRessursbrukByIdUrl(meldingId, updatedRfb.ressursforbrukId);
    setLoading(true);
    try {
      await api.put(url, updatedRfb);
      const newRfb = await api.get(ressursforbrukById(updatedRfb.ressursforbrukId));
      const updatedMeldingRfb = meldingRf.filter((r) => r.ressursforbrukId !== updatedRfb.ressursforbrukId) || [];
      setMeldingRf([...updatedMeldingRfb, newRfb.data.result]);
      addToast(`Ressursforbruk ble oppdatert`, 'success');
    } catch (e) {
      addToast(`Kunne ikke oppdatere ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const addMeldingRessursforbruk = async (newPessursforbruk: newRF): Promise<void> => {
    setLoading(true);
    const url = meldingRessursbrukUrl(meldingId);
    try {
      const res = await api.post(url, newPessursforbruk);
      const newRessursforbrukRes = await api.get(ressursforbrukById(res.data.result.id));
      const newRF = newRessursforbrukRes.data.result;
      setMeldingRf([...meldingRf, newRF]);
      addToast(`Ressursforbruk ble lagt til`, 'success');
    } catch (e) {
      addToast(`Kunne ikke oppdatere ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteMeldingRessursforbruk = async (rfId: string): Promise<void> => {
    setLoading(true);
    const url = ressursforbrukById(rfId);
    try {
      await api.delete(url);
      const updatedMeldingRF = meldingRf.filter((rf: Ressursforbruk) => rf.ressursforbrukId !== rfId) || [];
      setMeldingRf(updatedMeldingRF);

      addToast(`Ressursforbruk ble slettet`, 'success');
    } catch (e) {
      addToast(`Kunne ikke slette ressursforbruk. ${e.response?.data?.errorMessage}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const updateSort = (sortById: string): void => {
    if (sortById !== sortByCol) {
      setSortByCol(sortById);
      setSortByDir('desc');
    } else {
      setSortByDir(sortByDir === 'asc' ? 'desc' : 'asc');
    }
  };

  const sortableHeaders = [
    { label: 'Prosesskode', id: 'prosesskode.navn' },
    { label: 'Dato', id: 'dato' },
    { label: 'Mengde', id: 'prosesskodeEnhetsVerdi' },
  ];

  return (
    <>
      {loading && <PageSpinner />}
      {(avtaleId && meldingRf.length === 0 && user.erEntreprenor) && (
        <MessageBox state="info">
          Legg til ressursforbruk ved å velge prosesskode i nedtrekklisten.
        </MessageBox>
      )}
      {meldingRf.length > 0 && (
        <div className="ressursbruk-tabell">
          {sortableHeaders.map((header) => (
            <div key={header.id} className="bg-gray-dark p-2 ">
              <SortByBtn
                sortDir={sortByDir}
                label={header.label}
                id={header.id}
                isActive={sortByCol === header.id}
                onClick={updateSort}
              />
            </div>
          ))}
          {orderBy(meldingRf, [sortByCol], [sortByDir]).map((rf, index) => {
            return (
              <>
                <div key={`rf.ressursforbrukId_${1}`} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-light'} p-2`}>
                  {rf.prosesskode.navn} - {rf.prosesskode.beskrivelse}
                </div>
                <div key={`rf.ressursforbrukId_${2}`} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-light'} p-2`}>
                  {format(new Date(rf.dato), 'dd.MM.yyyy HH:mm')}
                </div>
                <div key={`rf.ressursforbrukId_${3}`} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-light'} p-2`}>
                  <RfInputWithSubmit
                    value={rf}
                    postfix={rf.prosesskode.prosesskodeEnhet.navn}
                    onSubmit={updateMeldingRessursforbruk}
                    onDelete={deleteMeldingRessursforbruk}
                  />
                </div>
              </>
            );
          }
          )}
        </div>)}
      <div className="max-w-lg">
        {avtaleId ? (
          <ProsesskodeSelect
            avtaleId={avtaleId}
            handleSelect={(pris: Pris) => {
              const newRfb = {
                dato: new Date().toUTCString(),
                kommentar: '',
                prisId: pris.id,
                prosesskodeEnhetsverdi: 0,
              };
              addMeldingRessursforbruk(newRfb);
            }}
          />
        ) : (
          <MessageBox state="warning">Meldingen har ingen tildelt avtale</MessageBox>
        )}

      </div>
    </>
  );
};

export default RessursbrukPanel;
