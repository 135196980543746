import React from 'react';
import { format, differenceInCalendarDays } from 'date-fns';
import { Melding } from '../../interfaces';
import Ikon from '../common/Ikon';
import { Link } from 'react-router-dom';
import StatusIkon from '../StatusIkon/StatusIkon';

import FilterLink from '../filter/FilterLink';
import OrderBySelect from './OrderBySelect';
import MeldingerListePageinator from './MeldingerListePageinator';
import Varsel from '../varsel/Varsel';
import { ENABLE_VARSEL, isFeatureEnabled } from '../../utils/featureFlagUtils';
import { getMeldingtypeFromId } from '../../utils/meldingstypeUtils';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';

type ArbeidsliteProps = {
  meldinger: Melding[];
  totaltAntall: number;
};

const MeldingerListevisning = ({ meldinger, totaltAntall }: ArbeidsliteProps): JSX.Element => {
  const { data: meldingstyper } = useMeldingstyper();

  const getAgeState = (age: number): string => {
    if (age > 10) return 'border-red';
    if (age > 5) return 'border-yellow';
    return 'border-green-light';
  };

  return (
    <>
      <div className={`p-2 flex justify-end items-center space-x-1 h-80 overflow-hidden`}>
        <div className="flex justify-start">
          <FilterLink />
        </div>
        <div className="flex-grow flex justify-start text-2">Meldinger: {totaltAntall}</div>
        <OrderBySelect />
      </div>
      <div
        className={`${totaltAntall > 100 ? 'h-body-small-header-filter-pagination' : 'h-body-small-header-filter'
          } overflow-y-scroll overflow-x-hidden`}
      >
        {isFeatureEnabled(ENABLE_VARSEL) && <Varsel />}
        {meldingstyper && meldinger.map((e) => {
          const type = getMeldingtypeFromId(meldingstyper, e.meldingstypeId);
          const age = differenceInCalendarDays(new Date(), new Date(e.opprettetTid));

          return (
            <div key={e.meldingId} className="border-gray-20 border-b mb-2">
              <Link to={`/arbeidsliste/liste/melding/${e.meldingId}`}>
                <div
                  className={`
                    flex 
                    border-l-8 
                    ${getAgeState(age)}
                    bg-gray-light hover:bg-blue-light 
                    p-3 
                    mobile-only:p-1 
                  `}
                >
                  <div
                    className={`
                      flex flex-col justify-center 
                      px-3 border-r-2 border-gray-20 
                      mobile-only:hidden
                    `}
                  >
                    <Ikon
                      title={type ? type.beskrivelse : ''}
                      ikon={type ? type.beskrivelse.split('-')[0]?.trim() : ''}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between mb-3">
                      <div className="flex-1 px-3 mobile-only:px-1 flex flex-col justify-center">
                        <div className="font-bold">{type ? type.beskrivelse : ''}</div>
                        <div>{e.adresse}</div>
                        <div>Meldingsnr.:{e.lopeNummer}</div>
                      </div>
                      <div className="flex px-3 mobile-only:px-1 flex-col items-end">
                        <div>{format(new Date(e.opprettetTid), 'dd.MM.yy HH:mm')}</div>
                        <div className="flex-1">
                          ({age} {age === 1 ? 'dag siden' : 'dager siden'})
                        </div>
                        <StatusIkon statusId={e.meldingsStatusId} />
                      </div>
                    </div>
                    <div className="flex-1 ml-3 flex flex-col">
                      {e.beskrivelse && (
                        <>
                          <div className="arbeidsliste-beskrivelse">
                            <span className="font-bold">Beskrivelse:</span> {e.beskrivelse}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      {totaltAntall > 100 && <MeldingerListePageinator totaltAntall={totaltAntall} />}
    </>
  );
};

export default MeldingerListevisning;
