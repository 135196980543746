import React, { useEffect, useState } from 'react';
import Ikon from '../common/Ikon';
import { useFilter } from '../filter/FilterProvider';

interface PaginatorProps {
  totaltAntall: number;
}

const MeldingerListePageinator = ({ totaltAntall }: PaginatorProps): JSX.Element => {
  const perPage = 100;
  const maxPages = 8;

  const [pages, setPages] = useState<number[]>([]);
  const { currentFilter, updateCurrentFilter } = useFilter();
  const { page } = currentFilter;

  const updatePage = (page: number): void => {
    updateCurrentFilter({ ...currentFilter, page: page });
  };

  useEffect(() => {
    const numberOfPages = Math.ceil(totaltAntall / perPage);
    const pages = [] as number[];
    for (let i = 1; i <= numberOfPages; i++) {
      pages.push(i);
    }
    setPages(pages);
  }, [totaltAntall]);

  return (
    <div className="bg-gray-light border-t border-gray-dark h-40 w-full flex flex-nowrap justify-between items-center">
      <button disabled={page <= 1} onClick={() => updatePage(page - 1)}>
        <Ikon ikon="chevron-thin-left" />
      </button>
      {pages.length > maxPages && page > Math.ceil(maxPages / 2) && (
        <>
          <button
            key="start"
            onClick={() => updatePage(1)}
            className={`py-1 px-2 hover:bg-blue-hover hover:text-white ${1 === page ? 'bg-blue-dark text-white' : ''}`}
          >
            1
          </button>
          <span>...</span>
        </>
      )}
      {pages.map((p) => {
        if (pages.length > maxPages && Math.abs(page - p) > maxPages / 2) {
          return;
        }
        return (
          <button
            key={p}
            onClick={() => updatePage(p)}
            className={`py-1 px-2 hover:bg-blue-hover hover:text-white ${p === page ? 'bg-blue-dark text-white' : ''}`}
          >
            {p}
          </button>
        );
      })}
      {pages.length > maxPages && Math.abs(page - pages.length) > Math.ceil(maxPages / 2) && (
        <>
          <span>...</span>
          <button
            key="end"
            onClick={() => updatePage(pages.length)}
            className={`py-1 px-2 hover:bg-blue-hover hover:text-white ${pages.length === page ? 'bg-blue-dark text-white' : ''
              }`}
          >
            {pages.length}
          </button>
        </>
      )}
      <button disabled={page >= pages.length} onClick={() => updatePage(page + 1)}>
        <Ikon ikon="chevron-thin-right" />
      </button>
    </div>
  );
};

export default MeldingerListePageinator;
