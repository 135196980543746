import React from 'react';
import { useState, useEffect } from "react";
import Button from "../common/buttons/Button";
import Checkbox from "../common/forms/Checkbox";
import Ikon from "../common/Ikon";
import { Kartlag, useKartGlobals } from "../context/KartProvider";
import KartlagItemLedgend from './KartlagItemLedgend';

const KartlagItem = (props: { kartlag: Kartlag }): JSX.Element => {
  const { kartlag } = props;
  const {
    selectedLayers,
    addOrRemoveLayer
  } = useKartGlobals();
  const [showLedgend, setShowLedgend] = useState(false);
  const [checked, setChecked] = useState(selectedLayers.includes(kartlag.layerId));

  useEffect(() => {
    setChecked(selectedLayers.includes(kartlag.layerId));
  }, [kartlag.layerId, selectedLayers]);

  return (<li className="flex flex-col justify-start items-start border-b py-1">
    <div className="flex justify-between items-center w-full px-2">

      <Checkbox handleChange={() => {
        addOrRemoveLayer(kartlag.layerId)
      }} checked={checked}>
        {kartlag.displayName}
      </Checkbox>
      <Button onClick={() => setShowLedgend(!showLedgend)} className="hover:bg-blue-hover hover:text-white" >
        <Ikon ikon={`${showLedgend ? "chevron-thin-down" : "chevron-thin-right"}`} />
      </Button>
    </div>
    {showLedgend &&
      <KartlagItemLedgend ledgendId={kartlag.ledgendId} />
    }
  </li>);
};

export default KartlagItem;
