import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCoffee,
  faSkating,
  faSquareFull,
  faTree,
  faSwimmingPool,
  faSnowplow,
  faBroom,
  faFutbol,
  faSprayCan,
  faArrowCircleLeft,
  faQuestion,
  faMap,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faTag,
  faCalendarAlt,
  faPen,
  faCamera,
  faHandPointUp,
  faSearchPlus,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import {
  BetalingsautomaterIcon,
  LawnmowerIcon,
  BiolgiskMangfold,
  Gatelys,
  Trafikklys,
  Trafikkskilt,
  HullIVeien,
  Soppel,
  Toalett,
  Snow,
  Renhold,
  Tree,
  Bom,
  Kumlokk,
  Swingset,
  Bus,
  Park,
  Bike,
  Map,
  User,
  List,
  CheckList,
  Check,
  ChevronLeft,
  PlusSign,
  Expand,
  Filter,
  X,
  CrossCircle,
  ChevronThinUp,
  Calendar,
  Handicap,
  MagnifySmall,
  ExclamationMarkCircle,
  InfoMarkCircle,
  CoinStacks,
  Edit,
  Undo,
  Dokument,
  Download,
  Menu,
  ChevronRight,
  Kartlag,
  WarningTriangle,
  ErrorHexagon,
  SuccessSquare,
  LadestolperIcon,
  OverNightingIcon,
} from '../../assets/osg-icons';

import './Ikon.scss';

type osgIcon =
  | 'chevron-right'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-up'
  | 'chevron-thin-right'
  | 'chevron-thin-down'
  | 'chevron-thin-left'
  | 'chevron-thin-up'
  | '24h'
  | 'backpack'
  | 'bike'
  | 'bus'
  | 'cafe'
  | 'calendar'
  | 'coin-stacks'
  | 'crane'
  | 'dog'
  | 'drone'
  | 'exclamation-mark-circle'
  | 'exclamation-mark-square'
  | 'filter'
  | 'fire-emblem'
  | 'handicap'
  | 'house-heart'
  | 'kartlag'
  | 'location-pin'
  | 'magnifying-glass-big'
  | 'magnifying-glass-small'
  | 'menu'
  | 'minus-sign'
  | 'museum'
  | 'park'
  | 'plus-sign'
  | 'recycling'
  | 'restaurant'
  | 'swingset'
  | 'toilet'
  | 'tram'
  | 'user'
  | 'water-faucet'
  | 'x'
  | 'x-cricle'
  | 'facebook'
  | 'instagram'
  | 'linked-in'
  | 'twitter'
  | 'heart-plus'
  | 'clock'
  | 'garbage'
  | 'tickets'
  | 'email'
  | 'chevron-thin-up'
  | 'chevron-thin-down'
  | 'elevator'
  | 'street-vendor'
  | 'coffee-cup'
  | 'defibrillator'
  | 'bathroom'
  | 'healthcare'
  | 'livingroom'
  | 'wardrobe'
  | 'bedroom'
  | 'info-mark-circle'
  | 'warning-triangle'
  | 'error-hexagon'
  | 'success-square';

type kategorikon =
  | 'badeplass'
  | 'betalingsautomater'
  | 'gressklipping'
  | 'idrettsutstyr'
  | 'ladestolper'
  | 'tagging'
  | 'ulovlig overnatting'
  | 'gatelys'
  | 'trafikklys'
  | 'skilt'
  | 'hull i veien'
  | 'toaletter'
  | 'søppel'
  | 'renhold'
  | 'lekeplass'
  | 'holdeplasser'
  | 'bymøbel'
  | 'sykkel'
  | 'brøyting/strøing'
  | 'brøyting'
  | 'strøing'
  | 'bortkjøring av snø'
  | 'trær'
  | 'bommer'
  | 'sluk, kummer og lokk'
  | 'problematiske fremmede arter';

type bymIkon = 'checklist' | 'undo' | 'dokument';

interface iconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  ikon: kategorikon | osgIcon | bymIkon | string;
  title?: string;
}

const FaIcon = ({ ...props }: FontAwesomeIconProps): JSX.Element => {
  return <FontAwesomeIcon {...props} />;
};

const Ikon = ({ ikon, title, ...rest }: iconProps): JSX.Element => {
  switch (ikon.toLowerCase()) {
    case 'badeplass':
      return <FaIcon icon="swimming-pool" title={title} />;
    case 'betalingsautomater':
      return <BetalingsautomaterIcon title={title} {...rest} />
    case 'gressklipping':
      return <LawnmowerIcon title={title} {...rest} />
    case 'idrettsutstyr':
      return <FaIcon icon="futbol" title={title} />;
    case 'ladestolper':
      return <LadestolperIcon title={title} {...rest} />
    case 'tagging':
      return <FaIcon icon="spray-can" title={title} />;
    case 'ulovlig overnatting':
      return <OverNightingIcon title={title} {...rest} />
    case 'gatelys':
      return <Gatelys title={title} {...rest} />;
    case 'trafikklys':
      return <Trafikklys title={title} {...rest} />;
    case 'skilt':
      return <Trafikkskilt title={title} {...rest} />;
    case 'hull i veien':
      return <HullIVeien title={title} {...rest} />;
    case 'toaletter':
      return <Toalett title={title} {...rest} />;
    case 'søppel':
      return <Soppel title={title} {...rest} />;
    case 'x':
      return <X title={title} {...rest} />;
    case 'x-cricle':
      return <CrossCircle title={title} {...rest} />;
    case 'renhold':
      return <Renhold title={title} {...rest} />;
    case 'lekeplass':
      return <Swingset title={title} {...rest} />;
    case 'holdeplasser':
      return <Bus title={title} {...rest} />;
    case 'bymøbel':
      return <Park title={title} {...rest} />;
    case 'sykkel':
      return <Bike title={title} {...rest} />;
    case 'brøyting/strøing': // beholder inntil videre for gamle saker
    case 'brøyting':
    case 'strøing':
    case 'bortkjøring av snø':
      return <Snow title={title} {...rest} />;
    case 'trær':
      return <Tree title={title} {...rest} />;
    case 'user':
      return <User title={title} {...rest} />;
    case 'map':
      return <Map title={title} {...rest} />;
    case 'kartlag':
      return <Kartlag title={title} {...rest} />;
    case 'list':
      return <List title={title} {...rest} />;
    case 'checklist':
      return <CheckList title={title} {...rest} />;
    case 'chevron-left':
      return <ChevronLeft title={title} {...rest} />;
    case 'plus-sign':
      return <PlusSign title={title} {...rest} />;
    case 'expand':
      return <Expand title={title} {...rest} />;
    case 'filter':
      return <Filter title={title} {...rest} />;
    case 'calendar':
      return <Calendar title={title} {...rest} />;
    case 'handicap':
      return <Handicap title={title} {...rest} />;
    case 'magnifying-glass-small':
      return <MagnifySmall title={title} {...rest} />;
    case 'exclamation-mark-circle':
      return <ExclamationMarkCircle title={title} {...rest} />;
    case 'info-mark-circle':
      return <InfoMarkCircle title={title} {...rest} />;
    case 'coin-stacks':
      return <CoinStacks title={title} {...rest} />;
    case 'check':
      return <Check title={title} {...rest} />;
    case 'edit':
      return <Edit title={title} {...rest} />;
    case 'undo':
      return <Undo title={title} {...rest} />;
    case 'dokument':
      return <Dokument title={title} {...rest} />;
    case 'download':
      return <Download title={title} {...rest} />;
    case 'menu':
      return <Menu title={title} {...rest} />;
    case 'bommer':
      return <Bom title={title} {...rest} />;
    case 'sluk, kummer og lokk':
      return <Kumlokk title={title} {...rest} />;
    case 'problematiske fremmede arter':
      return <BiolgiskMangfold title={title} {...rest} />;

    case 'chevron-right':
      return <ChevronRight title={title} className="" {...rest} />;

    case 'chevron-thin-right':
      return <ChevronThinUp title={title} className="transform rotate-90" {...rest} />;
    case 'chevron-thin-down':
      return <ChevronThinUp title={title} className="transform rotate-180" {...rest} />;
    case 'chevron-thin-left':
      return <ChevronThinUp title={title} className="transform -rotate-90" {...rest} />;
    case 'chevron-thin-up':
      return <ChevronThinUp title={title} {...rest} />;
    case 'warning-triangle':
      return <WarningTriangle title={title} {...rest} />;
    case 'error-hexagon':
      return <ErrorHexagon title={title} {...rest} />;
    case 'success-square':
      return <SuccessSquare title={title} {...rest} />;
    default:
      return <ExclamationMarkCircle title={title} {...rest} />;
  }
};

export const initilizeFaLibrary = (): void => {
  library.add(
    faCheckSquare,
    faCoffee,
    faSkating,
    faSquareFull,
    faTree,
    faSwimmingPool,
    faSnowplow,
    faBroom,
    faFutbol,
    faSprayCan,
    faArrowCircleLeft,
    faQuestion,
    faMap,
    faMapMarkerAlt,
    faMapMarkedAlt,
    faTag,
    faCalendarAlt,
    faPen,
    faCamera,
    faHandPointUp,
    faSearchPlus,
    faEye
  );
};

export default Ikon;
