import React, { useEffect } from 'react';
import L from 'leaflet';
import { Marker, Popup, useMap } from 'react-leaflet';
import { Melding } from '../../interfaces';
import { getStatusMarker } from '../../utils/statusUtils';
import MeldingPopup from './MeldingPopup';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'leaflet.markercluster';
import './MeldingerMarkers.scss';

interface MeldingerMarkersProps {
  meldinger: Melding[];
}

const MeldingerMarkers = (props: MeldingerMarkersProps): JSX.Element => {
  const { meldinger } = props;
  const map = useMap();

  useEffect(() => {
    if (meldinger.length > 0) map.fitBounds(meldinger.map(m => [m.latitude, m.longitude]))
  }, [meldinger, map]);

  return (
    <MarkerClusterGroup>
      {meldinger.map((melding: Melding) => (
        <Marker
          icon={
            new L.Icon({
              iconUrl: getStatusMarker(melding.meldingsStatusId),
              iconSize: new L.Point(40, 40),
              className: ``,
            })
          }
          key={melding.meldingId}
          position={[melding.latitude, melding.longitude]}
        >
          <Popup>
            <MeldingPopup melding={melding} />
          </Popup>
        </Marker>
      ))}
    </MarkerClusterGroup>
  );
};

export default MeldingerMarkers;
