import React, { useReducer, useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { Adresse, adresseSok } from '../../utils/adresseUtils';
import { adresseInputReducer, initState, updateAdresseInputState } from './AdresseInputReducer';
import Ikon from '../common/Ikon';
import Input from '../common/forms/Input';
import Button from '../common/buttons/Button';
import './AdresseInput.scss';

interface AdresseInputProps {
  onChange: (adresse: string, latitude: number, longitude: number) => void;
  value: string;
}

const AdresseInput = (props: AdresseInputProps): JSX.Element => {
  const { onChange, value } = props;
  const [state, dispatch] = useReducer(adresseInputReducer, initState);
  const { searchString, serchResult, activeItem, showResult } = state;

  const updateState = (updatedState: updateAdresseInputState): void => {
    dispatch({ type: 'UPDATE_STATE', payload: { ...state, ...updatedState } });
  };

  useMemo(() => {
    if (value !== searchString) updateState({ searchString: value, showResult: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const findAddress = async (soketekst: string): Promise<void> => {
    updateState({
      searchString: soketekst,
    });
    if (soketekst.length > 3) {
      const res = await adresseSok(soketekst, 5);
      updateState({
        searchString: soketekst,
        serchResult: sortBy(res, ['adressenavn', 'nummer', 'bokstav']),
        showResult: true,
      });
    } else {
      updateState({ searchString: soketekst, serchResult: [], showResult: false });
    }
  };

  const onSelectAdress = (item: Adresse): void => {
    updateState({
      ...initState,
      showResult: false,
      searchString: item.adressetekst,
    });
    onChange(item.adressetekst, item.representasjonspunkt?.lat || 0, item.representasjonspunkt?.lon || 0);
  };

  const handleKeybordEvent = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    let i = 0;
    if (e.key === 'Enter' && serchResult.length > 0) {
      onSelectAdress(serchResult[activeItem]);
    } else if (e.key === 'ArrowUp') {
      i = activeItem - 1;
    } else if (e.key === 'ArrowDown') {
      i = activeItem + 1;
    } else if (e.key === 'Home') {
      i = 0;
    } else if (e.key === 'End') {
      i = serchResult.length - 1;
    } else if (e.key === 'Escape') {
      updateState(initState);
      return;
    }
    if (i >= 0 && i < serchResult.length) {
      updateState({ activeItem: i });
    }
  };

  return (
    <div className="adressesok-container">
      <div className="mx-auto py-2">
        <Input
          outline
          label="Adresse"
          type="text"
          id="adressesok"
          onChange={(e) => {
            findAddress(e.target.value);
          }}
          onReset={() => {
            updateState({
              searchString: '',
              serchResult: [],
            });
          }}
          onKeyUp={handleKeybordEvent}
          value={searchString}
        />
      </div>

      {showResult && (
        <div className={`container z-modalBackdrop divide-y-1 ${showResult ? 'absolute' : 'hidden'}`} tabIndex={-1}>
          {serchResult.map((item, index) => (
            <div key={item.adressetekst}>
              <Button
                outline
                onClick={() => {
                  onSelectAdress(item);
                }}
                className={`
                  ${index === activeItem ? 'active' : 'inactive'}
                  adressesok-resultlist-item
                  z-modal
                  flex flex-row items-center justify-between 
                  p-3 w-full
                `}
              >
                <>
                  {item.adressetekst}
                  <Ikon ikon="chevron-thin-right" />
                </>
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdresseInput;
