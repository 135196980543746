import { sortBy } from 'lodash-es';
import React from 'react';
import { MeldingsStatus } from '../../interfaces';
import { checkContainsAllValues } from '../../utils/filterUtils';
import { getStatusIdsOfChildren } from '../../utils/statusUtils';
import Checkbox from '../common/forms/Checkbox';
import { useFilter } from './FilterProvider';

const MeldingStatusFilterItem = ({ status }: { status: MeldingsStatus }): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  if (status.children) {
    const groupIds = getStatusIdsOfChildren(status);
    return (
      <>
        <Checkbox
          labelClass=""
          key={status.id}
          label={`${status.navn}`}
          value={groupIds}
          checked={checkContainsAllValues(currentFilter.meldingsStatusId, groupIds)}
          handleChange={(value, checked) => {
            if (checked)
              updateCurrentFilter({
                ...currentFilter,
                meldingsStatusId: [
                  ...currentFilter.meldingsStatusId,
                  ...value.filter((f) => !currentFilter.meldingsStatusId.includes(f)),
                ],
              });
            else
              updateCurrentFilter({
                ...currentFilter,
                meldingsStatusId: [...currentFilter.meldingsStatusId.filter((v) => !value.includes(v))],
              });
          }}
        />
        <div className="ml-4 flex flex-col">
          {sortBy(status.children, (item: MeldingsStatus) => `${item.children?.length || 0}${item.navn}`).map(
            (understatus: MeldingsStatus) => (
              <MeldingStatusFilterItem status={understatus} key={understatus.id} />
            )
          )}
        </div>
      </>
    );
  } else {
    return (
      <Checkbox
        key={status.id}
        label={`${status.navn}`}
        value={status.id.toString()}
        checked={
          currentFilter.meldingsStatusId
            ? currentFilter.meldingsStatusId.findIndex((i) => i === status.id.toString()) >= 0
            : false
        }
        handleChange={(value, checked) => {
          if (checked)
            updateCurrentFilter({
              ...currentFilter,
              meldingsStatusId: [...currentFilter.meldingsStatusId, ...value],
            });
          else
            updateCurrentFilter({
              ...currentFilter,
              meldingsStatusId: [...currentFilter.meldingsStatusId.filter((v) => !value.includes(v))],
            });
        }}
      />
    );
  }
};

export default MeldingStatusFilterItem;
