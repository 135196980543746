import React, { useState } from 'react';
import Lokasjonsvelger, { LatLng } from './Lokasjonsvelger';
import { Popup } from 'react-leaflet';
import Button from '../common/buttons/Button';
import { useBrukerContext } from '../context/BrukerProvider';
import { MeldingDetaljer } from '../../interfaces';
import { useToast } from '../toast/ToastProvider';
import { updatedMeldingProps } from '../melding/MeldingPage';
import AdresseInput from '../NyMeldingPage/AdresseInput';
import MessageBox from '../common/messageBox/MessageBox';

interface UpdateLocationMapProps {
  melding: MeldingDetaljer;
  updateMelding: (meldingId: string, avtaleId: string, updatedMelding: updatedMeldingProps) => void;
}

const UpdateLocationMap = ({ melding, updateMelding }: UpdateLocationMapProps): JSX.Element => {
  const { bruker: user } = useBrukerContext();
  const { addToast } = useToast();
  const [updatedLocation, setUpdatedLocation] = useState<LatLng | null>(null);
  const [adresse, setAdresse] = useState("");

  const originalLocation = { lat: melding.latitude, lng: melding.longitude };

  const reset = (): void => {
    setUpdatedLocation(null);
    setAdresse("");
  }

  const saveUpdatedMelding = (updatedLocation: LatLng, adresse: string): void => {
    const updatedMelding = {
      adresse: adresse,
      latitude: updatedLocation.lat,
      longitude: updatedLocation.lng,
    }
    const avtaleId = melding.avtale?.avtaleId;
    try {
      if (user.erEntreprenor && avtaleId) {
        updateMelding(melding.meldingId, avtaleId, updatedMelding);
      } else if (user.erServiceavdeling) {
        updateMelding(melding.meldingId, '', updatedMelding);
      }

      reset();
    } catch (e) {
      addToast("Kunne ikke oppdatere meldingens lokasjon", "error")
    }
  }

  return (
    <div>
      <div>
        <AdresseInput
          value={adresse}
          onChange={(adresse: string, latitude: number, longitude: number) => {
            setAdresse("");
            setUpdatedLocation({ lat: latitude, lng: longitude });
          }}
        />
      </div>
      {updatedLocation && !adresse && (
        <MessageBox state="warning" >
          Velg nøyaktig posisjon ved å klikke i kartet
        </MessageBox>
      )}
      <Lokasjonsvelger
        mapCenter={updatedLocation || originalLocation}
        markerPosition={originalLocation}
        onPlaceMarker={(markerPosition, adresse) => {
          if (markerPosition) {
            setUpdatedLocation(markerPosition);
            setAdresse(adresse || "Ukjent adresse");
          }
        }}
      >
        <>
          {updatedLocation && adresse && (
            <Popup
              position={updatedLocation}
              onClose={reset}>
              <div className="text-5">
                <h4>{adresse}</h4>
                <p>Vil du flytte markeren hit?</p>
                <div className="flex flex-row justify-start space-x-2">
                  <Button
                    color="green-dark"
                    onClick={() => saveUpdatedMelding(updatedLocation, adresse)}
                  >
                    Ja
                  </Button>
                  <Button
                    outline
                    onClick={reset}
                  >
                    Avbryt
                  </Button>
                </div>
              </div>
            </Popup>
          )}
        </>
      </Lokasjonsvelger>
    </div>
  )
}



export default UpdateLocationMap;
