import React, { useState } from 'react';
import Spinner from './Spinner';
import { Transition } from 'react-transition-group';

const SildeInSpinner = ({ loading }: { loading: boolean }): JSX.Element => {
  const transition = {
    entering: '-translate-y-0 opacity-0',
    entered: 'translate-y-16 opacity-100',
    exiting: 'translate-y-16 opacity-100',
    exited: '-translate-y-0 opacity-0',
  };
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <div className={`flex justify-around items-center overflow-hidden`}>
      <Transition
        in={loading}
        timeout={300}
        onEnter={() => {
          setShowSpinner(true);
        }}
        onExited={() => {
          setShowSpinner(false);
        }}
      >
        {(state: 'entering' | 'entered' | 'exiting' | 'exited') => (
          <div
            className={`
              h-16 w-16 absolute
              transition-all duration-300
              z-spinner
              transform ${transition[state]}`}
          >
            {showSpinner && <Spinner color="green-dark" spin={showSpinner} />}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default SildeInSpinner;
