import React from 'react';
import Checkbox from '../common/forms/Checkbox';
import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';
import { Avtale } from '../../interfaces';
import { useAvtalerByBruker } from '../../queryHooks/useAvtalerByBruker';
import { useBrukerContext } from '../context/BrukerProvider';

const AvtaleFilter = (): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  const { bruker } = useBrukerContext();
  const { data: avtaler } = useAvtalerByBruker(bruker)

  if (avtaler && avtaler?.length > 1) {
    return (
      <ExpandablePanel title="Avtale" indicatorLabel={currentFilter.avtaleId.length}>
        <div className="flex flex-col">
          {avtaler.map((avtale: Avtale) => {
            return (
              <Checkbox
                key={avtale.avtaleId}
                label={`${avtale.navn} - ${avtale.beskrivelse}`}
                value={avtale.avtaleId}
                checked={
                  currentFilter.avtaleId ? currentFilter.avtaleId.findIndex((i) => i === avtale.avtaleId) >= 0 : false
                }
                handleChange={(value, checked) => {
                  if (checked)
                    updateCurrentFilter({
                      ...currentFilter,
                      avtaleId: [...currentFilter.avtaleId, ...value],
                    });
                  else
                    updateCurrentFilter({
                      ...currentFilter,
                      avtaleId: [...currentFilter.avtaleId.filter((v) => !value.includes(v))],
                    });
                }}
              />
            );
          })}
        </div>
      </ExpandablePanel>
    )
  }
  else return <></>
};

export default AvtaleFilter;
