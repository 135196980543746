import { groupBy, orderBy } from 'lodash-es';
import React, { useEffect, useReducer } from 'react';
import { Pris } from '../../interfaces';
import DropdownSelect from '../common/forms/DropdownSelect';
import {
  prosesskodeSelectInitState,
  prosesskodeSelectReducer,
  UpdateProsesskodeSelectState,
} from './ProsesskodeSelectReducer';
import { useAvtalerByBruker } from '../../queryHooks/useAvtalerByBruker';
import { useBrukerContext } from '../context/BrukerProvider';
import { getAvtaleFromId } from '../../utils/avtaleUtils';

interface ProsesskodeSelectProps {
  handleSelect: (pk: Pris) => void;
  avtaleId?: string;
}

export const getPrisFromProsesskodeId = (id: string, pkListe: Pris[]): Pris | undefined => {
  const pris = pkListe.find((e) => e.prosesskode?.id && e.prosesskode.id === id);
  return pris;
};

const ProsesskodeSelect = (props: ProsesskodeSelectProps): JSX.Element => {
  const { handleSelect, avtaleId } = props;
  const { bruker } = useBrukerContext();
  const { data: avtaler } = useAvtalerByBruker(bruker);
  const [state, dispatch] = useReducer(prosesskodeSelectReducer, prosesskodeSelectInitState);
  const { selectedAvtale, gruppertePriser, selectedGroup } = state;

  const updateState = (update: UpdateProsesskodeSelectState): void => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: { ...state, ...update },
    });
  };

  useEffect(() => {
    if (selectedAvtale && selectedAvtale.prisListe) {
      // Grupper prosesskoder basert på kode og deler av beskrivele (eks. Arbeidstype) for lettere å kunne finne riktig kode i dropdawn

      const g = groupBy(selectedAvtale.prisListe, (i) => {
        const { navn, beskrivelse } = i.prosesskode;
        let typeArbeidKode = '';
        if (navn.indexOf('BYM') === 0) {
          typeArbeidKode = 'BYM';
        } else if (navn.indexOf('ENT') === 0) {
          typeArbeidKode = 'ENT';
        } else {
          typeArbeidKode = navn[0];
        }
        const typeArbeidBeskrivelse = beskrivelse.substring(0, beskrivelse.indexOf(' - '));

        return `${typeArbeidKode} - ${typeArbeidBeskrivelse}`;
      });
      const groups = [
        ...Object.keys(g)
          .sort()
          .map((gruppe) => ({
            value: gruppe,
            name: gruppe,
            priser: g[gruppe],
          })),
      ];
      groups.unshift({
        value: 'Alle prosesskoder',
        name: 'Alle prosesskoder',
        priser: selectedAvtale.prisListe,
      });

      const mestBrukteAvtale = selectedAvtale.prisListe.filter((pris) => mestBrukte.includes(pris.prosesskode.id));
      if (mestBrukteAvtale.length > 0) {
        groups.unshift({
          value: 'Mest brukte prosesskoder',
          name: 'Mest brukte prosesskoder',
          priser: mestBrukteAvtale,
        });
      }
      updateState({ gruppertePriser: groups, selectedGroup: groups[0].priser });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAvtale]);

  useEffect(() => {
    if (!avtaler) {
      return;
    }

    if (avtaleId) updateState({ selectedAvtale: avtaler.find((avtale) => avtale.avtaleId === avtaleId) });
    else if (avtaler.length === 1) updateState({ selectedAvtale: avtaler[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avtaler, avtaleId]);

  return (
    <div className="flex flex-col space-y-2">
      {avtaler && avtaler.length > 1 && !avtaleId && (
        <DropdownSelect
          label="Avtale"
          onChange={(e) => {
            updateState({ selectedAvtale: getAvtaleFromId(e.target.value, avtaler) });
          }}
          options={[
            { value: '', name: 'Velg en avtale' },
            ...avtaler.map((a) => ({ value: a.avtaleId, name: a.beskrivelse })),
          ]}
        />
      )}
      {selectedAvtale &&
        (selectedAvtale.prisListe && gruppertePriser ? (
          <>
            <DropdownSelect
              label="Velg type arbeid"
              onChange={(e) => {
                if (e) {
                  updateState({ selectedGroup: gruppertePriser.find((i) => i.value === e.target.value)?.priser });
                }
              }}
              options={gruppertePriser}
            />
            {selectedGroup && (
              <DropdownSelect
                label="Legg til prosesskode"
                onChange={(e) => {
                  if (selectedAvtale.prisListe) {
                    const pris = getPrisFromProsesskodeId(e.target.value, selectedAvtale.prisListe);
                    if (pris) {
                      handleSelect(pris);
                    }
                  }
                }}
                options={[
                  { value: '', name: 'Velg prosesskode' },
                  ...orderBy(selectedGroup, ['prosesskode.navn'], 'asc').map((pris) => ({
                    value: pris.prosesskode.id,
                    name: `${pris.prosesskode.navn} - ${pris.prosesskode.beskrivelse}`,
                  })),
                ]}
              />
            )}
          </>
        ) : (
          <div>Det finnes ingen prosesskoder knyttet til denne avtalen</div>
        ))}
    </div>
  );
};

export default ProsesskodeSelect;

const mestBrukte = [
  // Skilt
  '8c0bd747-281a-4153-bd8c-586e0fb5d766', // A1.1.1
  '86852a65-6b94-4295-b762-6957f2705949', // A1.1.2
  '5622e798-738b-467b-8cc0-5d8af4946b56', // A1.2.1
  'e2f81cad-2719-4821-99df-141a78576e46', // A1.2.2
  '8baef5ea-69fc-4c52-bed2-a5fd223e2df7', // A1.3.1
  'aaa3378d-9448-48c0-b7c3-67aaa29d636b', // C3.1.1
  '5f8c1282-86d0-46f6-87d5-866a6eb9b7bd', // C3.1.2
  '7785c438-4803-40a7-b7d0-445c64a912b0', // E5.1.1
  '54e979d9-405b-4cba-b205-d13b135fb0ce', // E5.1.3
  '42388555-9273-43b8-8d14-1fa85e888b8f', // F6.1.1
  '54f40534-63c1-4fc9-8342-ec22b34a6650', // F6.1.1
  '62d4d901-3cbb-4d87-b15b-684e95e45b6d', // G7.1.1
  'dab42fef-cc50-488e-9c61-098b0726d01e', // G7.2.2
  '64e5c4d8-a751-4dbe-8977-c8960e9cec40', // H8.1.1
  'd84df7fc-8b61-4c2a-b48b-500493fd1acb', // H8.1.3
  'af96a06a-9c03-4245-b62b-4bad4f1efaff', // I9.1.5
  '39132e40-2c77-4917-b4dd-4051852e2831', // I9.1.6
  '10ca6849-6f84-4aab-a6ce-06d7dcb294ce', // I9.1.7
  '3acfd1ae-0135-4cb9-ab83-23fa272749e7', // I9.2.10
  'e8c173e1-8063-4892-8a59-bbda2795a2f0', // I9.2.12
  '695f764f-055b-467b-8937-139738e5a91c', // I9.2.15
  '98671ab5-576b-4513-a4cf-a82f35969ab0', // J10.1.2
  '5357cd0e-4dd0-4329-b19d-c6cbba545197', // K11.1.10
  '58998eaf-b01c-4d1c-9295-64a8a52749c4', // K11.1.11
  'ea6e777b-3d3b-47ed-841c-34c912cf40c2', // K11.1.6
  'ead7f16a-3703-46c5-90b5-f29afb42f2a8', // K11.1.8
  '35070763-0b49-4b89-811b-522f4a265888', // K11.1.9
  '5357cd0e-4dd0-4329-b19d-c6cbba545197', // K11.1.10
  '35070763-0b49-4b89-811b-522f4a265888', // K11.1.9
  '58998eaf-b01c-4d1c-9295-64a8a52749c4', // K11.1.11
  'ede5a46c-2959-4694-bdc1-1aa36524513c', // K11.1.12
  '1e75f8c5-1a40-425c-8bab-8214f3a7cb7e', // K11.1.13
  'd3160154-eabb-479c-b476-b14704cf55c6', // K11.1.14
  '1b54996e-cf13-49b7-bccd-11388f81fe54', // K11.1.15
  'ef70a9a8-cb4c-468c-a970-7f02ebb3fdf3', // K11.1.16
  '881f9c24-9356-4207-bc3b-7a267cd58ae1', // K11.1.17
  '37768aa2-bca9-4c25-9c62-1d1a99793526', // K11.1.18
  'a35c6c6c-ace2-4366-9f0c-83e63ee14c60', // J10.1.13
  'c4bee89d-73e1-4d21-befe-ab9f513bef34', // J10.1.14
  '42388555-9273-43b8-8d14-1fa85e888b8f', // F6.1.1
  '41af2729-c54e-49c3-b8af-2ca283747ad7', // B2.4.6
  '0d5b6a95-84fd-4ed5-81c2-10040f297833', // B2.4.6
  'c024666b-3f61-412c-9913-54ca21eda529', // I9.2.7
  '353626a6-5c79-4e32-8164-e9590a4ee3d8', // B2.3.8
  '5d2875f9-4951-46a5-bf12-946b474cbc65', // B2.3.1
  'e8cdb00b-85fd-4855-8865-6d437efddfb5', // B2.4.6
  'c6d4d75a-cfdb-4a95-ba2e-8a4be8afee1a', // C3.2.1
  '738357a1-7ac7-41f5-8756-e900225876ec', // I9.1.8
  '6b21581d-6b9b-47eb-8cf1-c4590d93a31c', // G7.1.3
  'ebc26a83-7a87-4361-b099-47b2d2e6db43', // B2.6.15
  '54f40534-63c1-4fc9-8342-ec22b34a6650', // F6.1.1
  'f58e6012-09bf-4daf-b34b-dd15b1a195db', // B2.3.6
  '2098f855-d5f2-4a6b-9a13-42737a880f2a', // G7.4.15
  '674f56e4-b5b4-4491-ab65-d32a8565a63e', // G7.4.14
  '348207cd-014d-4374-9f5e-9d182cf063f4', // I9.2.6
  '129c6b02-1751-4740-b93b-0376ce20aa03', // C3.2.2
  '49234f3d-8be2-48ed-b588-cfdd155ada05', // K11.1.1
  'd7665460-3b10-4f64-babc-f04869cccf9d', // I9.2.11
  '5d1d814a-00d0-45b0-b8db-490d8810da22', // J10.1.12
  'fb0b58ac-d6d0-42c0-98d6-ad1f324ae67e', // B2.6.21
  // Lapping med asfalt - Utlagt masse
  "de5280bf-d71a-47cd-bfa8-30b9205348cc" // E2.5
];
