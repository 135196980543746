import { startsWith } from 'lodash-es';
import React, { useState } from 'react';
import { dokumentasjonMeldingUrl } from '../../api/urls';
import Button from '../../common/buttons/Button';
import FileInput from '../../common/forms/FIleInput';
import Ikon from '../../common/Ikon';
import Modal from '../../common/modal/Modal';
import PageSpinner from '../../common/spinner/PageSpinner';
import { useApi } from '../../context/ApiProvider';

const LeggTilDokumantasjonForm = ({
  meldingId,
  onChange,
}: {
  meldingId: string;
  onChange: () => void;
}): JSX.Element => {
  const [filer, setFiler] = useState([] as File[]);
  const [loading, setLoading] = useState(false);
  const { api } = useApi();

  const onRemoveImage = (index: number): void => {
    filer.splice(index, 1);
    setFiler([...filer]);
  };

  const uploadFiles = async (): Promise<void> => {
    setLoading(true);

    const form = new FormData();
    filer.forEach((fil) => form.append('dokumentasjon', fil));
    try {
      await api.put(dokumentasjonMeldingUrl(meldingId), form);
      setFiler([]);
      onChange();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <PageSpinner />}
      <div>
        <FileInput
          filer={filer}
          onChange={(f: File[]) => {
            setFiler([...filer, ...f]);
          }}
          acceptedFormats={['image/*', 'application/pdf']}
          preview={false}
        />
      </div>
      <Modal isOpen={filer.length > 0} closeButton toggle={() => setFiler([])}>
        <div className="add-images">
          <ul className="flex flex-row flex-wrap">
            {filer &&
              filer.map((b: File, index: number) => (
                <li key={b.name} className="add-images-preview-item">
                  <Button
                    className={`
                      w-4 h-4 
                      flex items-center justify-center 
                      border-2 border-solid border-white rounded-full 
                      bg-gray-80 text-white 
                      absolute 
                      cursor-pointer
                `}
                    onClick={() => onRemoveImage(index)}
                  >
                    <Ikon ikon="x" />
                  </Button>
                  {startsWith(b.type, 'image/') ? (
                    <img className="add-images-preview-item-img" src={URL.createObjectURL(b)} />
                  ) : (
                    <Ikon className="add-images-preview-item-img" ikon="dokument" />
                  )}
                  <div className="add-images-filename">{b.name}</div>
                </li>
              ))}
          </ul>
        </div>
        <div className="flex justify-end space-x-3">
          <Button outline onClick={() => setFiler([])} disabled={loading}>
            Avbryt
          </Button>
          <Button onClick={uploadFiles} disabled={loading}>
            Last opp
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default LeggTilDokumantasjonForm;
