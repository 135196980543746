import React from 'react';

export type MessageBoxStateTypes = 'muted' | 'primary' | 'success' | 'warning' | 'danger' | 'info';

interface MessageBoxProps extends React.HTMLProps<HTMLDivElement> {
  state?: MessageBoxStateTypes;
}
export default function MessageBox({ state, children, ...rest }: MessageBoxProps): JSX.Element {
  const getStateColors = (state?: MessageBoxStateTypes): string => {
    switch (state) {
      case 'danger':
        return 'bg-red text-blue-dark';
      case 'warning':
        return 'bg-yellow text-blue-dark';
      case 'success':
        return 'bg-green-light text-blue-dark';
      case 'info':
        return 'bg-blue-light text-blue-dark';
      default:
        return 'bg-gray-light text-gray-dark';
    }
  };

  return (
    <div className={`p-2 ${getStateColors(state)}`} {...rest}>
      {children}
    </div>
  );
}
