/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
export interface MeldingsFilter {
  brukerId: string;
  filter: string;
  id: string;
  navn: string;
  standard: boolean;
}

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface Filter {
  savedFilters: MeldingsFilter[];
  currentFilter: FilterData;
  loading: boolean;
  initialized: boolean;
  numberOfFiltersSelected: number;
  saveFilter: ((navn: string, standard: boolean) => Promise<void>) | (() => void);
  deleteFilter: ((filterId: string) => Promise<void>) | (() => void);
  resetFilter: () => void;
  useSavedFilter: ((id: string) => void) | (() => void);
  updateMeldingSok: ((sok?: string) => void) | (() => void);
  updateCurrentFilter: (updatedFields: {
    avtaleId: string[];
    selskapId: string[];
    bydelId: string[];
    datoFra?: string;
    datoTil?: string;
    sistEndretDatoFra?: string;
    sistEndretDatoTil?: string;
    meldingsStatusId: string[];
    meldingstypeId: string[];
    fritekstSok?: string;
    page: number;
    sorterEtter?: string;
  }) => void;
}

export interface FilterData {
  meldingsStatusId: string[];
  avtaleId: string[];
  selskapId: string[];
  meldingstypeId: string[];
  bydelId: string[];
  datoFra?: string;
  datoTil?: string;
  sistEndretDatoFra?: string;
  sistEndretDatoTil?: string;
  sistEndretAvBruker?: string;
  fritekstSok?: string;
  sorterEtter: string;
  page: number;
}

export const initFilter = {
  meldingsStatusId: [],
  avtaleId: [],
  selskapId: [],
  meldingstypeId: [],
  bydelId: [],
  datoFra: undefined,
  datoTil: undefined,
  sistEndretDatoFra: undefined,
  sistEndretDatoTil: undefined,
  fritekstSok: undefined,
};

export const filterInitState = {
  savedFilters: [],
  currentFilter: {
    ...initFilter,
    page: 1,
    sorterEtter: 'dato',
  },
  numberOfFiltersSelected: 0,
  loading: false,
  initialized: false,
  saveFilter: (): void => {},
  deleteFilter: (): void => {},
  updateCurrentFilter: (): void => {},
  updateMeldingSok: (): void => {},
  resetFilter: (): void => {},
  useSavedFilter: (): void => {},
  toggleFilterOpen: (): void => {},
};

export const filterReducer = (state: Filter, action: { type: stateActionType; payload: Filter }): Filter => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
