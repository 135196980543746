import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import { useOutsideClickHandler } from '../hooks/ClickHandlerHooks';
import { useFilter } from './FilterProvider';
import { MeldingsFilter } from './filterReducer';

const SavedFilterItem = (props: { filter: MeldingsFilter }): JSX.Element => {
  const { filter } = props;
  const { deleteFilter, useSavedFilter } = useFilter();
  const { push } = useHistory();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const abortDeleteOnOutsideClickRef = useRef(null);
  useOutsideClickHandler(abortDeleteOnOutsideClickRef, () => {
    setShowConfirmDelete(false);
  });

  const handleSelectFilter = (filter: string): void => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSavedFilter(filter);
    push('liste');
  };

  return (
    <div className="flex flex-row items-center m-2">
      <Button
        color="blue-dark"
        onClick={() => {
          handleSelectFilter(filter.filter);
        }}
      >
        <span className="whitespace-no-wrap">{`${filter.navn}${filter.standard ? ' (Standard)' : ''}`}</span>
      </Button>
      {!showConfirmDelete ? (
        <Button
          className="h-full border-2 border-blue-dark"
          outline
          onClick={() => {
            setShowConfirmDelete(true);
          }}
        >
          <Ikon ikon="x" />
        </Button>
      ) : (
        <div ref={abortDeleteOnOutsideClickRef}>
          <Button
            color="red"
            onClick={() => {
              deleteFilter(filter.id);
            }}
          >
            <span className="whitespace-no-wrap flex flex-row flex-no-wrap items-center">Slett filter?</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default SavedFilterItem;
