import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import {
  grunnkartOptions,
  kartlag,
  layerInfoUrl
} from "../../utils/kartUtils";
import { useEffect } from 'react';
import { useMemo } from 'react';
import { getItem, setItem } from '../../utils/LocalStoreUtils';

export interface Kartlag {
  displayName: string;
  layerId: string;
  ledgendId: string | number;
  url: string;
  opacity?: number;
}

export interface KartlagLedgend {
  layerId: number;
  layerName: string;
  layerType: string;
  legend: {
    contentType: string;
    height: number;
    imageData: string;
    label: string;
    url: string;
  }[]
  maxScale: number;
  minScale: number;
};

interface kartGlobalsContext {
  ledgend: KartlagLedgend[];
  selectedLayers: string[];
  setSelectedLayers: (layers: string[]) => void;
  addOrRemoveLayer: (layerId: string) => void;
  selectedBasemap: string;
  setSelectedBasemap: (basemap: string) => void;
  grunnkartOptions: {
    key: string;
    displayName: string;
    url: string;
    attribution: string;
    subdomains?: string[];
    getMatrix?: (data: { z: number }) => string;
  }[];
  kartlag: Kartlag[];
}

export const KartContext = createContext({} as kartGlobalsContext);
const selectedBasemapKey = "selectedBasemap";
const selectedLayersKey = "selectedLayers";

const KartProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;

  const [layersLedgends, setLayersLedgends] = useState([]);
  const [selectedLayers, setSelectedLayers] = useState<string[]>(getItem(selectedLayersKey) || []);
  const [selectedBasemap, setSelectedBasemap] = useState(getItem(selectedBasemapKey) || grunnkartOptions[0].key);

  const addOrRemoveLayer = (layerId: string): void => {
    const idIndex = selectedLayers.findIndex((layer) => layer === layerId);
    if (idIndex >= 0) setSelectedLayers([...selectedLayers.filter(layer => layer !== layerId)]);
    else setSelectedLayers([...selectedLayers, layerId]);
  }

  useMemo(() => {
    setItem(selectedBasemapKey, selectedBasemap);
  }, [selectedBasemap])

  useMemo(() => {
    setItem(selectedLayersKey, selectedLayers);
  }, [selectedLayers])

  useEffect(() => {
    const getLedgends = async (): Promise<void> => {
      const ledgends = await axios.get(layerInfoUrl);
      setLayersLedgends(ledgends.data.layers)
    }
    getLedgends();
  }, [])

  return <KartContext.Provider value={{
    ledgend: layersLedgends,
    selectedLayers,
    setSelectedLayers,
    addOrRemoveLayer,
    selectedBasemap,
    setSelectedBasemap,
    grunnkartOptions,
    kartlag,
  }}>{children}</KartContext.Provider>
}

export const useKartGlobals = (): kartGlobalsContext => useContext(KartContext);

export default KartProvider;
