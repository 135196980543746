import React from "react";
import { Redirect } from 'react-router';
import { globalRoutes } from "./Routes";
import { useBrukerContext } from '../context/BrukerProvider';

function RedirectRoute(): JSX.Element {
  const brukerContext = useBrukerContext();

  if (!brukerContext.bruker.erAutentisert) {
    return <div>Logger på...</div>;
  } else {
    return <Redirect to={globalRoutes.arbeidsliste.link}></Redirect>;
  }
}

export default RedirectRoute;