import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import { roller } from '../../utils/constants';
import AccessToken from '../../interfaces/AccessToken';
import IdToken from '../../interfaces/IdToken';
import jwt_decode from 'jwt-decode';

export interface Bruker {
  oneLoginId: string;
  brukerId: string;
  navn: string;
  epost: string;
  roller: string[];
  erEntreprenor: boolean;
  erServiceavdeling: boolean;
  erAutentisert: boolean;
}

const initBruker: Bruker = {
  oneLoginId: '',
  brukerId: '',
  navn: '',
  epost: '',
  roller: [],
  erEntreprenor: false,
  erServiceavdeling: false,
  erAutentisert: false,
};

interface BrukerContext {
  bruker: Bruker;
  login: () => void;
  logout: () => void;
  error: Error | undefined;
  initialized: boolean;
}

export const BrukerContext = createContext({} as BrukerContext);

const BrukerProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const [bruker, setBruker] = useState(initBruker);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);
  const auth = useAuth();

  const getEpost = (auth: AuthContextProps): string | undefined => {
    const epost = auth?.user?.profile?.email;
    return epost ? String(epost) : undefined;
  };

  const getDecodedAccessToken = function (auth: AuthContextProps): AccessToken | undefined {
    try {
      if (!auth?.user?.access_token) {
        return undefined;
      }

      return jwt_decode<AccessToken>(auth?.user?.access_token);
    } catch (InvalidTokenError) {
      return undefined;
    }
  };

  const getIdToken = (auth: AuthContextProps): IdToken | undefined => {
    if (!auth?.user?.id_token) {
      return undefined;
    }

    return jwt_decode<IdToken>(auth.user.id_token);
  };

  const login = useCallback((): void => {
    auth.signinRedirect();
  }, [auth]);

  const logout = async (): Promise<void> => {
    const authorityRootUrl = auth.settings.authority;

    // send en logout request til OneLogin for å terminere sesjonen før ny login initieres
    const logoutPopup = window.open(authorityRootUrl + "/logout", 'windowname', 'location=false,toolbar=false,menubar=false,scrollbars=false,resizable=false,status=false,width=1,height=1');
    await new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
      logoutPopup?.close();
      auth.signoutRedirect();
    });
  };

  useEffect((): void => {
    if (auth.error) {
      setError(auth.error);
      return;
    }

    if (auth.isLoading) {
      return;
    }

    if (!auth.isAuthenticated) {
      login();
      return;
    }

    const harNoenAvRollene = (roller: string[]): boolean => {
      const accessToken = getDecodedAccessToken(auth);
      if (accessToken === undefined) {
        return false;
      }

      return accessToken.roles.some((rolle) => roller.includes(rolle));
    };

    const accessToken = getDecodedAccessToken(auth);
    const idToken = getIdToken(auth);

    setBruker({
      oneLoginId: accessToken?.sub ?? '',
      brukerId: accessToken?.['bym-id'] ?? '',
      navn: idToken?.name ?? '',
      epost: getEpost(auth) ?? '',
      roller: accessToken?.roles ?? [],
      erEntreprenor: harNoenAvRollene([roller.entrepreneur, roller.entrepreneurAdmin]),
      erServiceavdeling: harNoenAvRollene([roller.serviceavdeling, roller.serviceavdelingAdmin]),
      erAutentisert: auth.isAuthenticated
    });

    setInitialized(true);

  }, [auth, login]);

  return (
    <BrukerContext.Provider value={{ bruker, login, logout, initialized, error }}>
      {children}
    </BrukerContext.Provider>
  );


};

export default BrukerProvider;

export const useBrukerContext = (): BrukerContext => useContext(BrukerContext);
