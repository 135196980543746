import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { globalRoutes } from './Routes';
import { useBrukerContext } from '../context/BrukerProvider';
import { ErrorResponse } from 'oidc-client-ts';

interface RoleCheckRouteProps extends RouteProps {
  publicRoute?: boolean;
}

const RoleCheckRoute = (props: RoleCheckRouteProps): JSX.Element => {
  const { children, publicRoute = false, ...rest } = props;
  const { bruker, initialized, error, login: loginBruker } = useBrukerContext();


  if (error) {
    if ((error as ErrorResponse)?.error === 'invalid_grant') {
      loginBruker();
      return <></>;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const accessDenied = queryParams.get("error") === "access_denied";
    if (accessDenied) {
      return <Redirect to={globalRoutes.unauthorized.link}></Redirect>;
    }

    return (
      <div style={{ color: "red" }}>
        Autentiseringsfeil: Prøv å logge på i en ny fane.
      </div>
    );
  }

  if (!initialized) {
    return <div>
      <hgroup>
        <h1>Henter brukerinformasjon...</h1>
      </hgroup>
    </div>
  }

  if (bruker.erAutentisert || publicRoute) return <Route {...rest}>{children}</Route>;
  return <Redirect to={globalRoutes.unauthorized.link}></Redirect>;
};

export default RoleCheckRoute;
