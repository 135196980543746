
import React, { useMemo, useState } from 'react';
import { MeldingDetaljer, MeldingsStatus } from '../../../interfaces';
import { findMeldingstatusPath } from '../../../utils/statusUtils';
import Button from '../../common/buttons/Button';
import MessageBox from '../../common/messageBox/MessageBox';
import { useToast } from '../../toast/ToastProvider';
import { useMeldingsstatuser } from '../../../queryHooks/useMeldingsstatuser';

interface BekreftStatusoppdateringProps {
  melding: MeldingDetaljer;
  pendingStatusUpdate?: MeldingsStatus;
  onCancel: () => void;
  onConfirm: (statusId: number) => void;
}

const BekreftStatusoppdatering = (props: BekreftStatusoppdateringProps): JSX.Element => {
  const { pendingStatusUpdate, onCancel, onConfirm } = props;
  const { addToast } = useToast();
  const { data: meldingsstatuser } = useMeldingsstatuser();

  const [statusPath, setStatusPath] = useState<MeldingsStatus[]>();

  useMemo(() => {
    if (!meldingsstatuser) {
      return;
    }

    setStatusPath(findMeldingstatusPath(meldingsstatuser, pendingStatusUpdate?.id ?? 0));
  }, [meldingsstatuser, pendingStatusUpdate]);

  return (
    <div className="space-y-2">
      <h4>Bekreft oppdatering av stauts.</h4>
      {statusPath && (
        <p>Oppdater status til: {statusPath.reduce((prev, current, index) => {
          const res = prev + `${current.navn !== 'Fritekst' ? `${index === 0 ? '' : ' - '}${current.navn}` : ''}`;
          return res;
        }, '')}
        </p>)}
      <MessageBox state='warning'>Denne meldingen ble opprettet av en annonym bruker. Det er derfor ikke mulig å sende tilbakemelding.</MessageBox>
      <div className="flex justify-end space-x-2">
        <Button onClick={onCancel} outline color="red">
          Avbryt
        </Button>
        <Button
          onClick={async () => {
            try {
              onConfirm(pendingStatusUpdate?.id ?? 0);
              addToast('Status er oppdatert', 'success');
              onCancel();
            } catch (e) {
              addToast('Kunne ikke oppdatere status', 'error');
            }
          }}
          outline
        >
          Bekreft statusoppdatering
        </Button>

      </div>
    </div>
  );
};

export default BekreftStatusoppdatering;