import { Adresse } from '../../utils/adresseUtils';

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface adresseInputState {
  searchString: string;
  serchResult: Adresse[];
  showResult: boolean;
  currentSelection?: Adresse;
  activeItem: number;
}

export interface updateAdresseInputState {
  searchString?: string;
  serchResult?: Adresse[];
  currentSelection?: Adresse;
  activeItem?: number;
  showResult?: boolean;
}

export const initState = {
  searchString: '',
  serchResult: <Adresse[]>[],
  activeItem: 0,
  showResult: false,
};

export const adresseInputReducer = (
  state: adresseInputState,
  action: { type: stateActionType; payload: adresseInputState }
): adresseInputState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...initState };
    default:
      return state;
  }
};
