import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { bildeCashItem, initState, meldingPageState, meldingPageReducer } from './meldingReducer';
import { MeldingDetaljer, MeldingKommentar } from '../../interfaces/Melding';
import {
  putMeldingEntreprenor,
  putMelding,
  entreprenorMeldingById,
  serviceAvdelingMeldingById,
} from '../api/urls';
import { useApi } from '../context/ApiProvider';
import MeldingTabPanel from './MeldingTabPanel';
import MeldingInfoPanel from './MeldingInfoPanel';
import BilderPanel from './Bilde/BilderPanel';
import KommentarPanel from './KommentarPanel';
import RessursbrukPanel from './RessursbrukPanel';
import HistorikkPanel from './HistorikkPanel';
import PageSpinner from '../common/spinner/PageSpinner';
import { useToast } from '../toast/ToastProvider';
import { useBrukerContext } from '../context/BrukerProvider';
import StatusChangeButton from './StatusVelger/StatusChangeButton';
import DokumentasjonsPanel, { Dokumentasjon } from './Dokumentasjon/DokumentasjonPanel';
import { useBrukerselskap } from '../../queryHooks/useBrukerselskap';
import { useMeldingstyper } from '../../queryHooks/useMeldingstyper';
import { getMeldingtypeFromId } from '../../utils/meldingstypeUtils';

export interface updatedMeldingProps {
  meldingstypeId?: string;
  meldingsStatusId?: number;
  adresse?: string;
  latitude?: number;
  longitude?: number;
  avtaleId?: string;
}

const MeldingPage = (): JSX.Element => {
  const { meldingId } = useParams<{ meldingId: string }>();
  const { api } = useApi();
  const { bruker: user } = useBrukerContext();
  const { addToast } = useToast();
  const history = useHistory();
  const [state, dispatch] = useReducer(meldingPageReducer, initState);
  const [selectedTab, setSelectedTab] = useState(0);

  const { loading, melding, bildeCash, dokumentasjonCash, kommentarer } = state;
  const { bildeIds, dokumentasjonIds } = melding;
  const { data: brukerselskap, isSuccess: brukerselskapIsSuccess } = useBrukerselskap(user);
  const { data: meldingstyper, isSuccess: meldingstyperIsSuccess } = useMeldingstyper();

  const updateState = (state: meldingPageState): void => {
    dispatch({ type: 'UPDATE_STATE', payload: state });
  };

  const cashImageToState = (bilde: bildeCashItem): void => {
    if (state.bildeCash) updateState({ ...state, bildeCash: [...state.bildeCash, bilde] });
    else updateState({ ...state, bildeCash: [bilde] });
  };

  const cashDokumentasjonToState = (docs: Dokumentasjon[]): void => {
    updateState({ ...state, dokumentasjonCash: docs });
  };

  const cashKommentarToState = (k: MeldingKommentar[]): void => {
    updateState({ ...state, kommentarer: [...k, ...(state.kommentarer || [])] });
  };

  const updateMelding = async (
    meldingId: string,
    avtaleId: string,
    updatedMelding: updatedMeldingProps
  ): Promise<void> => {
    try {
      updateState({
        ...state,
        loading: true,
      });
      if (user.erEntreprenor && avtaleId && brukerselskap) {
        const updates = {
          meldingsStatusId: updatedMelding.meldingsStatusId ?? melding.meldingsStatusId,
          adresse: updatedMelding.adresse ?? melding.adresse,
          latitude: updatedMelding.latitude ?? melding.latitude,
          longitude: updatedMelding.longitude ?? melding.longitude,
        };
        await api.put(putMeldingEntreprenor(brukerselskap.selskapId, meldingId), updates);
        updateState({
          ...state,
          loading: false,
          melding: { ...melding, ...updatedMelding },
        });
      } else if (user.erServiceavdeling) {
        const updates = {
          meldingsStatusId: updatedMelding.meldingsStatusId ?? melding.meldingsStatusId,
          adresse: updatedMelding.adresse ?? melding.adresse,
          latitude: updatedMelding.latitude ?? melding.latitude,
          longitude: updatedMelding.longitude ?? melding.longitude,
          meldingstypeId: updatedMelding.meldingstypeId ?? melding.meldingstypeId,
          avtaleId: updatedMelding.avtaleId ?? melding.avtaleId,
        };
        await api.put(putMelding(meldingId), updates);
        updateState({
          ...state,
          loading: false,
          melding: { ...melding, ...updatedMelding },
        });
      }
    } catch (e) {
      addToast('Kunne ikke oppdatere meldingen');
    }
  };

  useMemo(() => {
    if (brukerselskapIsSuccess && meldingstyperIsSuccess) {
      const completeMeldingObject = (melding: MeldingDetaljer): MeldingDetaljer => {
        const completeMelding = { ...melding, meldingsStatusId: melding.meldingsStatus?.id ?? 0 };
        return completeMelding;
      };

      const getMeldingDetaljer = async (): Promise<void> => {
        updateState({ ...state, loading: true });
        try {
          if (user.erEntreprenor && meldingId && brukerselskap?.selskapId) {
            const res = await api.get(entreprenorMeldingById(brukerselskap.selskapId, meldingId));
            updateState({
              ...state,
              melding: completeMeldingObject(res.data.result),
              loading: false,
            });
          } else if (user.erServiceavdeling && meldingId) {
            const res = await api.get(serviceAvdelingMeldingById(meldingId));
            updateState({
              ...state,
              melding: completeMeldingObject(res.data.result),
              loading: false,
            });
          } else {
            throw new Error();
          }
        } catch (e) {
          history.push('/arbeidsliste');
          addToast(
            `Kunne ikke hente meldingsdetaljer for denne meldingen: ${e.response?.data?.errorMessage ?? 'Ukjent feil'}`,
            'error'
          );
        }
      };

      getMeldingDetaljer();
    }
  }, [meldingId, brukerselskapIsSuccess, meldingstyperIsSuccess]);

  useMemo(() => {
    if (brukerselskapIsSuccess && meldingstyperIsSuccess && meldingstyper) {
      const meldingstype = getMeldingtypeFromId(meldingstyper, melding.meldingstypeId);
      if (meldingstype) {
        updateState({
          ...state,
          melding: { ...melding, meldingstype },
        });
      }
    }
  }, [melding.meldingstypeId, brukerselskapIsSuccess, meldingstyperIsSuccess]);

  const tabs = [
    { key: 'info', title: 'Info', component: <MeldingInfoPanel melding={melding} updateMelding={updateMelding} /> },
    {
      key: 'bilder',
      title: `Bilder(${bildeIds?.length || 0})`,
      component: <BilderPanel bildeIds={bildeIds} cashImageToState={cashImageToState} bildeCash={bildeCash} />,
    },
    {
      key: 'dokumentasjon',
      title: `Dokumentasjon(${dokumentasjonIds?.length || 0})`,
      component: (
        <DokumentasjonsPanel
          dokumentasjonIds={dokumentasjonIds}
          meldingId={melding.meldingId}
          cashDokumentasjonToState={cashDokumentasjonToState}
          dokumentasjonCash={dokumentasjonCash}
        />
      ),
    },
    {
      key: 'kommentarer',
      title: 'Kommentarer',
      component: (
        <KommentarPanel
          meldingId={melding.meldingId}
          cashKommentarToState={cashKommentarToState}
          kommentarer={kommentarer || []}
        />
      ),
    },
    { key: 'historikk', title: 'Historikk', component: <HistorikkPanel meldingId={melding.meldingId} /> },
    {
      key: 'ressursbruk',
      title: 'Ressursbruk',
      component: <RessursbrukPanel meldingId={melding.meldingId} avtaleId={melding.avtale?.avtaleId} />,
    },
  ];

  return (
    <div className="min-h-body-large-header-modal h-auto">
      {loading && <PageSpinner />}
      {melding?.meldingId && (
        <>
          <div className="flex justify-between items-center p-1">
            <h1>
              {melding.lopeNummer} - {melding.meldingstype?.beskrivelse}
            </h1>
            <StatusChangeButton
              setSelectedTab={setSelectedTab}
              meldingId={melding.meldingId}
              avtaleId={melding.avtale?.avtaleId}
              updateMelding={updateMelding}
              melding={melding}
            />
          </div>
          <MeldingTabPanel tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </>
      )}
    </div>
  );
};

export default MeldingPage;
