import React, { ReactNode, useEffect, useRef } from 'react';
import { DomEvent } from 'leaflet';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

type PositionOption = 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

const CustomLeafletControl = (props: { position: PositionOption; children: ReactNode | ReactNode[] }): JSX.Element => {
  const { position, children } = props;
  const controlRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  useEffect(() => {
    controlRef.current && DomEvent.disableClickPropagation(controlRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlRef.current]);

  return (
    <div className={positionClass}>
      <div className="leaflet-control-container">
        <div className="leaflet-control" ref={controlRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomLeafletControl;
