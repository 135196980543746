import React, { forwardRef } from 'react';
import './DropdownSelect.scss';

export interface DropdownSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  state?: 'primary' | 'success' | 'warning' | 'danger';
  outline?: boolean;
  label: string;
  options: {
    value: string | number;
    name: string;
    disabled?: boolean;
    disabledTooltip?: string;
  }[];
}

const DropdownSelect = forwardRef<HTMLSelectElement, DropdownSelectProps>((props: DropdownSelectProps, ref) => {
  const { label, state = 'primary', outline = true, required = false, options, ...rest } = props;
  return (
    <label className="">
      {`${label}${required ? ' *' : ''}`}
      <div className="drpdown-select">
        <select
          className={`input-field ${outline ? 'outline' : ''}
            input-field-${state}`}
          ref={ref}
          {...rest}
        >
          {options.map((element) => (
            <option key={element.value} value={element.value} disabled={element.disabled} title={element.disabledTooltip ?? ''}>
              {element.name}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
});

DropdownSelect.displayName = 'DropdownSelect';
export default DropdownSelect;
