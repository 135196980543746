/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const localstoreName = 'entreprenor-portal-store';

export const getItem = (key: string): any => {
  if (typeof window !== 'undefined') {
    try {
      return JSON.parse(localStorage.getItem(`${localstoreName}-${key}`) || '');
    } catch (e) {
      return localStorage.getItem(`${localstoreName}-${key}`);
    }
  }
  return null;
};

export const setItem = (key: string, value: any): void => {
  if (typeof window !== 'undefined') {
    try {
      localStorage.setItem(`${localstoreName}-${key}`, typeof value === 'object' ? JSON.stringify(value) : value);
    } catch (e) {
      // todo
    }
  }
  return undefined;
};
