import React, { useState } from 'react';
import Ikon from './Ikon';

const ExpandablePanel = (props: {
  title: string;
  indicatorLabel?: number;
  heightConstraint?: boolean;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const { title, children, indicatorLabel = 0, heightConstraint = true } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="border-b border-blue-dark">
      <button className="flex justify-between items-center w-full" onClick={() => setExpanded(!expanded)}>
        <div className="flex justify-start items-center">
          <h4>{title}</h4>
          {indicatorLabel > 0 && (
            <span className="rounded-full bg-yellow flex justify-center items-center h-4 w-4 text-5 ml-2">
              {indicatorLabel.toString()}
            </span>
          )}
        </div>
        <div className={`transition-transform duration-300 ease-in-out ${expanded ? 'transform rotate-180' : ''}`}>
          <Ikon ikon="chevron-thin-down" />
        </div>
      </button>
      <div className={`transition-transform duration-300 ease-in-out`}>
        {expanded && (
          <div className={`${heightConstraint && "relative max-h-64 overflow-hidden"}`}>
            <div className={`${heightConstraint && "relative max-h-64 overflow-y-scroll"}`}>
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandablePanel;
