import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Avtale, BrukerSelskap, Kontrakt, Pris } from '../interfaces';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';
import { Bruker } from '../features/context/BrukerProvider';

export const useAvtalerByBruker = (bruker: Bruker): UseQueryResult<Avtale[]> => {
  const { api } = useApi();

  const completeAvtaler = async (avtale: Avtale): Promise<Avtale> => {
    const getKontrakt = async (avtaleId: string): Promise<Kontrakt | undefined> => {
      try {
        const res = await api.get(urls.kontratByAvtale(avtaleId));
        return res.data.result;
      } catch (e) {
        return undefined;
      }
    };

    const getPrisliste = async (kontraktId?: string): Promise<Pris[]> => {
      if (!kontraktId) return [];
      try {
        const res = await api.get(urls.prisliste(kontraktId));
        return res.data.result;
      } catch (e) {
        return [];
      }
    };

    const kontrakt = await getKontrakt(avtale.avtaleId);
    const prisListe = kontrakt ? await getPrisliste(kontrakt.id) : undefined;
    return { ...avtale, prisListe, kontrakt };
  };

  return useQuery({
    enabled: bruker.erAutentisert && bruker.erEntreprenor,
    queryKey: ['avtaler', [{ brukerId: bruker.brukerId }]],
    queryFn: () => {
      return api
        .get(urls.getselskapsIdForUserUrl(bruker.brukerId))
        .then((result: { data: { result: BrukerSelskap } }) => {
          return result.data.result;
        }).then(async (brukerselskap: BrukerSelskap) => {
          return api
            .get(urls.getAvtalerForEntrepreneurUrl(brukerselskap.selskapId, bruker.brukerId))
            .then(async (result: { data: { result: Avtale[] } }) => {
              return await Promise.all(result.data.result.map((avtale: Avtale) => completeAvtaler(avtale)));
            });
        });
      
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
