import React from 'react';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';

export interface SelectedFilterTokenProps {
  onRemove: (id?: string | number) => void;
  label: string;
}

const SelectedFilterToken = (props: SelectedFilterTokenProps): JSX.Element => {
  const { onRemove, label } = props;
  return (
    <div className="flex flex-row items-center justify-center bg-gray-dark pl-1 m-1">
      {label}
      <Button className="p-0 bg-gray-dark hover:bg-blue-hover hover:text-white " onClick={() => onRemove()}><Ikon ikon="x" /></Button>
    </div>);
};

export default SelectedFilterToken;