import React, { useEffect, useReducer, useState } from 'react';
import { useApi } from '../context/ApiProvider';
import * as urls from '../api/urls';
import { arbeidslisteReducer, arbeidslisteInitState } from '../arbeidsliste/arbeidslisteReducer';
import MeldingerListevisning from '../meldingerListevisning/MeldingerListevisning';
import ArbeidslisteNav from './ArbeidslisteNav';
import MeldingPage from '../melding/MeldingPage';
import { useFilter } from '../filter/FilterProvider';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import { useBrukerContext } from '../context/BrukerProvider';
import SildeInSpinner from '../common/spinner/SildeInSpinner';
import { useToast } from '../toast/ToastProvider';
import { buildQueryfromState } from '../../utils/filterUtils';
import MeldingerKartvisning from '../meldingerKartvisning/MeldingerKartvisning';
import Ressursforbruk from '../ressursforbruk/RessursforbrukPage';
import NyMeldingPage from '../NyMeldingPage/NyMeldingPage';
import SlideInFilter from '../filter/SlideInFIlter';
import Modal from '../common/modal/Modal';
import './arbeidsliste.scss';
import { useBrukerselskap } from '../../queryHooks/useBrukerselskap';

const ArbeidslistePage = (): JSX.Element => {
  const [state, dispatch] = useReducer(arbeidslisteReducer, arbeidslisteInitState);
  const { meldinger, totaltAntall } = state;
  const { currentFilter, updateCurrentFilter } = useFilter();
  const { api, version: appVersion } = useApi();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { logout, bruker: user } = useBrukerContext();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [nyMeldingModalOpen, setNyMeldingModalOpen] = useState(false);
  const { data: brukerselskap, isSuccess: brukerselskapIsSuccess } = useBrukerselskap(user);

  const getMeldinger = async (): Promise<void> => {
    setLoading(true);

    const { page } = currentFilter;
    const filterString = `${buildQueryfromState(currentFilter)}`;
    try {
      const res = user.erEntreprenor
        ? await api.get(urls.getFilteredMeldingerForEntrepreneurs(brukerselskap?.selskapId || '', filterString))
        : await api.get(urls.getFilteredMeldingerForServiceavdeling(filterString));
      const { meldinger, totaltAntall } = res.data.result;
      const numberOfPages = Math.ceil(totaltAntall / 100);
      if (numberOfPages > 0 && page > numberOfPages) updateCurrentFilter({ ...currentFilter, page: numberOfPages });
      else {
        dispatch({
          type: 'UPDATE_STATE',
          payload: { ...state, meldinger: meldinger, totaltAntall: totaltAntall },
        });
      }
    } catch (e) {
      addToast(`Kunne ikke hente meldinger: ${e.response?.data?.errorMessage || 'ukjent feil'}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      brukerselskapIsSuccess &&
      ((user.erEntreprenor && brukerselskap?.selskapId) || user.erServiceavdeling) &&
      (pathname === '/arbeidsliste/liste' || pathname === '/arbeidsliste/kart') &&
      (!currentFilter.fritekstSok || currentFilter.fritekstSok.length > 3)
    ) {

      getMeldinger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, brukerselskap, pathname, currentFilter, brukerselskapIsSuccess]);

  return (
    <>
      <div className={`flex justify-around items-center`}>
        <SildeInSpinner loading={loading} />
      </div>
      <div className="arbeidsliste_container">
        <div
          className={`content min-h-body-small-header ${pathname === '/arbeidsliste/ressursforbruk' ? 'overflow-scroll' : ''
            }`}
        >
          <SlideInFilter totaltAntall={totaltAntall} />
          <Route path={`${path}/liste`}>
            <div className="meldingliste_container">

              <div className="meldingliste_listevisning">
                <MeldingerListevisning meldinger={meldinger} totaltAntall={totaltAntall} />
              </div>
              <div className="meldingliste_kartvisning">
                <MeldingerKartvisning meldinger={meldinger} />
              </div>
            </div>

            <Route path={`${path}/liste/melding/:meldingId`}>
              <Modal isOpen={true} toggle={() => {
                history.push(`${path}/liste`);
              }}>
                <MeldingPage />
              </Modal>
            </Route>
          </Route>

          <Route path={`${path}/kart`}>
            <MeldingerKartvisning meldinger={meldinger} withFilter />

            <Route path={`${path}/kart/melding/:meldingId`}>
              <Modal isOpen={true} toggle={() => {
                history.push(`${path}/kart`);
              }}>
                <MeldingPage />
              </Modal>
            </Route>
          </Route>

          <Route exact path={`${path}/min_side`}>
            <div className="space-y-2 min-h-body-small-header p-2">
              <h1>Min side</h1>
              <h2>{user.epost}</h2>
              <Button onClick={() => {
                setLoading(true);
                logout();
              }}>
                <>
                  <Ikon ikon="user" />
                  Logg ut
                </>
              </Button>
              <p>Versjon: {appVersion}</p>
            </div>
          </Route>
          <Route path={`${path}/ressursforbruk`}>
            <>
              <Ressursforbruk />

              <Route path={`${path}/ressursforbruk/melding/:meldingId`}>
                <Modal isOpen={true} toggle={() => {
                  history.push(`${path}/ressursforbruk`);
                }}>
                  <MeldingPage />
                </Modal>
              </Route>
            </>
          </Route>

          <Route exact path={`${path}`}>
            <Redirect to={`${path}/liste`} />
          </Route>
        </div>
        <div className="navbar">
          <ArbeidslisteNav toggleNyMeldingModal={() => setNyMeldingModalOpen(!nyMeldingModalOpen)} />
        </div>
      </div>
      <Modal isOpen={nyMeldingModalOpen} toggle={() => setNyMeldingModalOpen(!nyMeldingModalOpen)}>
        <NyMeldingPage onClose={() => {
          setNyMeldingModalOpen(false);
          getMeldinger();
        }} />
      </Modal>
    </>
  );
};

export default ArbeidslistePage;
