import React, { useRef, useState } from 'react';
import Button from '../common/buttons/Button';
import ExpandablePanel from '../common/ExpandablePanel';
import Ikon from '../common/Ikon';
import { useFilter } from '../filter/FilterProvider';
import SokInstructions from './SokInstructions';

const MeldingSok = (): JSX.Element => {
  const { currentFilter, updateMeldingSok } = useFilter();
  const [showHelp, setShowHelp] = useState(false);

  const inputRef = useRef({} as HTMLInputElement);

  return (
    <ExpandablePanel title="Fritekst" indicatorLabel={currentFilter.fritekstSok ? 1 : 0}>
      <>
        <div className={`flex w-full justify-start py-2`}>
          <div className="p-2">
            <Ikon ikon={'magnifying-glass-small'} />
          </div>
          <input
            ref={inputRef}
            onChange={(e) => updateMeldingSok(e.target.value)}
            value={currentFilter.fritekstSok || ''}
            type="text"
            placeholder="Søk i meldinger"
            className="border border-blue-dark rounded-none w-full p-1"
          />
          <Button
            className="bg-yellow text-blue-dark p-2 hover:bg-blue-hover hover:text-white ml-1"
            onClick={() => setShowHelp(!showHelp)}
          >
            <Ikon ikon="info-mark-circle" />
          </Button>
        </div>
        {showHelp && <SokInstructions close={() => setShowHelp(false)} />}
      </>
    </ExpandablePanel>
  );
};

export default MeldingSok;
