export const WMSlayerServiceUrl = 'https://geodata.bymoslo.no/arcgis/services/geodata/bymelding/MapServer/WMSServer?';

export const layerInfoUrl =
  'https://geodata.bymoslo.no/arcgis/rest/services/geodata/bymelding/MapServer/legend?f=pjson';

export const grunnkartOptions = [
  {
    key: 'grunnkart',
    displayName: 'Kart',
    url: `https://{s}/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}`,
    subdomains: ['cache.kartverket.no', 'cache2.kartverket.no', 'cache3.kartverket.no'],
    attribution: '&copy; <a href="href="http://www.kartverket.no">Kartverket</a>',
  },
  {
    key: 'detaljert_grunnkart',
    displayName: 'Detaljert',
    objectStoreName: 'tile.openstreetmap',
    url: `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    tileUrlSubDomains: ['a', 'b', 'c'],
  },
  {
    key: 'satelite_grunnkart',
    displayName: 'Satelitt',
    objectStoreName: 'open_nib_web_mercator_wmts_v2',
    url:
      'https://opencache.statkart.no/gatekeeper/gk/gk.open_nib_web_mercator_wmts_v2?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=topo4&STYLE=default&FORMAT=image/jpgpng&TILEMATRIXSET=GoogleMapsCompatible&tilematrix={getMatrix}&TileRow={y}&TileCol={x}',
    getMatrix: (data: { z: number }): string => `${`0${data.z}`.slice(-2)}`,
    attribution: '&copy; <a href="https://www.norgeibilder.no/">Kartverket, NIBIO og Statens vegvesen</a>',
  },
];

export const kartlag = [
  {
    displayName: 'Turveier',
    layerId: 'Turveier',
    ledgendId: 30,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Indre by - ring 1',
    layerId: 'IndreByRing1',
    ledgendId: 1,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Driftsområder',
    layerId: 'Driftsområder_vei61671',
    ledgendId: 7,
    opacity: 0.3,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Bydelsgrenser',
    layerId: 'Bydelsgrenser',
    ledgendId: 2,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Fremmede arter',
    layerId: 'Fremmede_arter624',
    ledgendId: 5,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Parkregioner',
    ledgendId: 6,
    url: WMSlayerServiceUrl,
    layerId: 'Parkregioner',
  },
  {
    displayName: 'Gatelys',
    layerId: 'Armatur',
    ledgendId: 4,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Sykkelservicestasjon',
    layerId: 'Sykkelservicestasjon',
    ledgendId: 35,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Eiendomsoversikt',
    layerId: 'Eiendomsoversikt2020',
    ledgendId: 3,
    url: WMSlayerServiceUrl,
    opacity: 0.5,
  },
  {
    displayName: 'Idrettsanlegg',
    layerId: 'Idrettsomriss',
    ledgendId: 12,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Kummer og sluk',
    layerId: 'Slukredigering_BYM37120',
    ledgendId: 16,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Parker',
    layerId: 'Parkomriss',
    ledgendId: 13,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Veier',
    layerId: 'Veier',
    ledgendId: 11,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Veier - vegflater',
    layerId: 'vegflater',
    ledgendId: 34,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Trær',
    layerId: 'Tredata',
    ledgendId: 9,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Treforvalterregioner',
    layerId: 'Treforvalterregioner',
    ledgendId: 17,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Treanlegg_BYM',
    layerId: 'Treanlegg_BYM',
    ledgendId: 18,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Velvei',
    layerId: 'Velvei',
    ledgendId: 19,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Lekeplass',
    layerId: 'Lekeplass',
    ledgendId: 20,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Skiløyper BYM',
    layerId: 'Skiløyper_preparert_av_Bymiljøetaten17728',
    ledgendId: 21,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Skogeiendommer OK',
    layerId: 'Skogeiendommer_Oslo_kommune_eier50344',
    ledgendId: 22,
    url: WMSlayerServiceUrl,
  },
  {
    displayName: 'Idrettsregioner',
    layerId: 'Idrettsregioner',
    ledgendId: 8,
    url: WMSlayerServiceUrl,
  },
  {
    layerId: 'Vinterdrift_sykkel_-_Park36652',
    displayName: 'Vinterdrift sykkel - Park',
    ledgendId: 50,
    url: WMSlayerServiceUrl,
  },
  {
    layerId: 'Vinterdrift_sykkel_-_Prioritert_veinett37509',
    displayName: 'Vinterdrift sykkel - Prioritert veinett',
    ledgendId: 51,
    url: WMSlayerServiceUrl,
  },
  {
    layerId: 'Vinterdrift_sykkel_-_Statens_vegvesen58065',
    displayName: 'Vinterdrift sykkel - Statens vegvesen',
    ledgendId: 52,
    url: WMSlayerServiceUrl,
  },
  {
    layerId: 'Prioritert_veinett_-_ekstra_løvfeiing33747',
    displayName: 'Prioritert sykkelveinett - ekstra løvfeiing',
    ledgendId: 53,
    url: WMSlayerServiceUrl,
  },
  {
    layerId: 'Prioritert_veinett_-_sommerdrift32807',
    displayName: 'Prioritert sykkelveinett - sommerdrift',
    ledgendId: 54,
    url: WMSlayerServiceUrl,
  },
];
