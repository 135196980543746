import React, { useEffect, useState } from 'react';
import { useApi } from '../context/ApiProvider';
import { historikkForMeldingUrl } from '../api/urls';
import { MeldingHistorikk } from '../../interfaces';
import { useBrukerContext } from '../context/BrukerProvider';
import { format } from 'date-fns';
import FadeInSpinner from '../common/spinner/FadeInSpinner';

const HistorikkPanel = ({ meldingId }: { meldingId: string }): JSX.Element => {
  const { api } = useApi();
  const { bruker: user } = useBrukerContext();
  const [historikk, setHistorikk] = useState<MeldingHistorikk[]>([]);
  const [loading, setLoading] = useState(false);

  const loadHistorikk = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await api.get(historikkForMeldingUrl(meldingId));
      setHistorikk(res.data.result.tidslinje);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    historikk.length === 0 && loadHistorikk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mb-nav-small">
      <FadeInSpinner loading={loading} />
      {historikk.map((h) => {
        const outgoing = user.epost === h.historikkBrukerEpost;
        return (
          <div key={h.historikkId} className={`p-3 flex flex-col w-full ${outgoing ? 'items-end' : 'items-start'}`}>
            <div className="flex no-wrap max-w-full">
              <span className="truncate">{h.historikkBrukerEpost}</span>
              <span className="whitespace-no-wrap">({format(new Date(h.historikkTidspunkt), 'dd.MM.yy HH:mm')})</span>
            </div>
            <div
              className={`flex w-full mobile-only:overflow-x-scroll p-3 rounded-xl ${outgoing
                ? 'text-white bg-blue-dark rounded-tr-none'
                : 'bg-gray-light border border-blue-dark rounded-tl-none'
                }`}
            >
              <div className={`divide-y-2 w-full ${outgoing ? 'border-blue-dark divide-white' : 'border-white divide-blue-dark'}`}>
                {Object.entries(h.felter).map((entry) => {
                  if (entry.length === 2 && entry[0] !== 'Rutingsammendrag') {
                    return (
                      <div key={`${h.historikkId}--${entry[0]}`} className={`flex flex-row py-1`}>
                        <div className="flex flex-col w-1/3">
                          <span className="font-bold">Felt</span>
                          <span>{entry[0]}</span>
                        </div>

                        <div className="flex flex-col w-2/3">
                          <span className="font-bold">Ny verdi</span>
                          <span>{entry[1].til || '-'}</span>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HistorikkPanel;
