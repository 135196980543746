import React from "react";

const NotFound = (): JSX.Element => {
  return (
    <div>
      <hgroup>
        <h1>Beklager, siden finnes ikke!</h1>
      </hgroup>
      <section>
        <p>
          <span>
            Siden du prøver å nå finnes ikke.
          </span>
        </p>
      </section>
    </div>
  );
}

export default NotFound;