import React from 'react';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';

interface SortByBtnProps {
  label: string;
  id: string;
  sortDir: string;
  isActive: boolean;
  onClick: (sortByLabel: string) => void;
}

const SortByBtn = (props: SortByBtnProps): JSX.Element => {
  const { label, sortDir, onClick, isActive, id } = props;
  return (
    <Button
      className={`flex items-center justify-center space-x-2 h-full ${isActive ? 'font-semibold text-blue-hover' : 'font-light'}`}
      onClick={() => onClick(id)}
    >
      <>
        {label}
        {isActive && <Ikon ikon={sortDir === 'desc' ? 'chevron-thin-down' : 'chevron-thin-up'} />}
      </>
    </Button>
  );
};

export default SortByBtn;
