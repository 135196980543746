import React, { forwardRef } from 'react';
import './input.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  state?: 'primary' | 'success' | 'warning' | 'danger';
  outline?: boolean;
  label: string;
  postfix?: string;
  className?: string;
  containerClass?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref): JSX.Element => {
    const { label, state = 'primary', outline = false, required = false, postfix, className = '', ...rest } = props;
    return (
      <label className={`input-field whitespace-no-wrap flex-col items-stretch`}>
        {`${label}${required ? ' *' : ''}`}
        <div className="flex items-start w-full">
          <input
            className={`${
              outline ? 'input-field-outline' : 'input-field'
            } h-50 tablet:h-60 input-field-${state} ${className}`}
            ref={ref}
            {...rest}
          />
          <span
            className={` ${postfix ? 'postfix' : 'postfix-empty'}
            flex items-center justify-end
            bg-white ${
              outline ? 'input-field-outline' : 'input-field'
            }  h-50 tablet:h-60 postfix-${state} text-font-size-14 whitespace-normal
           `}
          >
            {postfix}
          </span>
        </div>
      </label>
    );
  }
);

Input.displayName = 'BYM_Input';

export default Input;
