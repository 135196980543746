import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ContextProviders from './features/context/ContextProviders';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.scss';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from './auth/oidcConfig';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      retry: 3
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ContextProviders>
            <App />
          </ContextProviders>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
