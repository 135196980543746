export const bydelListe = [
  { id: 'SØNDRE NORDSTRAND', navn: 'Søndre Nordstrand' },
  { id: 'BJERKE', navn: 'Bjerke' },
  { id: 'GRORUD', navn: 'Grorud' },
  { id: 'SENTRUM', navn: 'Sentrum' },
  { id: 'GRUNERLØKKA', navn: 'Grünerløkka' },
  { id: 'NORDSTRAND', navn: 'Nordstrand' },
  { id: 'NORDMARKA', navn: 'Nordmarka' },
  { id: 'VESTRE AKER', navn: 'Vestre Aker' },
  { id: 'ALNA', navn: 'Alna' },
  { id: 'NORDRE AKER', navn: 'Nordre Aker' },
  { id: 'ØSTMARKA', navn: 'Østmarka' },
  { id: 'ULLERN', navn: 'Ullern' },
  { id: 'GAMLE OSLO', navn: 'Gamle Oslo' },
  { id: 'ST.HANSHAUGEN', navn: 'St. Hanshaugen' },
  { id: 'SAGENE', navn: 'Sagene' },
  { id: 'STOVNER', navn: 'Stovner' },
  { id: 'ØSTENSJØ', navn: 'Østensjø' },
  { id: 'FROGNER', navn: 'Frogner' },
];

export const roller = {
  serviceavdeling: 'bymelding-serviceavdeling',
  serviceavdelingAdmin: 'bymelding-serviceavdeling-admin',
  entrepreneur: 'bymelding-entreprenor',
  entrepreneurAdmin: 'bymelding-entreprenor-admin',
};