import React from 'react';
import { Melding } from '../../interfaces';
import { MapContainer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import CustomLeafletControl from '../common/CustomLeafletControl';
import FilterLink from '../filter/FilterLink';
import MeldingerMarkers from './MeldingerMarkers';
import Kartlagvelger from '../kartlagvelger/Kartlagvelger';

type ArbeidslisteKartvisningProps = {
  meldinger: Melding[];
  withFilter?: boolean;
};

const ArbeidslisteKartvisning = ({ meldinger, withFilter = false }: ArbeidslisteKartvisningProps): JSX.Element => {

  return (
    <div className="w-full h-body-small-header overflow-hidden bg-gray-dark">

      <MapContainer className="w-full h-full" center={[59.9139, 10.7522]} zoom={13} zoomControl={false} tap={false} maxZoom={18}
        minZoom={11}>
        <CustomLeafletControl position="topright">
          <Kartlagvelger id="meldinger_kartvisning_kartlagvelger" />
        </CustomLeafletControl>

        {withFilter && (
          <CustomLeafletControl position="topleft">
            <div className="bg-white p-1 pr-0 rounded-full">
              <FilterLink />
            </div>
          </CustomLeafletControl>
        )}
        <MeldingerMarkers meldinger={meldinger} />
      </MapContainer>
    </div >
  );
};

export default ArbeidslisteKartvisning;
