import React from 'react';
import { useFilter } from './FilterProvider';
import SavedFilterItem from './SavedFilterItem';

const SavedFilters = (): JSX.Element => {
  const { savedFilters } = useFilter();

  return (
    <>
      <h4>Dine lagrede filtere</h4>
      <div className="flex flex-row m-1 flex-wrap">
        {savedFilters.map((item) => {
          return <SavedFilterItem key={item.id} filter={item} />;
        })}
      </div>
    </>
  );
};

export default SavedFilters;
