/* eslint-disable prettier/prettier */
const getHostFromEnv = (url_name: string): string => {
  const key = `REACT_APP_${url_name}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const host = (window as any)[key] || process.env[key];
  if (host != null) return host;
  throw new Error(`Missing environment configuration with key '${key}'`);
};

export const bymeldingService = getHostFromEnv('URL_BYMELDING_SERVICE');
export const bymeldingSokService = getHostFromEnv('URL_BYMELDING_SOK_SERVICE');
export const bymeldingPortalUrl = getHostFromEnv('URL_BYMELDING_PORTAL');
const notificationService = `${getHostFromEnv('URL_BYMELDING_NOTIFICATION_SERVICE')}/api`;

/** ********************************** Geolokasjon ********************************** */
export const getBydel = (lat: number, lng: number): string =>
  `${bymeldingService}/api/kart/feature/arcgis/${lng}/${lat}?layerId=2`;
export const getAdresse = (lat: string, lon: string, begrensTilOslo?: boolean): string =>
  `${bymeldingService}/api/adresseoppslag?latitude=${lat}&longitude=${lon}${
    begrensTilOslo ? '&begrenstiloslo=true' : '&begrenstiloslo=false'
  }`;

/************************************ Global  ********************* */
export const meldingstyperSokService = `${bymeldingSokService}/sok/meldingstype/autocomplete?tekst=`;
export const meldingstyper = `${bymeldingService}/api/meldingstyper`;
export const meldingstatuser = `${bymeldingService}/api/meldingsstatuser`;

/************************************ Images *************** */
export const imageUrl = (imageId: string): string => `${bymeldingService}/api/bilder/${imageId}/base64`;
export const imageThumbUrl = (imageId: string): string => `${bymeldingService}/api/bilder/${imageId}/thumb`;

/************************************ Dokumentasjon *************** */
export const dokumentasjonUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/dokumentasjon/base64`;
export const dokumentasjonMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/dokumentasjon`;

/************************************ ServiceAvd. *************** */
export const getFilteredMeldingerForServiceavdeling = (filter: string): string =>
  `${bymeldingSokService}/sok/melding/serviceavdeling/${filter ? `?${filter}` : ''}`;
export const getMeldingerForServiceavdeling = (): string => `${bymeldingService}/api/serviceavdelinger/meldinger`;
export const serviceAvdelingMeldingById = (meldingId: string): string =>
  `${bymeldingService}/api/serviceavdelinger/meldinger/${meldingId}?Include=kontrakt&Include=avtale&Include=selskap&Include=bilde&Include=dokumentasjon`;

/************************************ Entreprenorer *************** */
export const getselskapsIdForUserUrl = (brukerId: string): string =>
  `${bymeldingService}/api/entreprenorer/bruker/${brukerId}/selskapid`;
export const getFilteredMeldingerForEntrepreneurs = (selskapsId: string, filter: string): string =>
  `${bymeldingSokService}/sok/melding/entreprenor/${selskapsId}/${filter ? `?${filter}` : ''}`;

export const getMeldingerForEntrepreneurs = (selskapsId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapsId}/meldinger`;
export const entreprenorMeldingById = (selskapsId: string, meldingId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapsId}/meldinger/${meldingId}?Include=kontrakt&Include=avtale&Include=selskap&Include=bilde&Include=dokumentasjon`;
export const savedMeldingSearchUrl = (brukerId: string): string =>
  `${bymeldingService}/api/brukere/${brukerId}/meldingsfiltre`;
export const deleteSavedMeldingsfilter = (brukerId: string, filterId: string): string =>
  `${bymeldingService}/api/brukere/${brukerId}/meldingsfiltre/${filterId}`;

/************************************ Avtale *************** */
export const getAvtalerForEntrepreneurUrl = (selskapId: string, brukerId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapId}/brukere/${brukerId}/avtaler`;
export const getAvtalerForEntrepreneurAdminUrl = (selskapId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapId}/avtaler'`;
export const getkontraktByAvtaleIdUrl = (avtaleId: string): string =>
  `${bymeldingService}/api/avtaler/${avtaleId}/kontrakt`;

/************************************ Ressursforbruk *************** */
export const getRessursbrukById = (ressursforbrukId: string): string =>
  `${bymeldingService}/api/ressursforbruk/${ressursforbrukId}`;
export const updateMeldingRessursbrukByIdUrl = (meldingId: string, ressursbrukId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/ressursforbruk/${ressursbrukId}`;
export const deleteMeldingResourcebrukUrl = (ressursforbrukId: string): string =>
  `${bymeldingService}/api/ressursforbruk/${ressursforbrukId}`;

/************************************ Ressursforbruk for melding *************** */
export const getRessursbrukListUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/ressursforbruk`;
export const meldingRessursbrukUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/ressursforbruk`;

export const prosesskoder = `${bymeldingService}/api/prosesskoder`;
export const prisliste = (kontraktId: string): string => `${bymeldingService}/api/kontrakter/${kontraktId}/priser`;
export const kontrakter = (selskapId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapId}/kontrakter`;
export const kontratByAvtale = (avtaleId: string): string => `${bymeldingService}/api/avtaler/${avtaleId}/kontrakt`;
export const ressursforbrukById = (ressursforbrukId: string): string =>
  `${bymeldingService}/api/ressursforbruk/${ressursforbrukId}`;

/** ********************************** kommentar & historisk & tilbakemeldin for meldinger  **********************/
export const kommentarerForMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/kommentarer`;
export const historikkForMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/historikk`;
export const tilbakemeldingerForMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/tilbakemeldinger`;

/*********************************** Melding  **********************/
export const newMeldingUrl = `${bymeldingService}/api/meldinger`;

export const putMeldingEntreprenor = (selskapId: string, meldingId: string): string =>
  `${bymeldingService}/api/entreprenorer/${selskapId}/meldinger/${meldingId}`;
export const putMelding = (meldingId: string): string =>
  `${bymeldingService}/api/serviceavdelinger/meldinger/${meldingId}`;

/** ********************************** kommentar & historisk & tilbakemeldin for meldinger  **********************/
export const getTilbakemeldingerForMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/tilbakemeldinger`;
export const postTilbakemeldingerForMeldingUrl = (meldingId: string): string =>
  `${bymeldingService}/api/meldinger/${meldingId}/tilbakemeldinger`;

export const selskapsBrukere = `${bymeldingService} /api/serviceavdelinger / brukere`;

/** ********************************** varsel fra NotificationService  **********************/
export const getNotificationServiceVarselData = (klientKey: string): string =>
  `${notificationService}/varsel?klientKey=${klientKey}`;
