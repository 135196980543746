import { Melding } from '../../interfaces/Melding';

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface arbeidsliste {
  meldinger: Melding[];
  totaltAntall: number;
  valgtSide: number;
  antallSider: number;
  filter: Record<string, unknown>;
  loading: boolean;
}

export const arbeidslisteInitState = {
  meldinger: [],
  totaltAntall: 0,
  valgtSide: 1,
  antallSider: 1,
  filter: {},
  loading: true,
};

export const arbeidslisteReducer = (
  state: arbeidsliste,
  action: { type: stateActionType; payload: arbeidsliste }
): arbeidsliste => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'RESET_STATE':
      return { ...arbeidslisteInitState };
    default:
      return state;
  }
};
