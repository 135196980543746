/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { imageUrl, imageThumbUrl } from '../../api/urls';
import Button from '../../common/buttons/Button';
import Ikon from '../../common/Ikon';
import FadeInSpinner from '../../common/spinner/FadeInSpinner';
import { useApi } from '../../context/ApiProvider';
import { bildeCashItem } from '../meldingReducer';
import Bilde from './Bilde';

const BilderPanel = ({
  bildeIds,
  bildeCash,
  cashImageToState,
}: {
  bildeIds?: string[];
  bildeCash?: bildeCashItem[];
  cashImageToState: (bilde: bildeCashItem) => void;
}): JSX.Element => {
  const { api, initialized: apiInitialized } = useApi();
  const [selectedImageId, setSelectedImageId] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(false);

  const loadBase64image = async (id: string): Promise<void> => {
    const image = bildeCash ? bildeCash.find((i) => i.id === id) : undefined;
    if (!image) {
      try {
        setLoading(true);
        const res = await api.get(imageUrl(id));
        cashImageToState({ base64: res.data.result.base64Bilde, id: id, filnavn: res.data.result.filNavn });
        setSelectedImage(res.data.result.base64Bilde);
      } catch {
      } finally {
        setLoading(false);
      }
    } else {
      setSelectedImage(image.base64);
    }
  };

  const selectNext = (id: string): string => {
    if (bildeIds && bildeIds.length > 1) {
      const next = bildeIds?.indexOf(id) + 1;
      return bildeIds[next % bildeIds?.length];
    }
    return id;
  };

  const selectPrev = (id: string): string => {
    if (bildeIds && bildeIds.length > 1) {
      const prev = bildeIds?.indexOf(id) - 1;
      if (prev < 0) return bildeIds[bildeIds?.length - 1];
      return bildeIds[prev % bildeIds?.length];
    }
    return id;
  };

  useMemo(() => {
    if (bildeIds && bildeIds.length > 0) setSelectedImageId(bildeIds[0]);
  }, []);

  useMemo(() => {
    if (selectedImageId && apiInitialized) {
      setSelectedImage('');
      loadBase64image(selectedImageId);
    }
  }, [selectedImageId, apiInitialized]);

  return (
    <div className="flex flex-col">
      <FadeInSpinner loading={loading} />
      <div className="bg-gray-light flex h-img-large mobile-only:h-mobile-img-large justify-center items-center ">
        {selectedImageId && <Bilde id={selectedImageId} base64={selectedImage} />}
        {bildeIds && bildeIds.length > 1 && (
          <>
            <div className="absolute left-0">
              <button
                className="p-1 h-full bg-blue-dark text-white opacity-75"
                onClick={() => setSelectedImageId(selectPrev(selectedImageId))}
              >
                <Ikon ikon="chevron-thin-left" />
              </button>
            </div>
            <div className="absolute right-0 ">
              <button
                className="p-1 h-full bg-blue-dark text-white opacity-75"
                onClick={() => setSelectedImageId(selectNext(selectedImageId))}
              >
                <Ikon ikon="chevron-thin-right" />
              </button>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-wrap mt-3">
        {bildeIds &&
          bildeIds.map((id) => (
            <div key={id} className="">
              <Button className="" onClick={() => setSelectedImageId(id)}>
                <img className="object-contain h-img-thumb" alt="meldingsvedlegg" src={imageThumbUrl(id)} />
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BilderPanel;
