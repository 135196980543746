import React from "react";
import { useAuth } from "react-oidc-context";

const ServerError = (): JSX.Element => {
  const auth = useAuth();

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.stopPropagation();
    await auth.signoutRedirect();
  };

  return (
    <div>
      <hgroup>
        <h1>Ubehandlet serverfeil!</h1>
      </hgroup>
      <section>
        <p>
          <span>
            Beklager, det oppstod en ubehandlet serverfeil.
          </span>
        </p>
        <button type="button" onClick={handleClick}>Logg av</button>
      </section>
    </div>
  );
}

export default ServerError;