import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import ArbeidslistePage from '../arbeidsliste/ArbeidslistePage';
import RoleCheckRoute from './RoleCheckRoute';
import RedirectRoute from './RedirectRoute';
import Unauthorized from '../unauthorized/Unauthorized';
import NotFound from '../notFound/NotFound';
import ServerError from '../serverError/ServerError';

export const globalRoutes = {
  redirect: {
    id: 'redirect',
    name: 'Redirect',
    link: '/redirect',
    path: '/redirect',
    publicRoute: true,
  },
  unauthorized: {
    id: 'unauthorized',
    name: 'Ikke tilgang',
    link: '/unauthorized',
    path: '/unauthorized',
    publicRoute: true,
  },
  notfound: {
    id: 'notfound',
    name: 'Finnes ikke',
    link: '/notfound',
    path: '/notfound',
    publicRoute: true,
  },
  servererror: {
    id: 'servererror',
    name: 'Serverfeil',
    link: '/servererror',
    path: '/servererror',
    publicRoute: true,
  },
  arbeidsliste: {
    id: 'arbeidsliste',
    name: 'Arbeidsliste',
    link: '/arbeidsliste',
    path: '/arbeidsliste',
    publicRoute: false,
  }
};

const Routes = (): JSX.Element => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={globalRoutes.arbeidsliste.link} />
      </Route>
      <RoleCheckRoute
        path={globalRoutes.arbeidsliste.path}
        publicRoute={globalRoutes.arbeidsliste.publicRoute}
        key={globalRoutes.arbeidsliste.id}
      >
        <ArbeidslistePage />
      </RoleCheckRoute>
      <RoleCheckRoute
        exact
        path={globalRoutes.redirect.path}
        publicRoute={globalRoutes.redirect.publicRoute}
        key={globalRoutes.redirect.id}
      >
        <RedirectRoute />
      </RoleCheckRoute>
      <Route
        exact
        path={globalRoutes.unauthorized.path}
        key={globalRoutes.unauthorized.id}>
        <Unauthorized />
      </Route>
      <Route
        exact
        path={globalRoutes.notfound.path}
        key={globalRoutes.notfound.id}>
        <NotFound />
      </Route>
      <Route
        exact
        path={globalRoutes.servererror.path}
        key={globalRoutes.servererror.id}>
        <ServerError />
      </Route>

      <Redirect to={globalRoutes.notfound.link} />
    </Switch>
  );
};

export default Routes;
