import React from 'react';
import Spinner from './Spinner';

const PageSpinner = (): JSX.Element => (
  <div className="fixed top-0 left-0 bg-black-backdrop w-screen h-screen z-spinner">
    <div className="flex flex-col justify-around w-full h-full">
      <div className="m-auto h-16 w-16">
        <Spinner />
      </div>
    </div>
  </div>
);
export default PageSpinner;
