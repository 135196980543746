import React, { useMemo, useState } from 'react';
import { useYupValidationResolver } from '../../utils/useYupValidationResolver';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Button from '../common/buttons/Button';
import Textarea from '../common/forms/Textarea';
import { kommentarerForMeldingUrl } from '../api/urls';
import { useApi } from '../context/ApiProvider';
import { getFormValidationErrorMessage } from '../../utils/formValidation';

const LeggTilKommentarForm = ({
  meldingId,
  onSuccess,
  onClose,
}: {
  meldingId: string;
  onSuccess: (kommenrarId: string, tekst: string) => void;
  onClose: () => void;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { api } = useApi();

  const loginUserSchema = useMemo(
    () =>
      yup.object({
        kommentar: yup.string().required('validatation.required'),
      }),
    []
  );

  const resolver = useYupValidationResolver(loginUserSchema);
  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors },
  } = useForm<{
    kommentar: string;
  }>({
    mode: 'onSubmit',
    resolver,
  });

  const postKommentar = handleSubmit(
    async ({ kommentar }: { kommentar: string }): Promise<void> => {
      setLoading(true);
      try {
        const res = await api.post(kommentarerForMeldingUrl(meldingId), { tekst: kommentar });
        onSuccess(res.data.result.id, kommentar);
        reset();
      } catch (e) {
        setError('kommentar', {
          message: e?.response?.data.errorMessage || 'Det har oppstått en feil',
          type: 'conflict',
        });
      } finally {
        setLoading(false);
      }
    }
  );

  return (
    <div className="flex flex-col">
      <form className="flex flex-col items-end" onSubmit={postKommentar} onReset={onClose}>
        <div className="w-full">
          <Textarea
            state={errors.kommentar?.message !== undefined ? 'danger' : 'primary'}
            label="Legg til en intern kommentar"
            outline
            color="blue-dark"
            autoFocus
            {...register('kommentar')}
          />
        </div>
        <div className="flex flex-row items-center">
          <Button
            type="reset"
            value="Avbryt"
            className="border-0 bg-transparent font-bold hover:underline p-3"
            disabled={loading}
          />
          <Button
            type="submit"
            value="Legg til"
            className={`${loading ? 'bg-gray-dark cursor-not-allowed' : 'bg-blue-dark'
              } text-white h-50 tablet:h-60 px-3 flex justify-center items-center`}
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
      {errors.kommentar && getFormValidationErrorMessage(errors.kommentar.message || '')}
    </div>
  );
};

export default LeggTilKommentarForm;
