import { startOfDay } from 'date-fns';
import React, { useState } from 'react';
import Button from '../common/buttons/Button';
import DateInput from '../common/forms/DateInput';
import Ikon from '../common/Ikon';
import WeekCalendarPicker from '../common/weekCalendarPicker/WeekCalendarPicker';
import MeldingerWithRessursbrukTable from './MeldingerWithRessursbrukTable';
import './ressursforbruk.scss';

const RessursforbrukPage = (): JSX.Element => {
  const [date, setDate] = useState<Date>(startOfDay(new Date()));

  const onChangeDate = (d: Date): void => {
    const dateTime = startOfDay(d)
    setDate(dateTime);
  }

  return (
    <div className="rfb_container">
      <div className="rfb_header">
        <div className="rfb_header_container z-menu sticky left-0 py-1">
          <div className="flex justify-between items-center">
            <DateInput
              placeholder=""
              value={date}
              handleChange={(date) => onChangeDate(date || new Date())}
              id="ressursinnmelding__dateInput"
            />
            <Button className="flex items-center space-x-1" onClick={() => onChangeDate(new Date())}>
              <>
                <Ikon ikon="calendar" />
                <span>I dag</span>
              </>
            </Button>
          </div>
          {date && <WeekCalendarPicker date={date} onChange={onChangeDate} />}
        </div>
      </div>
      <div className="rfb_table">
        {date && <MeldingerWithRessursbrukTable date={date} />}
      </div>
    </div>
  );
};

export default RessursforbrukPage;
