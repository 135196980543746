import React, { forwardRef } from 'react';
import './Checkbox.scss';

interface InputFieldProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  value?: string | string[];
  labelClass?: string;
  handleChange?: (value: string[], checked?: boolean) => void;
}
type CheckboxProps = InputFieldProps;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, labelClass, value, checked, children, handleChange, ...rest }, ref) => {
    return (
      <label className="checkbox focus:text-blue-focus text-5">
        <input
          className="custom-input"
          type="checkbox"
          checked={checked}
          ref={ref}
          {...rest}
          onChange={() => {
            if (handleChange) {
              if (Array.isArray(value)) handleChange(value || '', !checked);
              else handleChange([value || ''], !checked);
            }
          }}
        />
        <span className="checkmark"></span>
        <span className={`p-1 pl-3 ${labelClass}`}>{label}</span>
        {children}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
