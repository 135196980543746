/* eslint-disable @typescript-eslint/no-explicit-any */
export const BYMELDING_SERVICE_ID = '59fface5-c76c-446c-bb58-15b110578a87';
export const BYM_CLIENT_ID = '2a508433-c2ba-41ef-be29-a7e51ead9904';
export const BYM_CLIENT_VILKAAR_ID = '8dc9bb1f-6856-4541-8b3f-881ef0d4fc30';

/**
 * Function that returns the value of a given environment variable.
 * It first checks if the variable is defined on the global window object,
 * if not it falls back to process.env.
 * The reason for this is that environment varibles are resolved at build time,
 * so to avoid running a build every time the application starts up in production
 * all REACT_APP_* environment variables are generated on the window object at startup
 * with the 'runtimeEnv.js' script
 */

const getEnvironmentVariable = (key: string, defaultValue?: string): string => {
  const env = (window as any)[key] || process.env[key];
  if (env != null) {
    // eslint-disable-next-line no-console
    return env;
  }
  if (defaultValue) return defaultValue;
  throw new Error(`Missing environment configuration with key '${key}'`);
};

const getReactEnviroment = (): string => {
  return getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
};

const isProduction = (): boolean => {
  const env = getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
  return env === 'production';
};

const isTest = (): boolean => {
  const env = getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
  return env === 'testing';
};

const isLocalhost = (): boolean => {
  const env = getEnvironmentVariable('REACT_APP_REACT_ENV', 'development');
  return env === 'localhost';
};

export { isProduction, isTest, isLocalhost, getReactEnviroment, getEnvironmentVariable };
