import React from 'react';
import DropdownSelect from '../common/forms/DropdownSelect';
import { useFilter } from '../filter/FilterProvider';

const OrderBySelect = (): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  const { sorterEtter } = currentFilter;

  const sortOptions = [
    {
      name: 'Adresse',
      value: 'adresse',
    },
    {
      name: 'Dato',
      value: 'dato',
    },
    {
      name: 'Status',
      value: 'status',
    },
    {
      name: 'Type',
      value: 'type',
    },
  ];

  return (
    <div>
      <DropdownSelect
        options={sortOptions}
        label="Sorter etter"
        value={sorterEtter}
        outline
        id="sortBySelect"
        name="sortBySelect"
        onChange={(e) => {
          updateCurrentFilter({ ...currentFilter, sorterEtter: e.target.value });
        }}
      />
    </div>
  );
};

export default OrderBySelect;
