import { Melding, Prosesskode, Ressursforbruk } from '../../interfaces';

type stateActionType = 'UPDATE_STATE' | 'RESET_STATE';

export interface MeldingWithRessursbruk extends Melding {
  aggregertHistoriskPris?: string;
  ressursforbruk?: Ressursforbruk[];
}

export interface meldingRessursbrukReducerState {
  meldinger: MeldingWithRessursbruk[];
  pkColumns: Prosesskode[];
  visMeldingerForAvtale: boolean;
  totaltAntall: number;
  loading: boolean;
}

export const meldingRessursbrukInitState = {
  meldinger: [],
  pkColumns: [],
  totaltAntall: 0,
  loading: false,
  visMeldingerForAvtale: false,
};

export const meldingRessursbrukReducer = (
  state: meldingRessursbrukReducerState,
  action: { type: stateActionType; payload: meldingRessursbrukReducerState }
): meldingRessursbrukReducerState => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
