import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MeldingstypeSokService, MeldingType } from '../interfaces';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';

export const useMeldingstyperFiltrert = (): UseQueryResult<MeldingType[]> => {
  const { api, initialized: apiInitialized } = useApi();

  return useQuery({
    enabled: apiInitialized,
    queryKey: ['meldingstyper-filtrert'],
    queryFn: () => {
      return api.get(urls.meldingstyperSokService).then((result: { data: { result: MeldingstypeSokService[] } }) => {
        return result.data.result.map((mt: MeldingstypeSokService) => ({
          navn: mt.beskrivelse,
          beskrivelse: mt.beskrivelse,
          meldingstypeId: mt.id,
        }));
      });
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
