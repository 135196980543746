import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MeldingType } from '../interfaces';
import { useApi } from '../features/context/ApiProvider';
import * as urls from '../features/api/urls';

export const useMeldingstyper = (): UseQueryResult<MeldingType[]> => {
  const { api, initialized: apiInitialized } = useApi();

  return useQuery({
    enabled: apiInitialized,
    queryKey: ['meldingstyper'],
    queryFn: () => {
      return api.get(urls.meldingstyper).then((result: { data: { result: MeldingType[] } }) => {
        return result.data.result;
      });
    },
    cacheTime: 15 * (60 * 1000),
    staleTime: 10 * (60 * 1000),
  });
};
