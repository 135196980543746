export const getFormValidationErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'validatation.required':
      return 'Dette feltet må fylles ut';
    case 'validatation.notavalidemail':
      return 'Ikke en gyldig E-post adresse';
    case 'validatation.notValidUser':
      return 'E-post eller passord er ikke riktig';
    case 'validatation.insufficientPermissions':
      return 'Eposten du forsøker å logge inn med er registrert som publikumsbruker og du har derfor ikke tilgang til å se innholdet til denne siden. Hvis du er saksbehandler eller entreprenør på oppdrag for Bymiljøetaten må du kontakte din systemadministrator for å få tilgang til siden';
    case 'validatation.unknownEmail':
      return 'E-post eller passord er ikke riktig';
    case 'validatation.password-must-match':
      return 'Passord må være like';
    case 'validatation.termsandcondition':
      return 'Du må godta vilkårene før du kan registrere brukerkonto';
    case 'validatation.invalidpassword':
      return 'Passord må være 8 tegn eller lengre og må inneholde minst 1 stor bokstav og 1 tall';
    case 'validatation.emailconflict':
      return 'E-posten du forsøkte å registrere deg med er allerede i bruk. Hvis du allerede har registrert en bruker kan du logge inn';
    default:
      return 'En feil oppsto, hvis feilen vedvarer kontakt Bymiljøetaten';
  }
};

export const passwordRegex = /^(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?=.*\d)(?=.{8,})/;
