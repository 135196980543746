import React from 'react';
import { imageThumbUrl } from '../../api/urls';

const Bilde = ({ id, base64 }: { id: string; base64?: string }): JSX.Element => {
  return (
    <div className="overflow-y-hidden">
      {base64 ? (
        <img
          key={id}
          className="object-contain max-w-screen h-img-large mobile-only:h-mobile-img-large mx-auto "
          src={`data:image/png;base64,${base64}`}
          alt="meldingsvedlegg"
        />
      ) : (
        <img
          key={id}
          className="object-contain max-w-screen h-img-large mobile-only:h-mobile-img-large mx-auto"
          alt="meldingsvedlegg"
          src={imageThumbUrl(id)}
        />
      )}
    </div>
  );
};

export default Bilde;
