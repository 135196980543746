/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, ReactNode, ReactNodeArray, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Button from '../buttons/Button';
import Ikon from '../Ikon';
import './Modal.scss';

interface ModalProps {
  toggle?: () => void;
  isOpen?: boolean;
  children: ReactNodeArray | ReactNode;
  closeButton?: boolean;
  preventScroll?: boolean;
}
export default function BymeldingModal({
  children,
  toggle,
  isOpen = false,
  closeButton = true,
  preventScroll = false,
}: ModalProps): JSX.Element {
  const [yPosition, setYPosition] = useState(0);

  useEffect(() => {
    setYPosition(window.scrollY);
    if (preventScroll) {
      if (isOpen)
        document.body.setAttribute(
          'style',
          `position: fixed: top: -${yPosition}px; left:0; rigth: 0; overflow: hidden;`
        );
      else document.body.setAttribute('style', '');
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      if (preventScroll) document.body.setAttribute('style', '');
    };
  }, []);

  Modal.setAppElement('#root');

  return (
    <Modal
      isOpen={isOpen}
      className="max-h-screen overflow-scroll w-full desktop:w-80-page pt-7 tablet:pt-6"
      overlayClassName="modal-backdrop z-modalBackdrop bg-black-backdrop"
      onRequestClose={toggle}
    >
      <div className="w-full  relative pb-3 tablet:pb-2 flex flex-col bg-white">
        <div className="close-button-section border-0">
          <div className="bg-white w-full" />
          <div className="mobile-only:relative mobile-only:bg-white">
            <Button color="yellow" circle disabled={!closeButton} onClick={toggle} padding={2}>
              <Ikon ikon="x" />
            </Button>
          </div>
        </div>
        <div className="px-3 tablet:px-5  ">{children}</div>
      </div>
    </Modal>
  );
}

interface ModalHeaderProps {
  children: {
    heading?: ReactNode;
    subheading?: ReactNode;
    restcontent?: ReactNode;
  };
}
export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  children: { heading, subheading, restcontent },
}) => {
  return (
    <div className="modal-header bg-white">
      {typeof heading === 'string' ? <h1 className="heading-modal mb-2">{heading}</h1> : heading}
      {typeof subheading === 'string' ? <p className="heading-modal-sub">{subheading}</p> : subheading}
      {restcontent}
    </div>
  );
};

interface ModalSectionProps {
  className?: string;
}
export const ModalSection: FunctionComponent<ModalSectionProps> = ({ children, className }) => {
  return (
    <div className={`mb-4 mobile-only:mb-2 mobile-only:mt-1 last:mb-2 first:mt-2   ${className ?? ''}`}>{children}</div>
  );
};

export const ModalTranslations = {
  no: {
    'modal.close-btn': 'Lukk',
  },
  en: {
    'modal.close-btn': 'Close',
  },
};
