import { reduce } from 'lodash-es';
import { FilterData } from '../features/filter/filterReducer';
import { MeldingsStatus, MeldingType } from '../interfaces';
import queryString from 'query-string';

export const addItemsToQuery = (name: string, items: string[]): string => {
  if (items.length === 0) return '';
  return reduce(
    items,
    (result: string, item: string) => {
      return `${result}${name}=${item}&`;
    },
    ''
  );
};

export const buildQueryfromState = (filterState: FilterData): string => {
  return queryString.stringify(filterState);
};

const toList = (parsedValue?: string | string[]): string[] => {
  if (Array.isArray(parsedValue)) return parsedValue;
  else if (typeof parsedValue === 'string') return [parsedValue];
  return [];
};

export const buildStateFromQuery = (filterQuery: string): FilterData => {
  const filter = <FilterData>(<unknown>queryString.parse(filterQuery, {}));
  const filterFromQuery = {
    ...filter,
    avtaleId: toList(filter.avtaleId),
    bydelId: toList(filter.bydelId),
    meldingstypeId: toList(filter.meldingstypeId),
    meldingsStatusId: toList(filter.meldingsStatusId),
    selskapId: toList(filter.selskapId),
    datoFra: filter.datoFra ? filter.datoFra : undefined,
    datoTil: filter.datoTil ? filter.datoTil : undefined,
    sistEndretDatoFra: filter.sistEndretDatoFra ? filter.sistEndretDatoFra : undefined,
    sistEndretDatoTil: filter.sistEndretDatoTil ? filter.sistEndretDatoTil : undefined,
    sorterEtter: filter.sorterEtter ? filter.sorterEtter : 'dato',
  };
  return filterFromQuery;
};

export const getNumberOfSelectedFilters = (filterState: FilterData): number => {
  const {
    avtaleId,
    bydelId,
    meldingstypeId,
    meldingsStatusId,
    selskapId,
    datoFra,
    datoTil,
    sistEndretDatoFra,
    sistEndretDatoTil,
  } = filterState;

  return (
    avtaleId.length +
    bydelId.length +
    meldingsStatusId.length +
    meldingstypeId.length +
    selskapId.length +
    (datoFra ? 1 : 0) +
    (datoTil ? 1 : 0) +
    (sistEndretDatoFra ? 1 : 0) +
    (sistEndretDatoTil ? 1 : 0)
  );
};

export const getGroupedMeldingstyper = (meldingTyper: MeldingType[]): { [key: string]: MeldingType[] } => {
  return reduce(
    meldingTyper,
    (accu, currentType): { [key: string]: MeldingType[] } => {
      const currentSub = currentType.beskrivelse.split('-')[0].trim();

      if (accu[currentSub]) accu[currentSub].push(currentType);
      else accu[currentSub] = [currentType];
      return accu;
    },
    {} as { [key: string]: MeldingType[] }
  );
};

export const getGroupedMeldingstatuser = (meldingsStatuser: MeldingsStatus[]): { [key: string]: MeldingsStatus[] } => {
  return reduce(
    meldingsStatuser,
    (accu, currentStatus): { [key: string]: MeldingsStatus[] } => {
      const currentSubs =
        meldingsStatuser.find((status) => status.id === currentStatus.foreldreId)?.navn || currentStatus.foreldreId;

      if (accu[currentSubs]) accu[currentSubs].push(currentStatus);
      else accu[currentSubs] = [currentStatus];
      return accu;
    },
    {} as { [key: string]: MeldingsStatus[] }
  );
};

export const checkContainsAllValues = (list: string[], values: string[]): boolean => {
  return values.every((value: string) => list.find((listItem: string) => listItem === value));
};
