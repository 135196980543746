import React from "react";
import { useAuth } from "react-oidc-context";

const Unauthorized = (): JSX.Element => {

  const auth = useAuth();

  const loggUtBrukerUtenTilgang = async (): Promise<void> => {
    const authorityRootUrl = auth.settings.authority;

    // send en logout request til OneLogin for å terminere sesjonen før ny login initieres
    const logoutPopup = window.open(authorityRootUrl + "/logout", 'windowname', 'location=false,toolbar=false,menubar=false,scrollbars=false,resizable=false,status=false,width=1,height=1');
    await new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
      logoutPopup?.close();
      auth.signinRedirect();
    });
  };

  return (
    <div>
      <hgroup>
        <h1>Beklager, brukeren du er innlogget med har ikke tilgang</h1>
      </hgroup>
      <section>
        <p>
          <span>
            <button className="btn" onClick={loggUtBrukerUtenTilgang}>
              Trykk her for å logge inn med en annen bruker
            </button>
          </span>
        </p>
      </section>
    </div>
  );
}

export default Unauthorized;
