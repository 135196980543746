import React from 'react';
import Button from '../common/buttons/Button';
import Ikon from '../common/Ikon';
import { useFilter } from './FilterProvider';

const FilterLink = (): JSX.Element => {
  const { numberOfFiltersSelected, toggleFilterOpen } = useFilter();
  return (
    <div className="flex justify-center items-center link-btn p-2 hover:text-blue-hover">
      <Button onClick={() => toggleFilterOpen()} className="flex flex-no-wrap">
        <Ikon ikon="filter" className="h-4 w-4" />
        <span className="rounded-full bg-yellow flex justify-center items-center h-4 w-4 -mt-2 -ml-2 text-blue-dark">
          {numberOfFiltersSelected}
        </span>
      </Button>
    </div>
  );
};

export default FilterLink;
