import { isEmpty, find, reverse, filter, map } from 'lodash-es';
import { MeldingsStatus } from '../interfaces';

import greenMarker from '../assets/mapMarkers/greenMarker.svg';
import redMarker from '../assets/mapMarkers/redMarker.svg';
import blueMarker from '../assets/mapMarkers/blueMarker.svg';
import grayMarker from '../assets/mapMarkers/grayMarker.svg';
import defaultMarker from '../assets/mapMarkers/defaultMarker.svg';

export const getStatusUnderstatuser = (
  statuser: MeldingsStatus[],
  inParent?: MeldingsStatus,
  inGroups?: MeldingsStatus[]
): MeldingsStatus[] => {
  let groups = typeof inGroups !== 'undefined' ? inGroups : [];
  const parent = typeof inParent !== 'undefined' ? inParent : { id: 0, children: [] };

  const children = filter(statuser, (child) => child.foreldreId === parent.id);
  if (!isEmpty(children)) {
    if (parent.id === 0) {
      groups = children;
    } else {
      parent.children = children;
    }
    map(children, (child) => {
      getStatusUnderstatuser(statuser, child);
    });
  }
  return groups;
};

export const isTerminalstatus = (statuser: MeldingsStatus[], statusId: number): boolean => {
  const path = findMeldingstatusPath(statuser, statusId, []);
  if (path[0].id === 5) return true;
  return false;
};

export const getStatusIdsOfChildren = (status: MeldingsStatus, collector: string[] = []): string[] => {
  if (status.children && status.children.length > 0) {
    status.children?.map((s) => getStatusIdsOfChildren(s, collector));
  }
  collector.push(status.id.toString());
  return collector;
};

export const findMeldingstatusPath = (
  statuser: MeldingsStatus[],
  statusId: number,
  statusPath: MeldingsStatus[] = []
): MeldingsStatus[] => {
  if (isEmpty(statuser)) return [];
  const statusItem = find(statuser, (s) => s.id === statusId);
  if (statusItem) {
    statusPath.push(statusItem);
    if (statusItem.foreldreId.toString() !== '0') {
      return findMeldingstatusPath(statuser, statusItem.foreldreId, statusPath);
    }
  }
  return reverse(statusPath);
};

export const getStatusMarker = (statusId: number): string => {
  const status = getStatusNameAndClass(statusId);
  switch (status[1]) {
    case 'ikke-behandlet':
    case 'ukjent':
      return grayMarker;
    case 'avvist':
      return redMarker;
    case 'ferdig-behandlet':
      return greenMarker;
    case 'ikke-tildelt':
      return blueMarker;
    default:
      return defaultMarker;
  }
};

export const getStatusNameAndClass = (statusId: number): [string, string] => {
  // const status = statusKoder.find((s) => s.id === statusId);
  return [getStatusName(statusId), getStatusClass(statusId)];
};

const getStatusClass = (statusId: number): string => {
  switch (statusId) {
    case 1: {
      return 'ikke-tildelt';
    }
    case 2: {
      return 'ikke-behandlet';
    }
    case 3: {
      return 'avvist';
    }
    case 4: {
      return 'under-behandling';
    }
    default: {
      return 'ferdig-behandlet';
    }
  }
};

const getStatusName = (statusId: number): string => {
  switch (statusId) {
    case 1: {
      return 'Ikke tildelt';
    }
    case 2: {
      return 'Ikke behandlet';
    }
    case 3: {
      return 'Avvist';
    }
    case 4: {
      return 'Under behandling';
    }
    case 5:
    case 6: {
      return 'Arbeid utført';
    }
    case 7: {
      return 'Duplikat';
    }
    case 8:
    case 10:
    case 11: {
      return 'Avslått';
    }
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 50:
    case 52:
    case 100: {
      return 'Henvises ekstern';
    }
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
    case 33:
    case 51:
    case 59:
    case 60:
    case 61:
    case 62:
    case 63:
    case 64:
    case 65:
    case 66:
    case 67:
    case 68:
    case 69:
    case 70:
    case 58:
    case 57:
    case 200:
    case 300:
    case 310: {
      return 'Videresendt';
    }
    default: {
      return 'Arbeid utført';
    }
  }
};

export const statusKoder = [
  {
    id: 1,
    alt: 'Ikke tildelt',
    navn: 'Ikke tildelt',
    foreldreId: 0,
    class: 'ikke-tildelt',
  },
  {
    id: 2,
    alt: 'Ikke behandlet',
    navn: 'Ikke behandlet',
    foreldreId: 0,
    class: 'ikke-behandlet',
  },
  {
    id: 3,
    alt: 'Avvist',
    navn: 'Avvist',
    foreldreId: 0,
    class: 'avvist',
  },
  {
    id: 4,
    alt: 'Under behandling',
    navn: 'Under behandling',
    foreldreId: 0,
    class: 'under-behandling',
  },
  {
    id: 5,
    alt: 'Ferdig behandlet',
    navn: 'Ferdig behandlet',
    foreldreId: 0,
    class: 'ferdig-behandlet',
  },
  {
    id: 6,
    alt: 'Arbeid utført',
    navn: 'Arbeid utført',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 7,
    alt: 'Duplikat',
    navn: 'Duplikat',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 8,
    alt: 'Avslått',
    navn: 'Avslått',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 71,
    alt: 'Utbedring bestilt',
    navn: 'Utbedring bestilt',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 10,
    alt: 'Fritekst',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 11,
    alt: 'Privat vei',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 15,
    alt: 'Statsbygg',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 16,
    alt: 'Statens vegvesen',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 17,
    alt: 'BaneNor',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 18,
    alt: 'Sporveien AS',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 19,
    alt: 'Forsvarsbygg',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 20,
    alt: 'Slottsforvalter',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 21,
    alt: 'Hafslund',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 22,
    alt: 'Leverandør avfallsekker (isekk)',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 23,
    alt: 'Kirkelig fellesråd',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 50,
    alt: 'Fri tekst',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 52,
    alt: 'Aktør for sparkesykkel',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 24,
    alt: 'Gårdeiers ansvar',
    navn: 'Henvises ekstern',
    foreldreId: 100,
    class: 'ferdig-behandlet',
  },
  {
    id: 26,
    alt: 'Bydel Frogner',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 27,
    alt: 'Bydel St.Hanshaugen',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 28,
    alt: 'Bydel Grünerløkka',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 29,
    alt: 'Bydel Sagene',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 30,
    alt: 'Eiendoms og byfornyelsesetaten',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 31,
    alt: 'Kulturetaten (KUL)',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 32,
    alt: 'Fri tekst',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 51,
    alt: 'Bydel Gamle Oslo',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 55,
    alt: 'Treforvaltning - Del av årshjul',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 12,
    alt: 'Privat eiendom',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 53,
    alt: 'Saksbehandles',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 54,
    alt: 'Tagging privat eiendom',
    navn: 'Avslått',
    foreldreId: 8,
    class: 'ferdig-behandlet',
  },
  {
    id: 59,
    alt: 'Boligbygg Oslo KF',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 61,
    alt: 'Bydel Alna',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 62,
    alt: 'Bydel Bjerke',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 63,
    alt: 'Bydel Grorud',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 64,
    alt: 'Bydel Nordre Aker',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 65,
    alt: 'Bydel Norstrand',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 66,
    alt: 'Bydel Stovner',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 67,
    alt: 'Bydel Søndre Norstrand',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 68,
    alt: 'Bydel Ullern',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 69,
    alt: 'Bydel Vestre Aker',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 70,
    alt: 'Bydel Østensjø',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 60,
    alt: 'Omsorgsbygg Oslo KF',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 33,
    alt: 'Oslo Havn KF',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 58,
    alt: 'Undervisningsbygg Oslo KF',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 57,
    alt: 'Vann- og avløpsetaten',
    navn: 'Videresendt',
    foreldreId: 200,
    class: 'ferdig-behandlet',
  },
  {
    id: 100,
    alt: 'Henvises områdeansvarlig',
    navn: 'Henvises ekstern',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 200,
    navn: 'Videresendt',
    alt: 'Videresendt områdeansvarlig Oslo Kommune',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 300,
    alt: 'Videresendt BYM områdeansvarlig',
    navn: 'Videresendt',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 301,
    alt: 'Videresendt veidrift',
    navn: 'Videresendt',
    foreldreId: 300,
    class: 'ferdig-behandlet',
  },
  {
    id: 302,
    alt: 'Videresendt parkforvaltning',
    navn: 'Videresendt',
    foreldreId: 300,
    class: 'ferdig-behandlet',
  },
  {
    id: 303,
    alt: 'Videresendt vaktsentralen',
    navn: 'Videresendt',
    foreldreId: 300,
    class: 'ferdig-behandlet',
  },
  {
    id: 304,
    alt: 'Videresendt arbeidsordre',
    navn: 'Videresendt',
    foreldreId: 300,
    class: 'ferdig-behandlet',
  },
  {
    id: 305,
    alt: 'Videresendt entreprenør for toalett',
    navn: 'Videresendt',
    foreldreId: 150,
    class: 'ferdig-behandlet',
  },
  {
    id: 310,
    alt: 'Bortkjøring av snø',
    navn: 'Videresendt',
    foreldreId: 300,
    class: 'ferdig-behandlet',
  },
  {
    id: 315,
    alt: 'Takk for innspill',
    navn: 'Ferdig behandlet',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
  {
    id: 316,
    alt: 'Takk for innspill',
    navn: 'Ferdig behandlet',
    foreldreId: 5,
    class: 'ferdig-behandlet',
  },
];
