import React from 'react';

import ExpandablePanel from '../common/ExpandablePanel';
import { useFilter } from './FilterProvider';
import { endOfDay } from 'date-fns';
import DateInput from '../common/forms/DateInput';

const DateCreatedFilter = (): JSX.Element => {
  const { currentFilter, updateCurrentFilter } = useFilter();
  return (
    <ExpandablePanel
      title="Opprettet dato"
      indicatorLabel={[currentFilter.sistEndretDatoFra, currentFilter.sistEndretDatoTil].filter((i) => i).length}
      heightConstraint={false}
    >
      <DateInput
        id="datofra"
        placeholder="f.o.m"
        value={currentFilter.datoFra ? new Date(currentFilter.datoFra) : undefined}
        handleChange={(date) => updateCurrentFilter({ ...currentFilter, datoFra: date?.toISOString() || undefined })}
      />
      <DateInput
        id="datotil"
        placeholder="t.o.m"
        value={currentFilter.datoTil ? new Date(currentFilter.datoTil) : undefined}
        handleChange={(date) =>
          updateCurrentFilter({ ...currentFilter, datoTil: date ? endOfDay(date).toISOString() : undefined })
        }
      />
    </ExpandablePanel>
  )
};

export default DateCreatedFilter;
