import React from 'react';
import { format } from 'date-fns';
import { MeldingDetaljer } from '../../interfaces';
import { getGoogleUrl } from '../../utils/adresseUtils';
import StatusIkon from '../StatusIkon/StatusIkon';
import Ikon from '../common/Ikon';
import UpdateLocationMap from '../Lokasjonsvelger/UpdateLocationMap';
import { updatedMeldingProps } from './MeldingPage';

interface MeldingInfoPanelProps {
  melding: MeldingDetaljer;
  updateMelding: (meldingId: string, avtaleId: string, updatedMelding: updatedMeldingProps) => void;
}

const MeldingInfoPanel = ({ melding, updateMelding }: MeldingInfoPanelProps): JSX.Element => (
  <div className="w-full mb-nav-small">
    <div className="bg-gray-light p-3">
      <dl className="">
        <div className="flex py-1">
          <dt className="font-bold w-1/2">Sted: </dt>
          <dd className="w-1/2 flex flex-col items-end">{melding.adresse}
            <a className="underline hover:text-blue-hover flex items-center" href={(getGoogleUrl(`${melding.latitude},${melding.longitude}`))} target="_">
              <Ikon ikon="chevron-right" /> Veibeskrivelse
            </a>
          </dd>
        </div>
        <div className="flex justify-between py-1">
          <dt className="font-bold">Meldingsnr.:</dt>
          <dd className="">{melding.lopeNummer}</dd>
        </div>
        <div className="flex justify-between py-1">
          <dt className="font-bold">Mottatt: </dt>
          <dd className="">{format(new Date(melding.opprettetTid), 'dd.MM.yy HH:mm')}</dd>
        </div>
        <div className="flex justify-between py-1">
          <dt className="font-bold">Status: </dt>
          <dd>{melding.meldingsStatus && <StatusIkon statusId={melding.meldingsStatusId} />}</dd>
        </div>
      </dl>
    </div>
    <div className="">{melding.beskrivelse}</div>
    <div className="w-full min-h-740px">
      <UpdateLocationMap
        melding={melding}
        updateMelding={updateMelding}
      />
    </div>
  </div>
);

export default MeldingInfoPanel;
