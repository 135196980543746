import React, { forwardRef } from 'react';
import Ikon from '../Ikon';
import './DateInput.scss';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';

interface InputFieldProps {
  label?: string;
  placeholder: string;
  id: string;
  value?: Date;
  handleChange: (date?: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}
type DateInputProps = InputFieldProps;

const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ placeholder, label, value, handleChange, id, minDate, maxDate }) => {
    registerLocale('nb', nb);
    setDefaultLocale('nb');
    const dateFormat = 'dd.MM.yyyy';
    return (
      <div className="flex flex-row flex-no-wrap justify-start items-end my-1">
        <label className="text-5 " htmlFor={`dateInput-${id}`}>
          {label}
          <div className="flex flex-row flex-no-wrap justify-start items-center">
            <div className="h-50 px-2 py-1 border-r-0 border-2 border-blue-dark hover:border-blue-hover">
              <Ikon ikon="calendar" aria-hidden="true" />
            </div>
            <DatePicker
              id={`dateInput-${id}`}
              selected={value}
              onChange={(date: Date) => handleChange(date)}
              dateFormat={dateFormat}
              locale="nb"
              placeholderText={placeholder}
              className="date_input flex-1 h-50 p-2 border-2 border-blue-dark hover:border-blue-hover focus:border-blue-hover rounded-none osloSans"
              calendarClassName="border-2 border-blue-dark rounded-none osloSans text-5"
              minDate={minDate}
              maxDate={maxDate ?? new Date()}
              popperModifiers={{
                offset: { enabled: true, offset: '-52px, 0' },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </div>
        </label>

        <button
          disabled={!value}
          onClick={() => handleChange(undefined)}
          className="h-50 px-2 py-1 border-l-0 border-2 border-blue-dark hover:border-blue-hover"
        >
          <Ikon ikon="x" />
        </button>
      </div>
    );
  }
);

DateInput.displayName = 'DateInput';
export default DateInput;
