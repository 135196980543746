import React from 'react';
import Spinner from '../spinner/Spinner';
import './button.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value?: string;
  color?: 'red' | 'blue-dark' | 'blue-light' | 'green-dark' | 'green-light' | 'yellow';
  circle?: boolean;
  padding?: 1 | 2 | 3;
  outline?: boolean;
  loading?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
}

const Button = (props: ButtonProps): JSX.Element => {
  const {
    value,
    color = 'blue-dark',
    circle = false,
    outline = false,
    padding = 3,
    children,
    loading,
    disabled,
    ...rest
  } = props;
  return (
    <button
      disabled={disabled || loading}
      className={`p-${padding} ${outline ? 'btn-outline' : 'btn'} btn-${color} ${circle ? 'btn-circle' : ''}`}
      {...rest}
    >
      {loading && (
        <div className="absolute flex items-center w-8 h-8">
          <Spinner color="blue-dark" />
        </div>
      )}
      {children}
      {value}
    </button>
  );
};

export default Button;
