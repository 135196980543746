import React from 'react';
import { base64Link, Dokumentasjon } from './DokumentasjonPanel';

interface DokumentasjonBildeProps {
  image: Dokumentasjon;
}

const DokumentasjonBilde = ({ image }: DokumentasjonBildeProps): JSX.Element => {
  return (
    <div className="overflow-y-hidden">
      <img
        key={image.dokumentasjonId}
        className="object-contain max-w-screen h-img-large mobile-only:h-mobile-img-large mx-auto"
        alt="meldingsvedlegg"
        src={base64Link(image)}
      />
    </div>
  );
};

export default DokumentasjonBilde;
